import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "src/styles/BuyInsurance.module.scss";
import { useTranslation } from "react-i18next";
import InsuranceItem from "src/components/FreeCancerInsuranceItem";
import {
  BUY_INSURANCE_STEP_DATA,
  CARD_TYPE_VALUES,
  CardType,
  GenderDisplayMap,
  FREE_CANCER_SUM_ASSURED,
  OTP_LENGTH,
  MBAGEAS_LIFE_URL,
  BUYING_PROCESS,
  BUYING_PROCESS_STEP,
  BUYING_PROCESS_TRACKING,
  STRING_EMPTY,
} from "src/Constants";
import HorizontalStepper from "src/components/HorizontalStepper";
import Accordion from "react-bootstrap/Accordion";
import Input from "src/components/Input";
import Checkbox from "src/components/Checkbox";
import UiService from "src/services/UiService";
import OtpPopup from "src/components/OtpPopup";
import HighlightText from "src/components/HighlightText";
import { useHistory } from "react-router-dom";
import mailImg from "src/assets/images/mail.svg";
import phoneImg from "src/assets/images/phone.svg";
import locationImg from "src/assets/images/location.svg";
import checkCircleBlueImg from "src/assets/images/check-circle-blue.svg";
import congratulationImg from "src/assets/images/congratulation.svg";
import Utils from "src/utils/Utils";
import { createOtp, createOrderFreeCancer } from "src/api";
import CancerFormService from "../CancerFormService";
import Item from "src/models/Item";
import { useMediaQuery } from "react-responsive";
import { gaTrackEvent } from "src/api/ga-tracking";

interface ConfirmInfoState {
  phone: string;
  errPhone: string;
  email: string;
  errEmail: string;
  address: string;
  errAddress: string;
  agreeJoin: boolean;
  agreeToc: boolean;
  requestId?: string | null;
}

const ConfirmInfo = () => {
  const isLargeDevice = useMediaQuery({
    query: "(min-width: 992px)",
  });
  const history = useHistory();
  const { t } = useTranslation();
  const [values, setValues] = useState<ConfirmInfoState>({
    phone: "",
    errPhone: "",
    email: "",
    errEmail: "",
    address: "",
    errAddress: "",
    agreeJoin: false,
    agreeToc: false,
    requestId: null,
  });
  const [commitments, setCommitments] = useState<Item[]>();
  const [showOtpPopup, setShowOtpPopup] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>();
  const [errOtp, setErrOtp] = useState<string>();
  const [otpKeyId, setOtpKeyId] = useState<number>(1);

  const _loadCommitments = () => {
    const commitmentsStr = localStorage.getItem("cancer_commitments");
    if (!commitmentsStr) return;
    try {
      setCommitments(JSON.parse(commitmentsStr));
    } catch (err) {
      console.log("Parse cancer commitments err", err);
    }
  };

  const _fillData = () => {
    if (CancerFormService.getCancerFormInfo()?.email) {
      setValues({
        ...values,
        email: CancerFormService.getCancerFormInfo()?.email || "",
        phone: CancerFormService.getCancerFormInfo()?.phone || "",
        address: CancerFormService.getCancerFormInfo()?.address || "",
      });
    }
  };

  useEffect(() => {
    _loadCommitments();
    _fillData();
  }, []);

  const _validate = (): boolean => {
    let isValid: boolean = true;
    let errEmail = "";
    let errPhone = "";
    let errAddress = "";
    if (!values.email || values.email.trim() === "") {
      errEmail = t("email-required");
      isValid = false;
    } else if (Utils.isEmailInvalid(values.email)) {
      errEmail = t("invalid-email");
      isValid = false;
    }
    if (!values.phone || values.phone.trim() === "") {
      errPhone = t("phone-required");
      isValid = false;
    } else if (!Utils.isValidPhoneNumer(values.phone)) {
      errPhone = t("invalid-phone");
      isValid = false;
    }
    if (!values.address || values.address.trim() === "") {
      errAddress = t("address-required");
      isValid = false;
    }
    if (!isValid) {
      setValues({
        ...values,
        errEmail,
        errPhone,
        errAddress,
      });
      return false;
    }

    if (!values.agreeJoin || !values.agreeToc) {
      UiService.openPopup({
        showIcon: false,
        content: t("please-agree-toc"),
        onlyOkButton: true,
        okTitle: t("agree"),
        onOk: () => {
          UiService.closePopup();
        },
        onCancel: () => {
          UiService.closePopup();
        },
      });
      isValid = false;
    }

    return isValid;
  };

  const _handleCreateOrder = async () => {
    if (
      CancerFormService.getCancerFormInfo()?.idCardType === CardType.PASSPORT &&
      (!CancerFormService?.frontIdCard || !CancerFormService?.signature)
    ) {
      setShowOtpPopup(false);
      UiService.openPopup({
        showIcon: false,
        onlyOkButton: true,
        visible: true,
        content: t("general_error"),
        okTitle: t("close"),
        onOk: () => {
          UiService.closePopup();
        },
      });
      return;
    } else if (
      CancerFormService.getCancerFormInfo()?.idCardType !== CardType.PASSPORT &&
      (!CancerFormService?.frontIdCard ||
        !CancerFormService?.backIdCard ||
        !CancerFormService?.signature)
    ) {
      setShowOtpPopup(false);
      UiService.openPopup({
        showIcon: false,
        onlyOkButton: true,
        visible: true,
        content: t("general_error"),
        okTitle: t("close"),
        onOk: () => {
          UiService.closePopup();
        },
      });
      return;
    }

    try {
      UiService.showLoading();
      const frontIdCardBase64Tmp = await Utils.getFileBase64(
        CancerFormService.frontIdCard!
      );
      const backIdCardBase64Tmp =
        CancerFormService.getCancerFormInfo()?.idCardType === CardType.PASSPORT
          ? null
          : await Utils.getFileBase64(CancerFormService.backIdCard!);
      const signatureBase64Tmp = await Utils.getFileBase64(
        CancerFormService.signature!
      );
      const frontIdCardBase64 = Utils.getBase64Content(frontIdCardBase64Tmp);
      const backIdCardBase64 = Utils.getBase64Content(backIdCardBase64Tmp??'');
      const signatureBase64 = Utils.getBase64Content(signatureBase64Tmp);
      const createOrderParams = {
        verificationOtp: {
          requestId: values.requestId,
          otp,
        },
        dob: CancerFormService.getCancerFormInfo()?.dob,
        gender: CancerFormService.getCancerFormInfo()?.gender,
        fullname: CancerFormService.getCancerFormInfo()?.fullname,
        premium: CancerFormService.getCancerFormInfo()?.premium,
        premiumAfterDiscount:
          CancerFormService.getCancerFormInfo()?.premiumAfterDiscount,
        sumInsured: FREE_CANCER_SUM_ASSURED,
        voucherCode: CancerFormService.getCancerFormInfo()?.voucherCode,
        idCardNo: CancerFormService.getCancerFormInfo()?.idCardNo,
        idCardType: CancerFormService.getCancerFormInfo()?.idCardType,
        phone: values.phone,
        email: values.email,
        nationality: "Việt Nam",
        address: values.address,
        issuedDate: CancerFormService.getCancerFormInfo()?.issuedDate,
        issuedPlace: CancerFormService.getCancerFormInfo()?.issuePlace,
        photoFront: {
          fileType: Utils.getFileType(CancerFormService.frontIdCard!),
          fileBase64: frontIdCardBase64,
        },
        photoBack:
          CancerFormService.getCancerFormInfo()?.idCardType ===
          CardType.PASSPORT
            ? null
            : {
                fileType: Utils.getFileType(CancerFormService.backIdCard!),
                fileBase64: backIdCardBase64,
              },
        photoSignature: {
          fileType: Utils.getFileType(CancerFormService.signature!),
          fileBase64: signatureBase64,
        },
      };
      gaTrackEvent(
        BUYING_PROCESS_TRACKING,
        BUYING_PROCESS[BUYING_PROCESS_STEP.VerifyOTP].name,
        CancerFormService.getCancerFormInfo()?.idCardNo ?? STRING_EMPTY
      );
      const createOrderRes = await createOrderFreeCancer(createOrderParams);
      if (createOrderRes?.data?.policyNumber)
        gaTrackEvent(
          BUYING_PROCESS_TRACKING,
          BUYING_PROCESS[BUYING_PROCESS_STEP.CreateContractSuccess].name,
          CancerFormService.getCancerFormInfo()?.idCardNo ?? STRING_EMPTY
        );
      UiService.hideLoading();
      setShowOtpPopup(false);
      UiService.openPopup({
        onlyCancelButton: true,
        title: t("congratulation"),
        image: (
          <div className={styles.congratulationImage}>
            <img
              src={congratulationImg}
              width={180}
              height={148}
              alt="congratulation"
            />
          </div>
        ),
        titleClassName: "popupTitle textPrimary text-center mb-4",
        onCancel: () => {
          UiService.closePopup();
          CancerFormService.reset();
          window.location.replace(MBAGEAS_LIFE_URL);
        },
        cancelTitle: t("back-to-home"),
        children: (
          <div className={styles.congratulationContent}>
            <HighlightText
              text={t("congratulation-message")}
              replaceText={[createOrderRes?.data?.policyNumber || ""]}
              className={"body20"}
              highlightClassName={"body20 w600"}
            />
          </div>
        ),
      });
    } catch (err: any) {
      UiService.hideLoading();
      console.log("Create Order err", err?.response?.data);
      if (err?.response?.data?.code === 400) {
        if (err?.response?.data?.error_code === "MSG03") {
          setErrOtp(err?.response?.data?.error_data?.messsage);
        } else {
          setShowOtpPopup(false);
          UiService.openPopup({
            content: err?.response?.data?.error_data?.messsage,
            onlyOkButton: true,
            onOk: () => {
              UiService.closePopup();
            },
          });
        }
      }
    }
  };

  const _createOtp = async () => {
    try {
      UiService.showLoading();
      const createOtpRes = await createOtp(
        CancerFormService.getCancerFormInfo()?.idCardNo || "",
        values.phone
      );
      console.log("Create Otp res", createOtpRes);
      setValues({
        ...values,
        requestId: createOtpRes?.data?.requestId,
      });
      UiService.hideLoading();
      setErrOtp("");
      setOtpKeyId(otpKeyId + 1);
      setShowOtpPopup(true);
    } catch (err) {
      UiService.hideLoading();
      console.log("Create Otp err", err);
    }
  };

  const _handleConfirmCreateOtp = async () => {
    UiService.closePopup();
    gaTrackEvent(
      BUYING_PROCESS_TRACKING,
      BUYING_PROCESS[BUYING_PROCESS_STEP.ConfirmContactInfo].name,
      CancerFormService.getCancerFormInfo()?.idCardNo ?? STRING_EMPTY
    );
    _createOtp();
  };

  const _handleContinue = () => {
    const isValid = _validate();
    if (!isValid) return;

    gaTrackEvent(
      BUYING_PROCESS_TRACKING,
      BUYING_PROCESS[BUYING_PROCESS_STEP.InputContactInfo].name,
      CancerFormService.getCancerFormInfo()?.idCardNo ?? STRING_EMPTY
    );

    CancerFormService.saveCancerFormInfo({
      phone: values.phone,
      email: values.email,
      address: values.address,
    });
    UiService.openPopup({
      title: t("confirm-info"),
      onlyOkButton: true,
      showClose: true,
      showIcon: false,
      children: (
        <div className={styles.popupChilren}>
          <div className="popupBody textBlack mb-4">
            {t("hint-create-otp-part1")}
            <span className="bold"> {t("confirm")} </span>
            {t("hint-create-otp-part2")}
          </div>
          <div className="rowStart fullWidth">
            <div className={styles.checkedIconContainer}>
              <img
                src={checkCircleBlueImg}
                width={24}
                height={24}
                alt={"checked"}
              />
            </div>
            <span className="popupBody textBlack">
              {t("confirm-phone-part1")}
              <span className="bold textPrimary"> {values.phone} </span>
              {t("confirm-phone-part2")}
            </span>
          </div>
        </div>
      ),
      onOk: _handleConfirmCreateOtp,
      onCancel: () => {
        UiService.closePopup();
      },
    });
  };

  const _handleGoBack = () => {
    history.goBack();
  };

  const _getDisplayCardType = (cardType?: CardType): string => {
    if (!cardType) return "";
    return t(
      CARD_TYPE_VALUES.find((item) => item.value === cardType)?.name || ""
    );
  };

  const _renderPersonalInfo = () => {
    return (
      <Accordion defaultActiveKey="0" className="mb-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="appH4 textBlack">
              {t("fill-personal-info")}
              <span className="required">*</span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("identity-card-type")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {_getDisplayCardType(
                    CancerFormService.getCancerFormInfo()?.idCardType
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("issue-date")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {CancerFormService.getCancerFormInfo()?.issuedDate || ""}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("issue-place")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {CancerFormService.getCancerFormInfo()?.issuePlace || ""}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("identity-card-no")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {CancerFormService.getCancerFormInfo()?.idCardNo}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("fullname")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {CancerFormService.getCancerFormInfo()?.fullname}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("gender")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {t(
                    GenderDisplayMap[
                      CancerFormService.getCancerFormInfo()?.gender || ""
                    ]
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("dob")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {CancerFormService.getCancerFormInfo()?.dob || ""}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="textGray body14">{t("nationality")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">Việt Nam</div>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const _handleChangeEmail = (e) => {
    setValues({
      ...values,
      email: e.target.value,
      errEmail: "",
    });
  };

  const _handleChangePhone = (e) => {
    setValues({
      ...values,
      phone: e.target.value,
      errPhone: "",
    });
  };

  const _handleChangeAddress = (e) => {
    setValues({
      ...values,
      address: e.target.value,
      errAddress: "",
    });
  };

  const _renderContactInfoForm = () => {
    return (
      <Accordion defaultActiveKey="1" className="mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <div className="appH4 textBlack">
              {t("contact-info")}
              <span className="required">*</span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <Input
              label={t("email")}
              placeholder={t("email-placeholder")}
              required={true}
              leftIcon={mailImg}
              value={values.email}
              errorText={values.errEmail}
              onChange={_handleChangeEmail}
              maxLength={100}
            />
            <Input
              label={t("phone")}
              placeholder={t("phone-placeholder")}
              required={true}
              leftIcon={phoneImg}
              value={values.phone}
              errorText={values.errPhone}
              onChange={_handleChangePhone}
              maxLength={20}
            />
            <Input
              label={t("address")}
              placeholder={t("address-placeholder")}
              required={true}
              leftIcon={locationImg}
              value={values.address}
              errorText={values.errAddress}
              onChange={_handleChangeAddress}
              maxLength={100}
            />
            <div className="mb-3 body16 textBlack italic">
              <span className="w700">{t("note")} </span>
              {t("contact-info-note")}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const _handleChangeAgreeJoin = (value: boolean) => {
    console.log("_handleChangeAgreeJoin", value);
    // setAgreeJoin(value);
    setValues({
      ...values,
      agreeJoin: value,
    });
  };

  const _handleChangeAgreeToc = (value: boolean) => {
    // setAgreeToc(value);
    setValues({
      ...values,
      agreeToc: value,
    });
  };

  const _renderTocCheckboxContent = () => {
    return (
      <span
        className={`ms-2 body16 w600 ${
          values.agreeToc ? "textBlack" : "textGray"
        }`}
      >
        {t("i-read-agree-with")}&nbsp;
        <a
          href="/cancer/term_and_condition"
          target="_blank"
          className={values.agreeToc ? styles.linkActive : styles.linkInactive}
        >
          {t("toc")}
        </a>
      </span>
    );
  };

  const _renderCommitment = () => {
    return (
      <Accordion defaultActiveKey="2" className="mb-3">
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <div className="appH4 textBlack">
              {t("commitment")}
              <span className="required">*</span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className={`${styles.commitmentContent} body14 mb-3`}>
              <div className="smallTitle16 mb-4">{t("commitment-title")}</div>
              {commitments &&
                commitments.map((item) => (
                  <div className="mb-4" key={item.id}>
                    {item.id}. {item.name}
                  </div>
                ))}
            </div>

            <div className="mb-3">
              <div className="mb-3 body16 textBlack italic">
                <span className="w700">{t("note")} </span>
                {t("commitment-note")}
              </div>
              <Checkbox
                text={t("i-agree-join-insurance")}
                checked={values.agreeJoin}
                onChange={_handleChangeAgreeJoin}
              />
            </div>

            <Checkbox
              // text={t("i-agree-toc")}
              textComponent={_renderTocCheckboxContent()}
              checked={values.agreeToc}
              onChange={_handleChangeAgreeToc}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const _renderButton = () => {
    return (
      <div className="rowStart">
        <Button variant="light" onClick={_handleGoBack} className="flex1 me-3">
          {t("go-back")}
        </Button>
        <Button variant="primary" onClick={_handleContinue} className="flex1">
          {t("continue")}
        </Button>
      </div>
    );
  };

  const _renderInfo = () => {
    return (
      <div className="mb-lg-0 mb-4">
        <Row className="mb-4">
          <Col sm md={8} className="d-flex flex-column justify-content-center">
            <div className="appH3 textBlack">{t("confirm-info")}</div>
          </Col>
          <Col sm md={4} className="d-flex flex-column justify-content-center">
            <div className="body16 textSecondary text-left text-sm-end">
              {t("required-info")}
            </div>
          </Col>
        </Row>

        {_renderPersonalInfo()}
        {_renderContactInfoForm()}
        {_renderCommitment()}

        {/* <div className={styles.infoForm}></div> */}
        {isLargeDevice && _renderButton()}
      </div>
    );
  };

  const _renderInsuranceItem = () => {
    return (
      <div className="mb-3">
        <Row className="mb-4">
          <Col sm className="d-flex flex-column justify-content-center">
            <div className="appH3 textBlack">{t("choosed-product")}</div>
          </Col>
        </Row>
        <InsuranceItem
          voucherCode={CancerFormService.getCancerFormInfo()?.voucherCode}
          feeTxt={CancerFormService.getCancerFormInfo()?.premiumTxt}
          feeDiscountText={
            CancerFormService.getCancerFormInfo()?.discountAmountTxt
          }
          feeAfterDiscountTxt={
            CancerFormService.getCancerFormInfo()?.premiumAfDiscountTxt
          }
          discountPercentage={
            CancerFormService.getCancerFormInfo()?.discountPercentage
          }
        />
      </div>
    );
  };

  const _handleConfirmOtp = () => {
    if (!otp || otp.length < OTP_LENGTH) {
      setErrOtp(t("otp-include-6-digit"));
      return;
    }
    _handleCreateOrder();
  };

  const _handleCancelOtp = () => {
    setShowOtpPopup(false);
    setErrOtp("");
  };

  const _handleChangeOtp = (value: string) => {
    if (value !== otp) {
      console.log("On Change Otp", value);
      setOtp(value);
      setErrOtp("");
    }
  };

  const _handleResendOtp = () => {
    _createOtp();
  };

  return (
    <div className={styles.pageContainer}>
      <OtpPopup
        phone={values.phone}
        value={otp}
        onChange={_handleChangeOtp}
        visible={showOtpPopup}
        onOk={_handleConfirmOtp}
        onCancel={_handleCancelOtp}
        key={"otpKey_" + otpKeyId}
        onResend={_handleResendOtp}
        errOtp={errOtp}
      />
      <Row className={`justify-content-center ${styles.stepper}`}>
        <Col lg={8}>
          <div className="rowCenter">
            <HorizontalStepper data={BUY_INSURANCE_STEP_DATA} step={2} />
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={5}>{_renderInfo()}</Col>
        <Col lg={3}>{_renderInsuranceItem()}</Col>
        {!isLargeDevice && <Col>{_renderButton()}</Col>}
      </Row>
    </div>
  );
};

export default ConfirmInfo;
