import { useState, useEffect, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import GenderSelector from "src/components/GenderSelector";
import styles from "src/styles/BuyInsurance.module.scss";
import DropdownInput, { DropdownItem } from "src/components/DropdownInput";
import { useTranslation } from "react-i18next";
import Input from "src/components/Input";
import VoucherInput from "src/components/VoucherInput";
import DateInput from "src/components/DateInput";
import InsuranceItem from "src/components/FreeCancerInsuranceItem";
import HorizontalStepper from "src/components/HorizontalStepper";
import { useHistory } from "react-router-dom";
import UiService from "src/services/UiService";
import {
  CARD_TYPE_VALUES,
  CardType,
  Gender,
  FREE_CANCER_SUM_ASSURED,
  VN_COUNTRY_CODE,
  ProductCode,
  BUY_INSURANCE_STEP_DATA,
  CardTypeSortDisplayMap,
  BUYING_STATUS_TYPE,
  BUYING_PROCESS,
  BUYING_PROCESS_STEP,
  BUYING_STATUS,
  BUYING_PROCESS_TRACKING,
  CHECKING_VOUCHER_CODE_FISRT,
  STRING_EMPTY,
} from "src/Constants";
import { getCategory, checkVoucher, calculatePremiumFreeCancer } from "src/api";
import Utils from "src/utils/Utils";
import CancerFormInfo from "src/pages/BuyInsurance/CancerFormInfo";
import CancerFormService from "../CancerFormService";
import ImageInput from "src/components/ImageInput";
import infoIconGray from "src/assets/images/information-circle-gray.svg";
import { useMediaQuery } from "react-responsive";
import { gaTrackEvent } from "src/api/ga-tracking";
import { string32 } from "pdfjs-dist/types/shared/util";

const PersonalInfo = () => {
  const isLargeDevice = useMediaQuery({
    query: "(min-width: 992px)",
  });
  const [voucherCode, setVoucherCode] = useState<string>("");
  const [isValidVoucher, setIsValidVoucher] = useState<boolean | null>(null);
  const [errVoucherCode, setErrVoucherCode] = useState<string>("");
  const [dob, setDob] = useState<Date>();
  const [errDob, setErrDob] = useState<string>();
  const [gender, setGender] = useState<Gender | null>(
    CancerFormService.getCancerFormInfo()?.gender || null
  );
  const [errGender, setErrGender] = useState<string>("");
  const [idCardType, setIdCardType] = useState<CardType>();
  const [issueDate, setIssueDate] = useState<Date>();
  const [errIssueDate, setErrIssueDate] = useState<string>();
  const [issuePlace, setIssuePlace] = useState<string>("");
  const [errIssuePlace, setErrIssuePlace] = useState<string>("");
  const [errIdCardType, setErrIdCardType] = useState<string>("");
  const [idCardNo, setIdCardNo] = useState<string>("");
  const [errIdCardNo, setErrIdCardNo] = useState<string>("");
  const [fullname, setFullname] = useState<string>("");
  const [errFullname, setErrFullname] = useState<string>("");
  const [nationality, setNationality] = useState<string>(VN_COUNTRY_CODE);
  const [errNationality, setErrNationality] = useState<string>("");
  const [nationList, setNationList] = useState<DropdownItem[]>();
  const [frontIdCard, setFrontIdCard] = useState<File>();
  const [errFrontIdCard, setErrFrontIdCard] = useState<string>();
  const [backIdCard, setBackIdCard] = useState<File>();
  const [errBackIdCard, setErrBackIdCard] = useState<string>();
  const [signature, setSignature] = useState<File>();
  const [errSignature, setErrSignature] = useState<string>();
  const { t } = useTranslation();
  const history = useHistory();

  const _loadCategory = async () => {
    try {
      const categoryRes = await getCategory(ProductCode.CANCER);
      console.log("categoryRes", categoryRes);
      localStorage.setItem(
        "cancer_commitments",
        JSON.stringify(categoryRes?.data?.commitments)
      );
      localStorage.setItem(
        "nationList",
        JSON.stringify(categoryRes?.data?.nationalitys)
      );
      localStorage.setItem(
        "cancer_question",
        JSON.stringify(categoryRes?.data?.questions)
      );
      setNationList(
        categoryRes?.data?.nationalitys.map((item) => ({
          name: item.name,
          value: item.id,
        }))
      );
    } catch (err) {
      console.log("_loadCategory err", err);
    }
  };

  const _fillData = () => {
    if (CancerFormService.getCancerFormInfo()?.voucherCode) {
      setVoucherCode(CancerFormService.getCancerFormInfo()?.voucherCode!);
      setIsValidVoucher(true);
      console.log("Fill valid voucher");
    }
    if (CancerFormService.getCancerFormInfo()?.fullname) {
      setFullname(CancerFormService.getCancerFormInfo()?.fullname!);
    }
    if (CancerFormService.getCancerFormInfo()?.idCardNo) {
      setIdCardNo(CancerFormService.getCancerFormInfo()?.idCardNo!);
    }
    if (CancerFormService.getCancerFormInfo()?.idCardType) {
      setIdCardType(CancerFormService.getCancerFormInfo()?.idCardType);
    }
    if (CancerFormService.getCancerFormInfo()?.issuedDate) {
      setIssueDate(
        Utils.covertToDate(CancerFormService.getCancerFormInfo()!.issuedDate!)
      );
    }
    if (CancerFormService.getCancerFormInfo()?.dob) {
      setDob(Utils.covertToDate(CancerFormService.getCancerFormInfo()!.dob!));
    }
    if (CancerFormService.getCancerFormInfo()?.issuePlace) {
      setIssuePlace(CancerFormService.getCancerFormInfo()?.issuePlace!);
    }
    if (CancerFormService.getCancerFormInfo()?.gender) {
      setGender(CancerFormService.getCancerFormInfo()!.gender!);
    }
    if (CancerFormService.frontIdCard) {
      setFrontIdCard(CancerFormService.frontIdCard);
    }
    if (CancerFormService.backIdCard) {
      setBackIdCard(CancerFormService.backIdCard);
    }
    if (CancerFormService.signature) {
      setSignature(CancerFormService.signature);
    }
  };

  useEffect(() => {
    _loadCategory();
    _fillData();
  }, []);

  const _validate = (): boolean => {
    let isValid: boolean = true;
    if (!voucherCode || !voucherCode.trim()) {
      setErrVoucherCode(t("voucher-code-required"));
      isValid = false;
    }
    if (!isValidVoucher) {
      isValid = false;
    }
    if (!idCardType) {
      setErrIdCardType(t("id-card-type-required"));
      isValid = false;
    }
    if (!idCardNo || idCardNo.trim() === "") {
      setErrIdCardNo(t("id-card-no-required"));
      isValid = false;
    } else if (idCardType === CardType.CMND && !Utils.isValidCMND(idCardNo)) {
      setErrIdCardNo(t("invalid-cmnd"));
      isValid = false;
    } else if (idCardType === CardType.CCCD && !Utils.isValidCCCD(idCardNo)) {
      setErrIdCardNo(t("invalid-cccd"));
      isValid = false;
    } else if (
      idCardType === CardType.PASSPORT &&
      !Utils.isValidPassport(idCardNo)
    ) {
      setErrIdCardNo(t("invalid-passport"));
      isValid = false;
    } else if (idCardType === CardType.CMTQD && !Utils.isValidCMTQD(idCardNo)) {
      setErrIdCardNo(t("invalid-cmntqd"));
      isValid = false;
    }
    if (!issueDate) {
      setErrIssueDate(t("issue-date-required"));
      isValid = false;
    }
    if (!issuePlace || !issuePlace.trim()) {
      setErrIssuePlace(t("issue-place-required"));
      isValid = false;
    }

    if (!fullname || fullname.trim() === "") {
      setErrFullname(t("name-required"));
      isValid = false;
    }
    if (!gender) {
      setErrGender(t("gender-required"));
      isValid = false;
    }
    if (!dob) {
      setErrDob(t("dob-required"));
      isValid = false;
    }

    if (!nationality) {
      setErrNationality(t("nationality-required"));
      isValid = false;
    }

    if (!frontIdCard) {
      setErrFrontIdCard(
        Utils.getReplaceText(
          t("front-id-card-required"),
          _getCardTypeSortDisplay(idCardType)
        )
      );
      isValid = false;
    } else if (!Utils.isValidImageFileType(frontIdCard)) {
      setErrFrontIdCard(t("invalid-image-type"));
      isValid = false;
    } else if (!Utils.isValidUploadImageSize(frontIdCard)) {
      setErrFrontIdCard(t("invalid-image-size"));
      isValid = false;
    }
    if (idCardType !== CardType.PASSPORT) {
      if (!backIdCard) {
        setErrBackIdCard(
          Utils.getReplaceText(
            t("back-id-card-required"),
            _getCardTypeSortDisplay(idCardType)
          )
        );
        isValid = false;
      } else if (!Utils.isValidImageFileType(backIdCard)) {
        setErrBackIdCard(t("invalid-image-type"));
        isValid = false;
      } else if (!Utils.isValidUploadImageSize(backIdCard)) {
        setErrBackIdCard(t("invalid-image-size"));
        isValid = false;
      }
    }

    if (!signature) {
      setErrSignature(t("signature-required"));
      isValid = false;
    } else if (!Utils.isValidImageFileType(signature)) {
      setErrSignature(t("invalid-image-type"));
      isValid = false;
    } else if (!Utils.isValidUploadImageSize(signature)) {
      setErrSignature(t("invalid-image-size"));
      isValid = false;
    }

    if (!isValid) return false;
    if (nationality !== VN_COUNTRY_CODE) {
      UiService.openPopup({
        showContactNow: true,
        content: t("product-not-support-foreign-people"),
        onOk: () => {
          UiService.closePopup();
        },
        onCancel: () => {
          UiService.closePopup();
        },
      });
      isValid = false;
    }
    return isValid;
  };

  const _handleContinue = async () => {
    const isValid = _validate();
    if (!isValid) return;
    const isValidVoucherCode = await _checkVoucherCode();

    if (!isValidVoucherCode) return;
    try {
      UiService.showLoading();
      const calculatePremiumResponse = await calculatePremiumFreeCancer({
        dob: Utils.formatDate(dob!),
        gender,
        voucherCode: voucherCode || null,
        idCardNo,
        idCardType: idCardType,
        sumAssured: FREE_CANCER_SUM_ASSURED,
      });

      gaTrackEvent(BUYING_PROCESS_TRACKING,
        BUYING_PROCESS[BUYING_PROCESS_STEP.InputCustomerInfo].name, 
        CancerFormService.getCancerFormInfo()?.idCardNo ?? STRING_EMPTY);

      const cancerFormInfo: CancerFormInfo = {
        dob: Utils.formatDate(dob!),
        gender: gender!,
        voucherCode: voucherCode!,
        idCardNo: idCardNo!,
        idCardType: idCardType!,
        fullname,
        premium: +calculatePremiumResponse?.data?.premiumAm,
        premiumAfterDiscount:
          +calculatePremiumResponse?.data?.premiumAfDiscountAm,
        issuePlace: issuePlace,
        issuedDate: Utils.formatDate(issueDate!),
        premiumAfDiscountTxt:
          calculatePremiumResponse?.data?.premiumAfDiscountTxt,
        premiumTxt: calculatePremiumResponse?.data?.premiumTxt,
        discountAmountTxt: calculatePremiumResponse?.data?.discountAmountTxt,
        discountPercentage: calculatePremiumResponse?.data?.discountPercentage,
      };
      CancerFormService.saveCancerFormInfo(cancerFormInfo);
      UiService.hideLoading();
      console.log("calculatePremiumResponse", calculatePremiumResponse);
      history.push("/cancer/buy-insurance/health-question");
    } catch (err: any) {
      console.log("calculatePremiumFreeCancer err", err);
      UiService.hideLoading();
      if (err?.response?.data?.code === 400) {
        UiService.openPopup({
          content: err?.response?.data?.error_message,
          showContactNow: true,
          onOk: () => {
            UiService.closePopup();
          },
          onCancel: () => {
            UiService.closePopup();
          },
        });
      }
    }
  };

  const _handleChangeVoucherCode = (e) => {
    setVoucherCode(e.target.value.toUpperCase());
    setIsValidVoucher(null);
    setErrVoucherCode("");
  };

  const _handleClearVoucherCode = () => {
    setVoucherCode("");
    setIsValidVoucher(null);
    setErrVoucherCode("");
  };

  const _checkVoucherCode = async (): Promise<boolean> => {
    console.log("_checkVoucherCode", voucherCode);
    if (!voucherCode || !voucherCode.trim()) return false;
    try {
      gaTrackEvent(BUYING_PROCESS_TRACKING,
        BUYING_PROCESS[BUYING_PROCESS_STEP.InputVoucherCode].name, 
        CancerFormService.getCancerFormInfo()?.idCardNo ?? STRING_EMPTY);
      UiService.showLoading();
      const checkVoucherRes = await checkVoucher(voucherCode);
      setIsValidVoucher(true);
      console.log("Check voucher res", checkVoucherRes);
      UiService.hideLoading();
      return true;
    } catch (err: any) {
      UiService.hideLoading();
      if (err?.response?.data?.code === 400) {
        setIsValidVoucher(false);
        setErrVoucherCode(err?.response?.data?.error_data?.messsage);
      }
      return false;
    }
  };

  const _handleChangeIdCardNo = (e) => {
    setIdCardNo(e.target.value.replace(/[^0-9a-zA-Z]/gi, ""));
    setErrIdCardNo("");
  };

  const _handleChangeIssueDate = (value) => {
    setIssueDate(value);
    setErrIssueDate("");
  };

  const _handleChangeIssuePlace = (e) => {
    setIssuePlace(e.target.value);
    setErrIssuePlace("");
  };

  const _handleChangeFullname = (e) => {
    setFullname(e.target.value);
    setErrFullname("");
  };

  const _handleChangeDob = (value) => {
    console.log("On Change Dob", value);
    setDob(value);
    setErrDob("");
  };

  const _handleChangeIdCardType = (value) => {
    setIdCardType(value);
    setErrIdCardType("");
  };

  const _handleChangeNationality = (value) => {
    setNationality(value);
    setErrNationality("");
  };

  const _handleChangeGender = (gender) => {
    setGender(gender);
    setErrGender("");
  };

  const _handleChangeFrontIdCard = useCallback(
    (file: File) => {
      // console.log("File Size", e.target.files[0]);
      setFrontIdCard(file);
      if (!Utils.isValidImageFileType(file)) {
        setErrFrontIdCard(t("invalid-image-type"));
      } else if (!Utils.isValidUploadImageSize(file)) {
        setErrFrontIdCard(t("invalid-image-size"));
      } else {
        setErrFrontIdCard("");
        CancerFormService.frontIdCard = file;
      }
    },
    [setFrontIdCard, setErrFrontIdCard, t]
  );

  const _handleChangeBackIdCard = useCallback(
    (file: File) => {
      setBackIdCard(file);
      if (!Utils.isValidImageFileType(file)) {
        setErrBackIdCard(t("invalid-image-type"));
      } else if (!Utils.isValidUploadImageSize(file)) {
        setErrBackIdCard(t("invalid-image-size"));
      } else {
        setErrBackIdCard("");
        CancerFormService.backIdCard = file;
      }
    },
    [setBackIdCard, setErrBackIdCard, t]
  );

  const _handleChangeSignature = useCallback(
    (file: File) => {
      setSignature(file);
      if (!Utils.isValidImageFileType(file)) {
        setErrSignature(t("invalid-image-type"));
      } else if (!Utils.isValidUploadImageSize(file)) {
        setErrSignature(t("invalid-image-size"));
      } else {
        setErrSignature("");
        CancerFormService.signature = file;
      }
    },
    [setSignature, setErrSignature, t]
  );
  const _getCardTypeSortDisplay = (cardType?: CardType | null) => {
    if (!cardType) return "";
    return t(CardTypeSortDisplayMap[cardType]);
  };

  const _getTodayDay = () => {
    const todayDate = new Date();
    return {
      day: todayDate.getDate(),
      month: todayDate.getMonth() + 1,
      year: todayDate.getFullYear(),
    };
  };

  const _renderIdentityCardForm = () => {
    return (
      <Accordion defaultActiveKey="1" className="mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <div className="appH4 textBlack">
              {t("identity-card")}
              <span className="required">*</span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            {idCardType !== CardType.PASSPORT ? (
              <>
                <Row>
                  <Col sm className="mb32">
                    <ImageInput
                      file={frontIdCard}
                      label={Utils.getReplaceText(
                        t("front-id-card-img"),
                        _getCardTypeSortDisplay(idCardType)
                      )}
                      onChange={_handleChangeFrontIdCard}
                      errText={errFrontIdCard}
                    />
                  </Col>
                  <Col sm className="mb32">
                    <ImageInput
                      label={Utils.getReplaceText(
                        t("back-id-card-img"),
                        _getCardTypeSortDisplay(idCardType)
                      )}
                      file={backIdCard}
                      errText={errBackIdCard}
                      onChange={_handleChangeBackIdCard}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ImageInput
                      label={t("signature-img")}
                      file={signature}
                      onChange={_handleChangeSignature}
                      imageClassName={styles.signatureImage}
                      imageContainerClassName={styles.signatureImageContainer}
                      errText={errSignature}
                      helperText={t("please-sign-to-paper")}
                      helperIcon={infoIconGray}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col sm className="mb32">
                    <ImageInput
                      file={frontIdCard}
                      label={Utils.getReplaceText(
                        t("front-id-card-img"),
                        _getCardTypeSortDisplay(idCardType)
                      )}
                      onChange={_handleChangeFrontIdCard}
                      errText={errFrontIdCard}
                    />
                  </Col>
                  <Col sm className="mb32">
                    {/* <ImageInput
                      label={Utils.getReplaceText(
                        t("back-id-card-img"),
                        _getCardTypeSortDisplay(idCardType)
                      )}
                      file={backIdCard}
                      errText={errBackIdCard}
                      onChange={_handleChangeBackIdCard}
                    /> */}
                    <ImageInput
                      label={t("signature-img")}
                      file={signature}
                      onChange={_handleChangeSignature}
                      errText={errSignature}
                      helperText={t("please-sign-to-paper")}
                      helperIcon={infoIconGray}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const _renderInfoForm = () => {
    return (
      <div className={styles.infoForm}>
        <div className="appH4 pb32">
          {t("fill-info")} <span className="textSecondary">*</span>
        </div>
        <div className="line mb24" />
        <VoucherInput
          label={t("promotion-code")}
          placeholder={t("promotion-code-placeholder")}
          required={true}
          value={voucherCode}
          onChange={_handleChangeVoucherCode}
          onCheckVoucherCode={_checkVoucherCode}
          errorText={errVoucherCode}
          isValidVouhcer={isValidVoucher}
          onClear={_handleClearVoucherCode}
        />
        <Input
          label={t("fullname")}
          placeholder={t("fullname-placeholder")}
          required={true}
          maxLength={60}
          value={fullname}
          errorText={errFullname}
          onChange={_handleChangeFullname}
        />
        <Row>
          <Col md>
            <DropdownInput
              label={t("identity-card-type")}
              placeholder={t("identity-card-type-placeholder")}
              values={CARD_TYPE_VALUES.map((item) => ({
                ...item,
                name: t(item.name),
              }))}
              value={idCardType}
              onChange={_handleChangeIdCardType}
              required={true}
              errorText={errIdCardType}
            />
          </Col>
          <Col md>
            <Input
              label={t("identity-card-no")}
              placeholder={t("identity-card-no-placeholder")}
              required={true}
              maxLength={60}
              value={idCardNo}
              onChange={_handleChangeIdCardNo}
              errorText={errIdCardNo}
            />
          </Col>
        </Row>
        <Row>
          <Col md>
            <DateInput
              placeholder={t("issue-date-placeholder")}
              label={t("issue-date")}
              required={true}
              value={issueDate}
              onChange={_handleChangeIssueDate}
              errorText={errIssueDate}
              maximumDate={_getTodayDay()}
            />
          </Col>
          <Col md>
            <Input
              label={t("issue-place")}
              placeholder={t("issue-place-placeholder")}
              required={true}
              maxLength={60}
              value={issuePlace}
              errorText={errIssuePlace}
              onChange={_handleChangeIssuePlace}
            />
          </Col>
        </Row>

        <GenderSelector
          value={gender}
          onChange={_handleChangeGender}
          errorText={errGender}
        />

        <DateInput
          placeholder={t("dob-placeholder")}
          label={t("dob")}
          required={true}
          value={dob}
          onChange={_handleChangeDob}
          errorText={errDob}
          maximumDate={_getTodayDay()}
        />

        <DropdownInput
          label={t("nationality")}
          placeholder={t("nationality-placeholder")}
          values={nationList}
          value={nationality}
          onChange={_handleChangeNationality}
          required={true}
          errorText={errNationality}
        />
      </div>
    );
  };

  const _renderForm = () => {
    return (
      <div className="mb-lg-0 mb-4">
        <Row className="mb-4">
          <Col sm md={8} className="d-flex flex-column justify-content-center">
            <div className="appH3 textBlack">{t("fill-personal-info")}</div>
          </Col>
          <Col sm md={4} className="d-flex flex-column justify-content-center">
            <div className="body16 textSecondary text-left text-sm-end">
              {t("required-info")}
            </div>
          </Col>
        </Row>

        {_renderInfoForm()}
        {_renderIdentityCardForm()}
        {isLargeDevice && _renderButton()}
      </div>
    );
  };

  const _renderButton = () => {
    return (
      <Row>
        <Col>
          <Button
            variant="primary"
            onClick={_handleContinue}
            className="fullWidth"
          >
            {t("continue")}
          </Button>
        </Col>
      </Row>
    );
  };

  const _renderInsuranceItem = () => {
    return (
      <div className="mb-3">
        <Row className="mb-4">
          <Col sm className="d-flex flex-column justify-content-center">
            <div className="appH3 textBlack">{t("choosed-product")}</div>
          </Col>
        </Row>
        <InsuranceItem />
      </div>
    );
  };

  return (
    <div className={styles.pageContainer}>
      <Row className={`justify-content-center ${styles.stepper}`}>
        <Col lg={8}>
          <div className="rowCenter">
            <HorizontalStepper data={BUY_INSURANCE_STEP_DATA} step={0} />
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={6}>{_renderForm()}</Col>
        <Col lg={3}>{_renderInsuranceItem()}</Col>
        {!isLargeDevice && <Col>{_renderButton()}</Col>}
      </Row>
    </div>
  );
};

export default PersonalInfo;
