import accidentImg1 from "src/assets/images/accident-page-1.png";
import accidentImg2 from "src/assets/images/accident-page-2.png";
import accidentImg3 from "src/assets/images/accident-page-3.png";
import styles from "./ProductAccident.module.scss";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useMediaQuery } from "react-responsive";
import dollarImg from "src/assets/images/currency-dollar.svg";
import creditCardImg from "src/assets/images/credit-card.svg";
import calculatorImg from "src/assets/images/calculator.svg";

const Product360 = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const isUnderMd = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const isUnderXl = useMediaQuery({
    query: "(max-width: 1200px)",
  });

  const _renderProductSortInfo = () => {
    if (isMobile) {
      return (
        <div>
          <img
            src={accidentImg1}
            className={styles.image}
            alt="Caner Insurance"
          />
          <div className={styles.productSortInfoContainer}>
            <div className="rowStart mb-2">
              <div className="lineLeft" />
              <div className="title18 textBlack2Opacity">
                {t("accident-insurance").toUpperCase()}
              </div>
            </div>
            <div className="appH1 textBlack prewrap">
              {t("accident-insurance-name")}
            </div>
          </div>
        </div>
      );
    }
    return (
      <Row className={styles.producerSortInfoRow}>
        <div className={styles.productSortInfoOuter}>
          <div className={styles.productSortInfoContainer}>
            <div className="rowStart mb32">
              <div className="lineLeft" />
              <div className="title18 textBlack2Opacity">
                {t("accident-insurance").toUpperCase()}
              </div>
            </div>
            <div className="appH1 textBlack mb-4 prewrap">
              {t("accident-insurance-name")}
            </div>
          </div>
        </div>
        <Col lg={4}></Col>
        <Col lg={8}>
          <img
            src={accidentImg1}
            className={styles.image}
            alt="Caner Insurance"
          />
        </Col>
      </Row>
    );
  };

  const _renderDescription = () => {
    return (
      <Container fluid>
        <Row>
          <Col xl={6} lg={4} className="gx-0">
            <img src={accidentImg2} className={styles.image} alt="Cancer" />
          </Col>
          <Col xl={6} lg={8} className="gx-0">
            <div className={styles.descriptionContainer}>
              <div className="rowStart mb24">
                <div className={"lineLeft"} />
                <div className={`textBlack2Opacity subTitle`}>
                  {t("customer-service").toUpperCase()}
                </div>
              </div>
              <div className="appH2 textBlack mb24">
                {t("info-abount-product")}
              </div>
              <div className={styles.aboutProductItem}>
                <div className="rowStart mb-3">
                  <div className={styles.aboutProductIconContainer}>
                    <img
                      src={dollarImg}
                      alt="dollar"
                      className={styles.aboutProductIcon}
                    />
                  </div>
                  <div className="smallTitle">{t("insurance-money")}</div>
                </div>
                <div className="appH3 textPrimary">300,000,000 VNĐ</div>
              </div>
              <div className={styles.aboutProductItem}>
                <div className="rowStart mb-3">
                  <div className={styles.aboutProductIconContainer}>
                    <img
                      src={creditCardImg}
                      alt="credit card"
                      className={styles.aboutProductIcon}
                    />
                  </div>
                  <div className="smallTitle">{t("method-and-fee-term")}</div>
                </div>
                <div className="body18 textGray">
                  Trích trừ tự động từ tài khoản ngân hàng hàng tháng.
                </div>
              </div>
              <div className={styles.aboutProductItemLast}>
                <div className="rowStart mb-3">
                  <div className={styles.aboutProductIconContainer}>
                    <img
                      src={calculatorImg}
                      alt="caculator"
                      className={styles.aboutProductIcon}
                    />
                  </div>
                  <div className="smallTitle">{t("insurance-fee")}</div>
                </div>
                <Row>
                  <Col className="mb-3">
                    <div className="body16 textBlack w600 fontSourceSansPro">
                      Cho nữ
                    </div>
                    <div className="body16 textSecondary w600 fontSourceSansPro">
                      <span className="body24 w600">65,000</span> VNĐ/tháng
                    </div>
                  </Col>
                  <Col className="mb-3">
                    <div className="body16 textBlack w600 fontSourceSansPro">
                      Cho nam
                    </div>
                    <div className="body16 textSecondary w600 fontSourceSansPro">
                      <span className="body24 w600">95,000</span> VNĐ/tháng
                    </div>
                  </Col>
                </Row>
                <div className="body16 textGray">
                  (Miễn phí kỳ đầu đối với một số Khách hàng may mắn)
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  };

  const _renderProductBenefit = () => {
    if (isUnderXl) {
      return (
        <Row>
          <Col className="gx-0" md>
            <img
              src={accidentImg3}
              className={styles.image}
              alt="Accident Insurance"
            />
          </Col>
          <Col className="gx-0" md>
            <div className={styles.productBenefitContainer}>
              <div className="rowStart mb24">
                <div className="lineLeft" />
                <div className="title18 textBlack2Opacity">
                  {t("customer-service").toUpperCase()}
                </div>
              </div>
              <div className="appH2 textBlack mb40 prewrap">
                {t("insurance-benefit")}
              </div>
              <div className="body24 mb-3 textBlack opacity72">
                Chi trả lên đến{" "}
                <span className="w700 textPrimary">300,000,000 VNĐ</span> trong
                trường hợp NĐBH Tử vong hoặc bị Thương tật (toàn bộ hoặc bộ
                phận) vĩnh viễn do tai nạn.
              </div>
              <div className="body24 mb-3 textBlack opacity72">
                Quyền lợi Tử vong hoặc Thương tật vĩnh viễn do Tai nạn được{" "}
                <span className="w700 textPrimary">nhân đôi</span> nếu Tai nạn
                xảy ra ở nước ngoài hoặc trên các chuyến bay thương mại.
              </div>
              <div className="body24 textBlack opacity72">
                Chi trả{" "}
                <span className="w700 textPrimary">600,000 VNĐ/ngày</span> nếu
                Người được bảo hiểm phải nhập viện nội trú do tai nạn từ 2 ngày
                trở lên.
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    return (
      <Row className={`${styles.producerBenefitRow} gx-0`}>
        <div className={styles.productBenefitOuter}>
          <div className={styles.productBenefitContainer}>
            <div className="rowStart mb24">
              <div className="lineLeft" />
              <div className="title18 textBlack2Opacity">
                {t("customer-service").toUpperCase()}
              </div>
            </div>
            <div className="appH2 textBlack mb40 prewrap">
              {t("insurance-benefit")}
            </div>
            <div className="body24 mb-4 textBlack opacity72">
              Chi trả lên đến{" "}
              <span className="w700 textPrimary">300,000,000 VNĐ</span> trong
              trường hợp NĐBH Tử vong hoặc bị Thương tật (toàn bộ hoặc bộ phận)
              vĩnh viễn do tai nạn.
            </div>
            <div className="body24 mb-4 textBlack opacity72">
              Quyền lợi Tử vong hoặc Thương tật vĩnh viễn do Tai nạn được{" "}
              <span className="w700 textPrimary">nhân đôi</span> nếu Tai nạn xảy
              ra ở nước ngoài hoặc trên các chuyến bay thương mại.
            </div>
            <div className="body24 textBlack opacity72">
              Chi trả <span className="w700 textPrimary">600,000 VNĐ/ngày</span>{" "}
              nếu Người được bảo hiểm phải nhập viện nội trú do tai nạn từ 2
              ngày trở lên.
            </div>
          </div>
        </div>
        <Col md className="gx-0"></Col>
        <Col md className="gx-0">
          <img
            src={accidentImg3}
            className={styles.image}
            alt="Accident Insurance"
          />
        </Col>
      </Row>
    );
  };

  return (
    <div className={styles.container}>
      <Container fluid>
        <Row className="justify-content-center">
          <Col lg={9} className="gx-0">
            {_renderProductSortInfo()}
          </Col>
        </Row>
      </Container>
      {_renderDescription()}
      {_renderProductBenefit()}
    </div>
  );
};

export default Product360;
