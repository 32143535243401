import styles from "./InsuranceItem.module.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import cancerInsurance2Img from "../../assets/images/cancer_insurance2.png";
import shieldImg from "../../assets/images/shield.svg";
import { CANCER_URL } from "src/Constants";
import InsurancePackage from "src/models/InsurancePackage";
import Discount from "src/models/Discount";
import Utils from "src/utils/Utils";

interface InsuranceItemProps {
  data?: InsurancePackage | null;
  discount?: Discount | null;
  afterDiscount?: number | null;
  beforeDiscount?: number | null;
  discountAmt?: number | null;
}

const InsuranceItem = ({
  data,
  discount,
  beforeDiscount,
  afterDiscount,
  discountAmt,
}: InsuranceItemProps) => {
  const { t } = useTranslation();

  const _renderBenefit = () => {
    return (
      <>
        <div className={styles.benefitRow}>
          <div className="me-2">
            <img width="20" height="20" src={shieldImg} alt="Shield" />
          </div>

          <div className="body14 w600 textBlack">
            {t("cancer-benefit")}:{" "}
            <span className="textSecondary">{data?.insamountTxt}</span>
          </div>
        </div>
        <div className={styles.benefitRow2}>
          <div className="me-2">
            <img width="20" height="20" src={shieldImg} alt="Shield" />
          </div>
          <div className="body14 w600 textBlack">
            {t("live-benefit")}:{" "}
            <span className="textSecondary">{data?.survivalTxt}</span>
          </div>
        </div>
        <div className={styles.benefitRow}>
          <div className="me-2" style={{ opacity: 0 }}>
            <img width="20" height="20" src={shieldImg} alt="Shield" />
          </div>
          <span className="caption textGray">
            ({t("live-benefit-description")})
          </span>
        </div>
        <div className={styles.benefitRow}>
          <div className="me-2">
            <img width="20" height="20" src={shieldImg} alt="Shield" />
          </div>
          <div className="body14 w600 textBlack">
            {t("insurance-period")}:{" "}
            <span className="textSecondary">5 năm</span>
          </div>
        </div>
        <div className={styles.benefitRow}>
          <div className="me-2">
            <img width="20" height="20" src={shieldImg} alt="Shield" />
          </div>
          <div className="body14 w600 textBlack">
            {t("fee-term")}: <span className="textSecondary">Hàng năm</span>
          </div>
        </div>
        <div className={styles.benefitRow}>
          <div className="me-2">
            <img width="20" height="20" src={shieldImg} alt="Shield" />
          </div>
          <div className="body14 w600 textBlack">
            {t("new-contract-benefit")}
          </div>
        </div>
      </>
    );
  };

  const _renderPaymentInfo = () => {
    if (discount && discountAmt) {
      return (
        <>
          <div className="body16 w700 textBlack mb-3">{t("payment-info")}</div>
          <div className="rowStart mb-2">
            <div className="flex1 body14 textBack">{t("yearly-fee")}</div>
            <div className="body14 w600 textBack">
              {Utils.formatMoney(beforeDiscount)} {t("vnd")}
            </div>
          </div>
          <div className="rowStart mb-2">
            <div className="flex1 body14 textBack">
              {t("discount-for-first-year")}
            </div>
            <div className="body14 w600 textBack">
              -{Utils.formatMoney(discountAmt)} {t("vnd")}
            </div>
          </div>
          <div className="rowStart mb-2">
            <div className="flex1 body14 textBack w600">
              {t("amount-first-year")}
            </div>
            <div className="body14 textSecondary w700">
              {Utils.formatMoney(afterDiscount)} {t("vnd")}
            </div>
          </div>
          <div className="line mb-3 mt-3" />
        </>
      );
    }
    return (
      <>
        <div className="body16 w700 textBlack mb-3">{t("payment-info")}</div>
        <div className="rowStart mb-2">
          <div className="flex1 body14 textBack">{t("yearly-fee")}</div>
          <div className="body14 w600 textBack">{data?.premiumAmTxt}</div>
        </div>
        <div className="rowStart mb-2">
          <div className="flex1 body14 textBack w600">
            {t("amount-first-year")}
          </div>
          <div className="body14 textSecondary w700">{data?.premiumAmTxt}</div>
        </div>
        <div className="line mb-3 mt-3" />
      </>
    );
  };

  const _renderImage = () => {
    return (
      <div className={styles.imageContainer}>
        <img
          src={cancerInsurance2Img}
          alt="Cancer insurance"
          width="1320"
          height="660"
          className="img-fluid"
        />
      </div>
    );
  };

  const _getDiscountText = () => {
    if (!discount) return "";
    if (discount.calType === "1") {
      if (!discount.discountValueMax) {
        return Utils.getReplaceText(
          t("discount-first-year"),
          discount.discountValue
        );
      }
      return Utils.getReplaceTextMulti(t("discount-first-year-with-limit"), [
        discount.discountValue?.toString(),
        Utils.formatMoney(discount.discountValueMax?.toString()),
      ]);
    } else if (discount.calType === "2") {
      return Utils.getReplaceText(
        t("discount-first-year-vnd"),
        Utils.formatMoney(discount.discountValueMin)
      );
    } else if (discount.calType === "3") {
      return Utils.getReplaceText(
        t("discount-first-year-vnd-remain"),
        Utils.formatMoney(discount.discountFixedValue)
      );
    }
    return "";
  };

  return (
    <div className={styles.container}>
      {_renderImage()}
      <div className={styles.content}>
        <div className="smallTitle14 textGray mb-1">Bảo hiểm ung thư</div>
        <div className="smallTitle textBlack mb-2">Ung dung sống khoẻ</div>
        <div className="smallTitle16 w500 textGray mb-3">
          Quan tâm mỗi ngày, ung dung từng giây
        </div>
        {data ? (
          <>
            <div className="line mb-3" />
            <Row>
              <Col>
                <div className="body14 textGray mb-1">
                  {t("insurance-package")}
                </div>
                <div className="body16 w600 textPrimary">
                  {data.insamountTxt} {t("dong")}
                </div>
              </Col>
              <Col>
                <div className="body14 textGray mb-1 text-end">
                  {t("first-year-fee")}
                </div>
                <div className="body16 w600 textSecondary text-end">
                  {data.premiumAmTxt}
                </div>
              </Col>
            </Row>

            {!!discount && (
              <div className={styles.voucherBlock}>
                <div className="body14 textPrimary">
                  {t("appling-voucher-code")}{" "}
                  <span className="w700">{discount.discount}</span>
                </div>
                <div className="body14 textPrimary">{_getDiscountText()}</div>
              </div>
            )}

            <div className="line mb-3 mt-3" />
            {_renderPaymentInfo()}
            {_renderBenefit()}
          </>
        ) : (
          <div className="body18 italic textBlack">
            {t("please-fill-info-and-choose-package")}
          </div>
        )}

        <div className="mb-3" />
        <a href={CANCER_URL} target="_blank" rel="noreferrer">
          <Button variant={"outline-light"} className="fullWidth">
            {t("more-info")}
          </Button>
        </a>
      </div>
    </div>
  );
};

export default InsuranceItem;
