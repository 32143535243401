import styles from "./Support.module.scss";
import { useTranslation } from "react-i18next";
import { SUPPORT_CATEGORY_DATA, SUPPORT_DATA } from "./data";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Input from "src/components/Input";
import { useState } from "react";
import searchImg from "src/assets/images/search.svg";
import { SupportItemData } from "src/models/SupportItemData";
import SupportItem from "src/components/SupportItem";
import SegmentControl from "src/components/SegmentControl";
import Utils from "src/utils/Utils";
import { useLocation } from "react-router-dom";

const Support = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const tabFromQuery = query.get("tab");
  const [keyword, setKeyword] = useState("");
  const [category, setCategory] = useState(tabFromQuery || "product");
  const [searchData, setSearchData] = useState<SupportItemData[]>();
  const [searching, setSearching] = useState<boolean>(false);
  const _handleChangeKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const _search = (keyword: string, category: string) => {
    if (!keyword || !keyword.trim()) {
      setSearching(false);
    } else {
      setSearching(true);
      setSearchData(_getDisplaySupportData(keyword, category));
    }
  };

  const _handleSearch = () => {
    _search(keyword, category);
  };

  const _handleKeyDownKeyword = (e) => {
    if (e.key === "Enter") {
      _handleSearch();
    }
  };

  const _renderTitle = () => {
    return (
      <div className={styles.titleContainer}>
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="appH1 textBlack text-center mb40">
                {t("support-customer")}
              </div>
              <div className={styles.inputContainer}>
                <Input
                  value={keyword}
                  onChange={_handleChangeKeyword}
                  placeholder={t("search")}
                  rightComponent={
                    <img
                      src={searchImg}
                      alt="Search"
                      className={styles.searchImg}
                      onClick={_handleSearch}
                    />
                  }
                  onBlur={_handleSearch}
                  onKeyDown={_handleKeyDownKeyword}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const _renderSupportItem = (item: SupportItemData, index: number) => {
    return (
      <div className="mb-3" key={item.id}>
        <SupportItem data={item} />
      </div>
    );
  };

  const _getDisplaySupportData = (keyword: string, category: string) => {
    if (keyword && keyword.trim()) {
      console.log("Current category", category);
      return SUPPORT_DATA.filter((item) => item.category === category).filter(
        (item) => {
          const normalizedKeyword = Utils.removeAccent(
            keyword.trim().toLowerCase()
          );
          const normalizedQuestion = Utils.removeAccent(
            item.question.toLowerCase()
          );
          const normalizedAnswer = Utils.removeAccent(
            item.answer.toLowerCase()
          );
          return (
            normalizedQuestion.indexOf(normalizedKeyword) > -1 ||
            normalizedAnswer.indexOf(normalizedKeyword) > -1
          );
        }
      );
    }
    return SUPPORT_DATA.filter((item) => item.category === category);
  };

  const _renderNoResult = () => {
    return (
      <div className="body16 textGray text-center mt-3">{t("no-result")}</div>
    );
  };

  const _renderContent = () => {
    const tabData = SUPPORT_DATA.filter((item) => item.category === category);
    return (
      <div className={styles.contentContainer}>
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={8}>
              {!searching
                ? tabData.map(_renderSupportItem)
                : !searchData || searchData.length === 0
                ? _renderNoResult()
                : searchData.map(_renderSupportItem)}
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const _handleChangeCategory = (value: string) => {
    setCategory(value);
    _search(keyword, value);
  };

  const _getCurrentCategoryName = () => {
    return t(
      SUPPORT_CATEGORY_DATA.find((item) => item.id === category)?.nameKey || ""
    );
  };

  return (
    <div>
      {_renderTitle()}
      <Container fluid>
        <Row className="justify-content-center">
          <Col lg={8}>
            <div className="rowCenter mt40">
              <SegmentControl
                data={SUPPORT_CATEGORY_DATA}
                value={category}
                onChange={_handleChangeCategory}
              />
            </div>
            <div className="appH2 textBlack text-center mt40">
              {_getCurrentCategoryName()}
            </div>
          </Col>
        </Row>
      </Container>

      {_renderContent()}
    </div>
  );
};

export default Support;
