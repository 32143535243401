import styles from "./Promotion.module.scss";
import promotionBannerImg from "src/assets/images/promotion-banner.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import documentImg from "src/assets/images/document-text.svg";
import calendar2Img from "src/assets/images/calendar2.svg";
import giftImg from "src/assets/images/gift.svg";
import fileDoneImg from "src/assets/images/file-done.svg";
import promotionImg from "src/assets/images/promotion.svg";
import checkCircleImg from "src/assets/images/check-circle-blue.svg";
import { useMediaQuery } from "react-responsive";

const Promotion = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const isUnderMd = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const { t } = useTranslation();

  const _renderName = () => {
    return (
      <div className={styles.promotionNameBlock}>
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={9}>
              <div className="rowStart opacity50 mb24">
                <div className="lineLeftOnPrimary" />
                <div className="title18 textOnPrimary">
                  {t("customer-program").toUpperCase()}
                </div>
              </div>
              <div className="appH1 textOnPrimary">{t("promotion-name")}</div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const _renderRule = () => {
    return (
      <div className={styles.ruleBlock}>
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={9}>
              <Row>
                <Col sm className="mb-4">
                  <div className={styles.infoItem}>
                    <img
                      src={documentImg}
                      className={styles.icon}
                      alt="Defination"
                    />
                    <div className="smallTitle textBlack mb14">
                      {t("defination")}
                    </div>
                    <div className="body24 textBlack mb14">
                      {t("promotion-definition-1")}
                    </div>
                    <div className="body24 textBlack mb14">
                      {t("promotion-definition-2")}
                    </div>
                    <div className="body24 textBlack">
                      {t("promotion-definition-3")}
                    </div>
                  </div>
                </Col>

                <Col sm className="mb-4">
                  <Row className={!isMobile ? "h-50" : ""}>
                    <Col sm>
                      <div className="h-100 pb32">
                        <div className={`${styles.infoItemTop} h-100`}>
                          <img
                            src={calendar2Img}
                            className={styles.icon}
                            alt="Calendar"
                          />
                          <div className="smallTitle textBlack mb-3">
                            {t("time-start-end")}
                          </div>
                          <div className="body24 textBlack">
                            01/09/2021 - 31/12/2021
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm>
                      <div className="h-100 pb32">
                        <div className={`${styles.infoItemTop} h-100`}>
                          <img
                            src={giftImg}
                            className={styles.icon}
                            alt="Gift"
                          />
                          <div className="smallTitle textBlack mb-3">
                            {t("reward-method")}
                          </div>
                          <div className="body24 textBlack">{t("cash")}</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div
                    className={`${styles.infoItem} ${!isMobile ? "h-50" : ""}`}
                  >
                    <img
                      src={fileDoneImg}
                      className={styles.icon}
                      alt="Condition"
                    />
                    <div className="smallTitle textBlack mb-3">
                      {t("condition")}
                    </div>
                    <div className="body24 textBlack">
                      {t("promotion-condition")}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const _renderProgramDetailUnderMd = () => {
    return (
      <div className={styles.programDetailBlock}>
        <div className="appH2 textBlack text-center mb72">
          {t("program-detail")}
        </div>
        <div className={styles.programDetailRow}>
          <div className="appH3 textBlack mb-2">{t("reward-structure")}</div>
          <div className="body24 textBlack mb-4">Chặng 1</div>
          <div className="appH3 textBlack mb-2">{t("reward")}</div>
          <div className="body24 textBlack">
            <span className="w700">Thưởng 25%</span> phí bảo hiểm năm đầu cho
            mỗi HĐBH phát hành thành công
          </div>
        </div>
        <div className={styles.programDetailRow}>
          <div className="appH3 textBlack mb-2">{t("reward-structure")}</div>
          <div className="body24 textBlack mb-4">Chặng 2</div>
          <div className="appH3 textBlack mb-2">{t("reward")}</div>
          <div className="body24 textBlack">
            <span className="w700">+ Thưởng thêm 300k</span> nếu Người giới
            thiệu từ 3 HĐBH trở lên
          </div>
          <div className="body24 textBlack">
            <span className="w700">+ Thưởng 500k</span> nếu Người giới thiệu từ
            5 HĐBH trở lên
          </div>
        </div>
        <div className={styles.programDetailRow}>
          <div className="appH3 textBlack mb-2">{t("reward-structure")}</div>
          <div className="body24 textBlack mb-4">Chặng 3</div>
          <div className="appH3 textBlack mb-2">{t("reward")}</div>
          <div className="body24 textBlack">
            <span className="w700">Top 5</span> Người giới thiệu xuất sắc nhất
            trong tuần: Mỗi giải <span className="w700">500k</span>
          </div>
          <div className="body24 textBlack">
            <span className="w700">Top 10</span> Người giới thiệu xuất sắc nhất
            trong tháng:
          </div>
          <div className="body24 textBlack">
            <span className="w700">1 giải đặc biệt:</span> 10 triệu
          </div>
          <div className="body24 textBlack">
            <span className="w700">1 giải nhất:</span> 5 triệu
          </div>
          <div className="body24 textBlack">
            <span className="w700">2 giải 2:</span> 5 triệu{" "}
          </div>
          <div className="body24 textBlack">
            <span className="w700">3 giải 3:</span> 2,5 triệu
          </div>

          <div className="body24 textBlack">
            <span className="w700">4 giải tư:</span> mỗi giải 1,50k
          </div>
        </div>
      </div>
    );
  };

  const _renderProgramDetail = () => {
    if (isUnderMd) {
      return _renderProgramDetailUnderMd();
    }
    return (
      <div className={styles.programDetailBlock}>
        <Container fluid className="gx-0">
          <Row className="justify-content-center">
            <Col xl={10} lg={12}>
              <div className={styles.programDetailContainer}>
                <div className="appH2 textBlack text-center mb72">
                  {t("program-detail")}
                </div>
                <div className={`${styles.programDetailRow} mb-2`}>
                  <Row>
                    <Col md={4}>
                      <div className="appH3 textBlack">
                        {t("reward-structure")}
                      </div>
                    </Col>
                    <Col md={8}>
                      <div className="appH3 textBlack">{t("reward")}</div>
                    </Col>
                  </Row>
                </div>
                <div className={`${styles.programDetailRow} mb-2`}>
                  <Row>
                    <Col md={4}>
                      <div className="body24 textBlack">Chặng 1</div>
                    </Col>
                    <Col md={8}>
                      <div className="body24 textBlack">
                        <span className="w700">Thưởng 25%</span> phí bảo hiểm
                        năm đầu cho mỗi HĐBH phát hành thành công
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className={`${styles.programDetailRow} mb-2`}>
                  <Row>
                    <Col md={4}>
                      <div className="body24 textBlack">Chặng 2</div>
                    </Col>
                    <Col md={8}>
                      <div className="body24 textBlack">
                        <span className="w700">+ Thưởng thêm 300k</span> nếu
                        Người giới thiệu từ 3 HĐBH trở lên
                      </div>
                      <div className="body24 textBlack">
                        <span className="w700">+ Thưởng 500k</span> nếu Người
                        giới thiệu từ 5 HĐBH trở lên
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className={styles.programDetailRow}>
                  <Row>
                    <Col lg={4}>
                      <div className="body24 textBlack">Chặng 3</div>
                    </Col>
                    <Col lg={8}>
                      <div className="body24 textBlack">
                        <span className="w700">Top 5</span> Người giới thiệu
                        xuất sắc nhất trong tuần: Mỗi giải{" "}
                        <span className="w700">500k</span>
                      </div>
                      <div className="body24 textBlack">
                        <span className="w700">Top 10</span> Người giới thiệu
                        xuất sắc nhất trong tháng:
                      </div>
                      <div className="body24 textBlack">
                        <span className="w700">1 giải đặc biệt:</span> 10 triệu
                      </div>
                      <div className="body24 textBlack">
                        <span className="w700">1 giải nhất:</span> 5 triệu
                      </div>
                      <div className="body24 textBlack">
                        <span className="w700">2 giải 2:</span> 5 triệu{" "}
                      </div>
                      <div className="body24 textBlack">
                        <span className="w700">3 giải 3:</span> 2,5 triệu
                      </div>

                      <div className="body24 textBlack">
                        <span className="w700">4 giải tư:</span> mỗi giải 1,50k
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const _renderRewardRule = () => {
    return (
      <Container fluid>
        <Row className="justify-content-center">
          <Col md={12} lg={8} xl={6} sm={12} className="gx-0">
            <div className={styles.promotionRewardContainer}>
              <div className="mb32 appH2">{t("reward-procedure")}</div>
              <div className="rowAllStart mb24">
                <img
                  src={checkCircleImg}
                  className={styles.checkImg}
                  alt="Check"
                />
                <div className="body20 textBlack flex1">
                  Kết quả cuối cùng sẽ được công bố trong vòng 05 ngày làm việc
                  kể từ ngày kết thúc tính thưởng. Giải thưởng sẽ được trao
                  trong vòng 10 ngày làm việc kể từ ngày công bố.
                </div>
              </div>
              <div className="rowAllStart mb24">
                <img
                  src={checkCircleImg}
                  className={styles.checkImg}
                  alt="Check"
                />
                <div className="body20 textBlack flex1">
                  Trong thời gian thi đua, MB Ageas Life hoàn toàn có quyền xem
                  xét sửa đổi hoặc thay đổi hoặc mở rộng điều kiện và thời gian
                  của chương trình thi đua theo chính sách của MB Ageas Life bất
                  cứ lúc nào.
                </div>
              </div>
              <div className="rowAllStart">
                <img
                  src={checkCircleImg}
                  className={styles.checkImg}
                  alt="Check"
                />
                <div className="body20 textBlack flex1">
                  Giải thưởng đã bao gồm tất cả các loại thuế theo quy định của
                  Pháp luật hiện hành (nếu có áp dụng). Riêng đối với thuế thu
                  nhập cá nhân, giải thưởng sẽ chịu thuế theo quy định (nếu có).
                  MB Ageas Life sẽ thực hiện khấu trừ tiền thuế này trước khi
                  thực hiện chi trả cho các cá nhân nhận giải.
                </div>
              </div>
            </div>
          </Col>

          <Col md={12} lg={4} xl={6} sm={12} className="gx-0">
            <img
              src={promotionImg}
              alt="Promotion reward"
              className={styles.promotionImg}
            />
          </Col>
        </Row>
      </Container>
    );
  };
  return (
    <div>
      <img
        src={promotionBannerImg}
        alt={"Promotion banner"}
        className={styles.banner}
      />
      {_renderName()}
      {_renderRule()}
      {isUnderMd && <div className={styles.whiteSpacer40} />}
      {_renderProgramDetail()}
      {_renderRewardRule()}
    </div>
  );
};

export default Promotion;
