import { useCallback } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./RadioItemSelector.module.scss";
import radioCheckedImg from "src/assets/images/radio-checked.svg";
import radioDefaultImg from "src/assets/images/radio-default.svg";

export interface RadioItem {
  name: string;
  value: string;
}

interface RadioItemSelectorProps {
  data: RadioItem[];
  value?: string | null;
  onChange: (value: string) => void;
}
const RadioItemSelector = ({
  data,
  value,
  onChange,
}: RadioItemSelectorProps) => {
  const _handleChooseItem = useCallback(
    (item: RadioItem) => {
      onChange(item.value);
    },
    [onChange]
  );

  const _renderItem = useCallback(
    (item: RadioItem, index: number) => {
      const isSelected = value === item.value;
      return (
        <Col key={item.value} sm className="gx-2">
          <div
            className={isSelected ? styles.itemActive : styles.itemInactive}
            onClick={() => _handleChooseItem(item)}
          >
            <img
              src={isSelected ? radioCheckedImg : radioDefaultImg}
              alt={"Radio "}
              className={styles.radioImg}
            />
            <div>
              <div
                className={`body16 w600 mb-1 ${
                  isSelected ? "textPrimary" : "textBlack"
                }`}
              >
                {item.name}
              </div>
            </div>
          </div>
        </Col>
      );
    },
    [value, _handleChooseItem]
  );

  return <Row>{data.map(_renderItem)}</Row>;
};

export default RadioItemSelector;
