import CancerFormInfo from "./CancerFormInfo";

class CancerFormService {
  private static cancerFormInfo?: CancerFormInfo;
  static frontIdCard?: File;
  static backIdCard?: File;
  static signature?: File;

  static saveCancerFormInfo(data: any) {
    this.cancerFormInfo = {
      ...this.cancerFormInfo,
      ...data,
    };
    sessionStorage.setItem(
      "cancer_form_info",
      JSON.stringify(this.cancerFormInfo)
    );
  }
  static getCancerFormInfo = (): CancerFormInfo | null => {
    if (this.cancerFormInfo) return this.cancerFormInfo;
    const cancerFormInfoStr = sessionStorage.getItem("cancer_form_info");
    if (!cancerFormInfoStr) return null;
    try {
      return JSON.parse(cancerFormInfoStr) as CancerFormInfo;
    } catch (err) {
      console.log("Parse form info err", err);
      return null;
    }
  };
  static reset = () => {
    sessionStorage.removeItem("cancer_form_info");
    this.cancerFormInfo = undefined;
    this.frontIdCard = undefined;
    this.backIdCard = undefined;
    this.signature = undefined;
  };
}

export default CancerFormService;
