import { useState, useEffect, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "src/styles/BuyInsurance.module.scss";
import { useTranslation } from "react-i18next";
import InsuranceItem from "src/components/InsuranceItem";
import {
  BUY_INSURANCE_STEP_DATA,
  CARD_TYPE_VALUES,
  CardType,
  GenderDisplayMap,
  MBAGEAS_LIFE_URL,
  CardTypeSortDisplayMap,
} from "src/Constants";
import HorizontalStepper from "src/components/HorizontalStepper";
import Accordion from "react-bootstrap/Accordion";
import Input from "src/components/Input";
import Checkbox from "src/components/Checkbox";
import UiService from "src/services/UiService";
import { useHistory, useLocation } from "react-router-dom";
import mailImg from "src/assets/images/mail.svg";
import phoneImg from "src/assets/images/phone.svg";
import locationImg from "src/assets/images/location.svg";
import Utils from "src/utils/Utils";
import McreditFormService from "../McreditFormService";
import Item from "src/models/Item";
import { useMediaQuery } from "react-responsive";
import ImageInput from "src/components/ImageInput";
import infoIconGray from "src/assets/images/information-circle-gray.svg";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import HighlightText from "src/components/HighlightText";
import congratulationImg from "src/assets/images/congratulation.svg";
import qs from "qs";
dayjs.extend(customParseFormat);

interface PaymentSuccessState {
  phone: string;
  errPhone: string;
  email: string;
  errEmail: string;
  address: string;
  errAddress: string;
  agreeJoin: boolean;
  agreeToc: boolean;
  requestId?: string | null;
}

const PaymentSuccess = () => {
  const location = useLocation();
  const isLargeDevice = useMediaQuery({
    query: "(min-width: 992px)",
  });
  const history = useHistory();
  const { t } = useTranslation();
  const [values, setValues] = useState<PaymentSuccessState>({
    phone: "",
    errPhone: "",
    email: "",
    errEmail: "",
    address: "",
    errAddress: "",
    agreeJoin: false,
    agreeToc: false,
    requestId: null,
  });
  const [commitments, setCommitments] = useState<Item[]>();
  const [frontIdCard, setFrontIdCard] = useState<File>();
  const [errFrontIdCard, setErrFrontIdCard] = useState<string>();
  const [backIdCard, setBackIdCard] = useState<File>();
  const [errBackIdCard, setErrBackIdCard] = useState<string>();
  const [signature, setSignature] = useState<File>();
  const [errSignature, setErrSignature] = useState<string>();

  const _loadCommitments = () => {
    const commitmentsStr = localStorage.getItem("cancer_commitments");
    if (!commitmentsStr) return;
    try {
      setCommitments(JSON.parse(commitmentsStr));
    } catch (err) {
      console.log("Parse cancer commitments err", err);
    }
  };

  const _fillData = () => {
    if (McreditFormService.getMcreditFormInfo()?.email) {
      setValues({
        ...values,
        email: McreditFormService.getMcreditFormInfo()?.email || "",
        phone: McreditFormService.getMcreditFormInfo()?.phone || "",
        address: McreditFormService.getMcreditFormInfo()?.address || "",
      });
    }
    if (McreditFormService.frontIdCard) {
      setFrontIdCard(McreditFormService.frontIdCard);
    }
    if (McreditFormService.backIdCard) {
      setBackIdCard(McreditFormService.backIdCard);
    }
    if (McreditFormService.signature) {
      setSignature(McreditFormService.signature);
    }
  };

  const _checkPaymentInfo = () => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    console.log("Query params", queryParams);
    UiService.openPopup({
      onlyCancelButton: true,
      title: t("congratulation"),
      image: (
        <div className={styles.congratulationImage}>
          <img
            src={congratulationImg}
            width={180}
            height={148}
            alt="congratulation"
          />
        </div>
      ),
      titleClassName: "popupTitle textPrimary text-center mb-4",
      onCancel: () => {
        UiService.closePopup();
        McreditFormService.reset();
        window.location.replace(MBAGEAS_LIFE_URL);
      },
      cancelTitle: t("back-to-home"),
      children: (
        <div className={styles.congratulationContent}>
          <HighlightText
            text={t("congratulation-message")}
            replaceText={[queryParams?.applNo || ""]}
            className={"body20"}
            highlightClassName={"body20 w600"}
          />
        </div>
      ),
    });
  };

  useEffect(() => {
    _loadCommitments();
    _fillData();
    _checkPaymentInfo();
  }, []);

  const _handleContinue = () => {};

  const _handleGoBack = () => {
    history.goBack();
  };

  const _getDisplayCardType = (cardType?: CardType): string => {
    if (!cardType) return "";
    return t(
      CARD_TYPE_VALUES.find((item) => item.value === cardType)?.name || ""
    );
  };

  const _renderCustomerInfo = () => {
    return (
      <Accordion defaultActiveKey="0" className="mb-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="appH4 textBlack">
              {t("fill-personal-info")}
              <span className="required">*</span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="mt-3 mb-3 body16 w600 textBlack">
              {t("seller-info")}
            </div>
            <div className="line mb-3" />
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("sale-channel")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {McreditFormService.getMcreditFormInfo()?.saleChannel}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("cross-selling-code")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {McreditFormService.getMcreditFormInfo()?.crossSellingCode ||
                    "-"}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("referral-code")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {McreditFormService.getMcreditFormInfo()?.referralCode}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("employee-name")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {McreditFormService.getMcreditFormInfo()?.employeeName}
                </div>
              </Col>
            </Row>

            <div className="mt-3 mb-3 body16 w600 textBlack">
              {t("customer-info")}
            </div>
            <div className="line mb-3" />
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("identity-card-type")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {_getDisplayCardType(
                    McreditFormService.getMcreditFormInfo()?.idCardType
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("issue-date")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {McreditFormService.getMcreditFormInfo()?.issuedDate || ""}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("issue-place")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {McreditFormService.getMcreditFormInfo()?.issuePlace || ""}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("identity-card-no")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {McreditFormService.getMcreditFormInfo()?.idCardNo}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("fullname")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {McreditFormService.getMcreditFormInfo()?.fullname}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("gender")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {t(
                    GenderDisplayMap[
                      McreditFormService.getMcreditFormInfo()?.gender || ""
                    ]
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("dob")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {McreditFormService.getMcreditFormInfo()?.dob || ""}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="textGray body14">{t("nationality")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">Việt Nam</div>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const _handleChangeEmail = (e) => {
    setValues({
      ...values,
      email: e.target.value,
      errEmail: "",
    });
  };

  const _handleChangePhone = (e) => {
    setValues({
      ...values,
      phone: e.target.value,
      errPhone: "",
    });
  };

  const _handleChangeAddress = (e) => {
    setValues({
      ...values,
      address: e.target.value,
      errAddress: "",
    });
  };

  const _renderContactInfoForm = () => {
    return (
      <Accordion defaultActiveKey="1" className="mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <div className="appH4 textBlack">
              {t("contact-info")}
              <span className="required">*</span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <Input
              label={t("email")}
              placeholder={t("email-placeholder")}
              required={true}
              leftIcon={mailImg}
              value={values.email}
              errorText={values.errEmail}
              onChange={_handleChangeEmail}
              maxLength={100}
            />
            <Input
              label={t("phone")}
              placeholder={t("phone-placeholder")}
              required={true}
              leftIcon={phoneImg}
              value={values.phone}
              errorText={values.errPhone}
              onChange={_handleChangePhone}
              maxLength={20}
            />
            <Input
              label={t("address")}
              placeholder={t("address-placeholder")}
              required={true}
              leftIcon={locationImg}
              value={values.address}
              errorText={values.errAddress}
              onChange={_handleChangeAddress}
              maxLength={100}
            />
            <div className="mb-3 body16 textBlack italic">
              <span className="w700">{t("note")} </span>
              {t("contact-info-note")}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const _handleChangeFrontIdCard = useCallback(
    (file: File) => {
      // console.log("File Size", e.target.files[0]);
      setFrontIdCard(file);
      if (!Utils.isValidImageFileType(file)) {
        setErrFrontIdCard(t("invalid-image-type"));
      } else if (!Utils.isValidUploadImageSize(file)) {
        setErrFrontIdCard(t("invalid-image-size"));
      } else {
        setErrFrontIdCard("");
        McreditFormService.frontIdCard = file;
      }
    },
    [setFrontIdCard, setErrFrontIdCard, t]
  );

  const _handleChangeBackIdCard = useCallback(
    (file: File) => {
      setBackIdCard(file);
      if (!Utils.isValidImageFileType(file)) {
        setErrBackIdCard(t("invalid-image-type"));
      } else if (!Utils.isValidUploadImageSize(file)) {
        setErrBackIdCard(t("invalid-image-size"));
      } else {
        setErrBackIdCard("");
        McreditFormService.backIdCard = file;
      }
    },
    [setBackIdCard, setErrBackIdCard, t]
  );

  const _handleChangeSignature = useCallback(
    (file: File) => {
      setSignature(file);
      if (!Utils.isValidImageFileType(file)) {
        setErrSignature(t("invalid-image-type"));
      } else if (!Utils.isValidUploadImageSize(file)) {
        setErrSignature(t("invalid-image-size"));
      } else {
        setErrSignature("");
        McreditFormService.signature = file;
      }
    },
    [setSignature, setErrSignature, t]
  );
  const _getCardTypeSortDisplay = (cardType?: CardType | null) => {
    if (!cardType) return "";
    return t(CardTypeSortDisplayMap[cardType]);
  };

  const _renderIdentityCardForm = () => {
    if (McreditFormService.getMcreditFormInfo()?.hasCustomerInfo)
      return <div />;
    return (
      <Accordion defaultActiveKey="1" className="mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <div className="appH4 textBlack">
              {t("identity-card")}
              <span className="required">*</span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            {McreditFormService.getMcreditFormInfo()?.idCardType !==
            CardType.PASSPORT ? (
              <>
                <Row>
                  <Col sm className="mb32">
                    <ImageInput
                      file={frontIdCard}
                      label={Utils.getReplaceText(
                        t("front-id-card-img"),
                        _getCardTypeSortDisplay(
                          McreditFormService.getMcreditFormInfo()?.idCardType
                        )
                      )}
                      onChange={_handleChangeFrontIdCard}
                      errText={errFrontIdCard}
                    />
                  </Col>
                  <Col sm className="mb32">
                    <ImageInput
                      label={Utils.getReplaceText(
                        t("back-id-card-img"),
                        _getCardTypeSortDisplay(
                          McreditFormService.getMcreditFormInfo()?.idCardType
                        )
                      )}
                      file={backIdCard}
                      errText={errBackIdCard}
                      onChange={_handleChangeBackIdCard}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ImageInput
                      label={t("signature-img")}
                      file={signature}
                      onChange={_handleChangeSignature}
                      imageClassName={styles.signatureImage}
                      imageContainerClassName={styles.signatureImageContainer}
                      errText={errSignature}
                      helperText={t("please-sign-to-paper")}
                      helperIcon={infoIconGray}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col sm className="mb32">
                    <ImageInput
                      file={frontIdCard}
                      label={Utils.getReplaceText(
                        t("front-id-card-img"),
                        _getCardTypeSortDisplay(
                          McreditFormService.getMcreditFormInfo()?.idCardType
                        )
                      )}
                      onChange={_handleChangeFrontIdCard}
                      errText={errFrontIdCard}
                    />
                  </Col>
                  <Col sm className="mb32">
                    <ImageInput
                      label={t("signature-img")}
                      file={signature}
                      onChange={_handleChangeSignature}
                      errText={errSignature}
                      helperText={t("please-sign-to-paper")}
                      helperIcon={infoIconGray}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const _handleChangeAgreeJoin = (value: boolean) => {
    console.log("_handleChangeAgreeJoin", value);
    // setAgreeJoin(value);
    setValues({
      ...values,
      agreeJoin: value,
    });
  };

  const _handleChangeAgreeToc = (value: boolean) => {
    // setAgreeToc(value);
    setValues({
      ...values,
      agreeToc: value,
    });
  };

  const _renderTocCheckboxContent = () => {
    return (
      <span
        className={`ms-2 body16 w600 ${
          values.agreeToc ? "textBlack" : "textGray"
        }`}
      >
        {t("i-read-agree-with")}&nbsp;
        <a
          href="/cancer/term_and_condition"
          target="_blank"
          className={values.agreeToc ? styles.linkActive : styles.linkInactive}
        >
          {t("toc")}
        </a>
      </span>
    );
  };

  const _renderCommitment = () => {
    return (
      <Accordion defaultActiveKey="2" className="mb-3">
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <div className="appH4 textBlack">
              {t("commitment")}
              <span className="required">*</span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className={`${styles.commitmentContent} body14 mb-3`}>
              <div className="smallTitle16 mb-4">{t("commitment-title")}</div>
              {commitments &&
                commitments.map((item) => (
                  <div className="mb-4" key={item.id}>
                    {item.id}. {item.name}
                  </div>
                ))}
            </div>

            <div className="mb-3">
              <div className="mb-3 body16 textBlack italic">
                <span className="w700">{t("note")} </span>
                {t("commitment-note")}
              </div>
              <Checkbox
                text={t("i-agree-join-insurance")}
                checked={values.agreeJoin}
                onChange={_handleChangeAgreeJoin}
              />
            </div>

            <Checkbox
              // text={t("i-agree-toc")}
              textComponent={_renderTocCheckboxContent()}
              checked={values.agreeToc}
              onChange={_handleChangeAgreeToc}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const _renderButton = () => {
    return (
      <div className="rowStart">
        <Button variant="light" onClick={_handleGoBack} className="flex1 me-3">
          {t("go-back")}
        </Button>
        <Button variant="primary" onClick={_handleContinue} className="flex1">
          {t("confirm")}
        </Button>
      </div>
    );
  };

  const _renderInfo = () => {
    return (
      <div className="mb-lg-0 mb-4">
        <Row className="mb-4">
          <Col sm md={8} className="d-flex flex-column justify-content-center">
            <div className="appH3 textBlack">{t("confirm-info")}</div>
          </Col>
          <Col sm md={4} className="d-flex flex-column justify-content-center">
            <div className="body16 textSecondary text-left text-sm-end">
              {t("required-info")}
            </div>
          </Col>
        </Row>

        {_renderCustomerInfo()}
        {_renderContactInfoForm()}
        {_renderIdentityCardForm()}
        {_renderCommitment()}

        {/* <div className={styles.infoForm}></div> */}
        {isLargeDevice && _renderButton()}
      </div>
    );
  };

  const _renderInsuranceItem = () => {
    return (
      <div className="mb-3">
        <Row className="mb-4">
          <Col sm className="d-flex flex-column justify-content-center">
            <div className="appH3 textBlack">{t("choosed-product")}</div>
          </Col>
        </Row>
        <InsuranceItem
          data={McreditFormService.getInsurancePackages()?.find(
            (item) =>
              item.id ===
              McreditFormService.getMcreditFormInfo()?.selectedPackage
          )}
          discount={McreditFormService.getMcreditFormInfo()?.applyingDiscount}
          beforeDiscount={
            McreditFormService.getMcreditFormInfo()?.beforeDiscount
          }
          discountAmt={McreditFormService.getMcreditFormInfo()?.discountAmt}
          afterDiscount={McreditFormService.getMcreditFormInfo()?.afterDiscount}
        />
      </div>
    );
  };

  return (
    <div className={styles.pageContainer}>
      <Row className={`justify-content-center ${styles.stepper}`}>
        <Col lg={8}>
          <div className="rowCenter">
            <HorizontalStepper data={BUY_INSURANCE_STEP_DATA} step={2} />
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={5}>{_renderInfo()}</Col>
        <Col lg={3}>{_renderInsuranceItem()}</Col>
        {!isLargeDevice && <Col>{_renderButton()}</Col>}
      </Row>
    </div>
  );
};

export default PaymentSuccess;
