import { useState, useEffect, useCallback, useMemo } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GenderSelector from "src/components/GenderSelector";
import styles from "src/styles/BuyInsurance.module.scss";
import DropdownInput, { DropdownItem } from "src/components/DropdownInput";
import { useTranslation } from "react-i18next";
import Input from "src/components/Input";
import DateInput from "src/components/DateInput";
import InsuranceItem from "src/components/InsuranceItem";
import HorizontalStepper from "src/components/HorizontalStepper";
import { useHistory, useLocation } from "react-router-dom";
import UiService from "src/services/UiService";
import {
  CARD_TYPE_VALUES,
  CardType,
  Gender,
  VN_COUNTRY_CODE,
  ProductCode,
  BUY_INSURANCE_STEP_DATA,
  GenderDisplayMap,
} from "src/Constants";
import {
  getCategory,
  getInsurancePackage,
  checkBpTsa,
  getDiscount,
  checkDiscount,
} from "src/api";
import Utils from "src/utils/Utils";
import FullCancerFormInfo from "../FullCancerFormInfo";
import FullCancerFormService from "../FullCancerFormService";
import { useMediaQuery } from "react-responsive";
import Accordion from "react-bootstrap/Accordion";
import InsurancePackage from "src/models/InsurancePackage";
import InsurancePackageChooser from "src/components/InsurancePackageChooser";
import VoucherInput from "src/components/VoucherInput";
import DiscountPopup from "src/components/DiscountPopup";
import Discount from "src/models/Discount";
import qs from "qs";
import AuthInfoService from "src/services/AuthInfoService";

const CustomerInfo = () => {
  const isLargeDevice = useMediaQuery({
    query: "(min-width: 992px)",
  });

  const [dob, setDob] = useState<Date>();
  const [errDob, setErrDob] = useState<string>();
  const [gender, setGender] = useState<Gender | null>(
    FullCancerFormService.getFullCancerFormInfo()?.gender || null
  );
  const [errGender, setErrGender] = useState<string>("");
  const [idCardType, setIdCardType] = useState<CardType>();
  const [issueDate, setIssueDate] = useState<Date>();
  const [errIssueDate, setErrIssueDate] = useState<string>();
  const [issuePlace, setIssuePlace] = useState<string>("");
  const [errIssuePlace, setErrIssuePlace] = useState<string>("");
  const [errIdCardType, setErrIdCardType] = useState<string>("");
  const [idCardNo, setIdCardNo] = useState<string>("");
  const [errIdCardNo, setErrIdCardNo] = useState<string>("");
  const [fullname, setFullname] = useState<string>("");
  const [canEditFullname, setCanEditFullname] = useState<boolean>(true);
  const [errFullname, setErrFullname] = useState<string>("");
  const [nationality, setNationality] = useState<string>(VN_COUNTRY_CODE);
  const [errNationality, setErrNationality] = useState<string>("");
  const [nationList, setNationList] = useState<DropdownItem[]>();
  const [selectedPackage, setSelectedPackage] = useState<string | null>();
  const [insurancePackages, setInsurancePackages] = useState<
    InsurancePackage[] | null
  >();
  const [editingInfo, setEditingInfo] = useState<boolean>(true);
  const [discount, setDiscount] = useState<string>();
  const [isValidDiscount, setIsValidDiscount] = useState<boolean | null>(null);
  const [errDiscount, setErrDiscount] = useState<string>("");
  const [showDiscountPopup, setShowDiscountPopup] = useState<boolean>(false);
  const [discountData, setDiscountData] = useState<Discount[] | null>();
  const [discountAmt, setDiscountAmt] = useState<number | null>();
  const [beforeDiscount, setBeforeDiscount] = useState<number | null>();
  const [afterDiscount, setAfterDiscount] = useState<number | null>();
  const [applyingDiscount, setApplyingDiscount] = useState<Discount | null>();

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const _loadCategory = async () => {
    try {
      const categoryRes = await getCategory(ProductCode.CANCER);
      console.log("categoryRes", categoryRes);
      localStorage.setItem(
        "cancer_commitments",
        JSON.stringify(categoryRes?.data?.commitments)
      );
      localStorage.setItem(
        "nationList",
        JSON.stringify(categoryRes?.data?.nationalitys)
      );
      localStorage.setItem(
        "cancer_question",
        JSON.stringify(categoryRes?.data?.questions)
      );
      setNationList(
        categoryRes?.data?.nationalitys.map((item) => ({
          name: item.name,
          value: item.id,
        }))
      );
    } catch (err) {
      console.log("_loadCategory err", err);
    }
  };

  const _fillData = () => {
    const savedInsuraceaPackages = FullCancerFormService.getInsurancePackages();
    if (savedInsuraceaPackages) {
      setEditingInfo(false);
      setInsurancePackages(savedInsuraceaPackages);
      if (FullCancerFormService.getFullCancerFormInfo()?.selectedPackage) {
        _selectPackage(
          FullCancerFormService.getFullCancerFormInfo()?.selectedPackage,
          FullCancerFormService.getFullCancerFormInfo()?.idCardNo
        );
      } else {
        _selectPackage(
          savedInsuraceaPackages.find((item) => item.defaultSelect === true)
            ?.id || "1",
          FullCancerFormService.getFullCancerFormInfo()?.idCardNo
        );
      }
      if (FullCancerFormService.getFullCancerFormInfo()?.discount) {
        setDiscount(FullCancerFormService.getFullCancerFormInfo()?.discount);
        setIsValidDiscount(true);
      }
      if (FullCancerFormService.getFullCancerFormInfo()?.applyingDiscount) {
        setApplyingDiscount(
          FullCancerFormService.getFullCancerFormInfo()?.applyingDiscount
        );
      }
    }
    if (FullCancerFormService.getFullCancerFormInfo()?.fullname) {
      setFullname(FullCancerFormService.getFullCancerFormInfo()?.fullname!);
    }
    if (FullCancerFormService.getFullCancerFormInfo()?.idCardNo) {
      setIdCardNo(FullCancerFormService.getFullCancerFormInfo()?.idCardNo!);
    }
    if (FullCancerFormService.getFullCancerFormInfo()?.idCardType) {
      setIdCardType(FullCancerFormService.getFullCancerFormInfo()?.idCardType);
    }
    if (FullCancerFormService.getFullCancerFormInfo()?.issuedDate) {
      setIssueDate(
        Utils.covertToDate(
          FullCancerFormService.getFullCancerFormInfo()!.issuedDate!
        )
      );
    }
    if (FullCancerFormService.getFullCancerFormInfo()?.dob) {
      setDob(
        Utils.covertToDate(FullCancerFormService.getFullCancerFormInfo()!.dob!)
      );
    }
    if (FullCancerFormService.getFullCancerFormInfo()?.issuePlace) {
      setIssuePlace(FullCancerFormService.getFullCancerFormInfo()?.issuePlace!);
    }
    if (FullCancerFormService.getFullCancerFormInfo()?.gender) {
      setGender(FullCancerFormService.getFullCancerFormInfo()!.gender!);
    }

    if (
      typeof FullCancerFormService.getFullCancerFormInfo()?.beforeDiscount !=
      "undefined"
    ) {
      setBeforeDiscount(
        FullCancerFormService.getFullCancerFormInfo()?.beforeDiscount
      );
    }
    if (
      typeof FullCancerFormService.getFullCancerFormInfo()?.afterDiscount !=
      "undefined"
    ) {
      setAfterDiscount(
        FullCancerFormService.getFullCancerFormInfo()?.afterDiscount
      );
    }
    if (
      typeof FullCancerFormService.getFullCancerFormInfo()?.discountAmt !=
      "undefined"
    ) {
      setDiscountAmt(
        FullCancerFormService.getFullCancerFormInfo()?.discountAmt
      );
    }
  };

  useEffect(() => {
    _loadCategory();
    _fillData();
  }, []);

  const _validate = (): boolean => {
    let isValid: boolean = true;
    if (!idCardType) {
      setErrIdCardType(t("id-card-type-required"));
      isValid = false;
    }
    if (!idCardNo || idCardNo.trim() === "") {
      setErrIdCardNo(t("id-card-no-required"));
      isValid = false;
    } else if (idCardType === CardType.CMND && !Utils.isValidCMND(idCardNo)) {
      setErrIdCardNo(t("invalid-cmnd"));
      isValid = false;
    } else if (idCardType === CardType.CCCD && !Utils.isValidCCCD(idCardNo)) {
      setErrIdCardNo(t("invalid-cccd"));
      isValid = false;
    } else if (
      idCardType === CardType.PASSPORT &&
      !Utils.isValidPassport(idCardNo)
    ) {
      setErrIdCardNo(t("invalid-passport"));
      isValid = false;
    } else if (idCardType === CardType.CMTQD && !Utils.isValidCMTQD(idCardNo)) {
      setErrIdCardNo(t("invalid-cmntqd"));
      isValid = false;
    }
    if (!issueDate) {
      setErrIssueDate(t("issue-date-required"));
      isValid = false;
    }
    if (!issuePlace || !issuePlace.trim()) {
      setErrIssuePlace(t("issue-place-required"));
      isValid = false;
    }

    if (!fullname || fullname.trim() === "") {
      setErrFullname(t("name-required"));
      isValid = false;
    }
    if (!gender) {
      setErrGender(t("gender-required"));
      isValid = false;
    }
    if (!dob) {
      setErrDob(t("dob-required"));
      isValid = false;
    }

    if (!nationality) {
      setErrNationality(t("nationality-required"));
      isValid = false;
    }

    if (!isValid) return false;
    if (nationality !== VN_COUNTRY_CODE) {
      UiService.openPopup({
        showContactNow: true,
        content: t("product-not-support-foreign-people"),
        onOk: () => {
          UiService.closePopup();
        },
        onCancel: () => {
          UiService.closePopup();
        },
      });
      isValid = false;
    }
    return isValid;
  };

  const _handleContinue = async () => {
    if (discount && discount.trim()) {
      const isValidDiscount = await _checkDiscount();
      if (!isValidDiscount) return;
    }
    try {
      UiService.showLoading();
      const checkBpTsaRes = await checkBpTsa({
        dob: Utils.formatDate(dob!),
        gender,
        fullname,
        sumAssured: insurancePackages?.find(
          (item) => item.id === selectedPackage
        )?.insamountAm,
        idCardNo: idCardNo,
        channel: AuthInfoService.getChannel(),
      });
      FullCancerFormService.saveFullCanncerFormInfo({
        selectedPackage,
        discount,
        discountData,
        discountAmt,
        beforeDiscount,
        afterDiscount,
        applyingDiscount,
      });
      UiService.hideLoading();
      console.log("checkBpTsaRes", checkBpTsaRes);
      history.push("/individual/buy-insurance/cancer/health-question");
    } catch (err: any) {
      console.log("calculatePremiumFreeCancer err", err);
      UiService.hideLoading();
      if (err?.response?.data?.code === 400) {
        UiService.openPopup({
          content: err?.response?.data?.error_message,
          showContactNow: true,
          onOk: () => {
            UiService.closePopup();
          },
          onCancel: () => {
            UiService.closePopup();
          },
        });
      }
    }
  };

  const _handleChangeIdCardNo = (e) => {
    setIdCardNo(e.target.value.replace(/[^0-9a-zA-Z]/gi, ""));
    setErrIdCardNo("");
  };

  const _handleChangeIssueDate = (value) => {
    setIssueDate(value);
    setErrIssueDate("");
  };

  const _handleChangeIssuePlace = (e) => {
    setIssuePlace(e.target.value);
    setErrIssuePlace("");
  };

  const _handleChangeFullname = (e) => {
    setFullname(e.target.value);
    setErrFullname("");
  };

  const _handleChangeDob = (value) => {
    console.log("On Change Dob", value);
    setDob(value);
    setErrDob("");
  };

  const _handleChangeIdCardType = (value) => {
    setIdCardType(value);
    setErrIdCardType("");
  };

  const _handleChangeNationality = (value) => {
    setNationality(value);
    setErrNationality("");
  };

  const _handleChangeGender = (gender) => {
    setGender(gender);
    setErrGender("");
  };

  const _getTodayDay = () => {
    const todayDate = new Date();
    return {
      day: todayDate.getDate(),
      month: todayDate.getMonth() + 1,
      year: todayDate.getFullYear(),
    };
  };

  const _selectPackage = async (
    packageId?: string | null,
    idCardNo?: string
  ) => {
    if (!packageId || !idCardNo) return;
    setSelectedPackage(packageId);
  };

  const _handleConfirmInfo = async () => {
    if (!editingInfo) {
      setEditingInfo(true);
      setInsurancePackages(null);
      setSelectedPackage(null);
      setDiscount("");
      setIsValidDiscount(null);
      setDiscountData(null);
      setErrDiscount("");
      setApplyingDiscount(undefined);
      setBeforeDiscount(undefined);
      setAfterDiscount(undefined);
      setDiscountAmt(undefined);
      return;
    }
    const isValid = _validate();
    if (!isValid) return;

    const fullCanderFormInfo: FullCancerFormInfo = {
      dob: Utils.formatDate(dob!),
      gender: gender!,
      idCardNo: idCardNo!,
      idCardType: idCardType!,
      fullname,
      issuePlace: issuePlace,
      issuedDate: Utils.formatDate(issueDate!),
    };
    FullCancerFormService.saveFullCanncerFormInfo(fullCanderFormInfo);
    try {
      UiService.showLoading();
      const insuracePackagesRes = await getInsurancePackage(
        Utils.formatDate(dob!),
        gender!,
        idCardNo,
        AuthInfoService.getChannel()
      );
      setEditingInfo(false);
      FullCancerFormService.saveInsuracePackages(
        insuracePackagesRes?.data?.list
      );
      setInsurancePackages(insuracePackagesRes?.data?.list);
      const selectedPackageId =
        insuracePackagesRes?.data?.list?.find(
          (item) => item.defaultSelected === true
        )?.id || "1";
      // setSelectedPackage(selectedPackageId);
      _selectPackage(selectedPackageId, idCardNo);
      console.log("insuracePackagesRes", insuracePackagesRes);
      UiService.hideLoading();
    } catch (err: any) {
      UiService.hideLoading();
      console.log("getInsurancePackage err", err);
      if (err?.response?.data?.code === 400) {
        UiService.openPopup({
          content: err?.response?.data?.error_message,
          showContactNow: true,
          onOk: () => {
            UiService.closePopup();
          },
          onCancel: () => {
            UiService.closePopup();
          },
        });
      }
    }
  };

  const _getDisplayCardType = (cardType?: CardType): string => {
    if (!cardType) return "";
    return t(
      CARD_TYPE_VALUES.find((item) => item.value === cardType)?.name || ""
    );
  };

  const _renderCustomerInfoForm = () => {
    return (
      <>
        <div className="body16 w600 textBlack">{t("customer-info")}</div>
        <div className="line mb-3 mt-3" />
        {editingInfo ? (
          <>
            <Row>
              <Col md>
                <DropdownInput
                  label={t("identity-card-type")}
                  placeholder={t("identity-card-type-placeholder")}
                  values={CARD_TYPE_VALUES.map((item) => ({
                    ...item,
                    name: t(item.name),
                  }))}
                  value={idCardType}
                  onChange={_handleChangeIdCardType}
                  required={true}
                  errorText={errIdCardType}
                />
              </Col>
              <Col md>
                <Input
                  label={t("identity-card-no")}
                  placeholder={t("identity-card-no-placeholder")}
                  required={true}
                  maxLength={20}
                  value={idCardNo}
                  onChange={_handleChangeIdCardNo}
                  errorText={errIdCardNo}
                />
              </Col>
            </Row>
            <Input
              label={t("fullname")}
              placeholder={t("fullname-placeholder")}
              required={true}
              maxLength={60}
              value={fullname}
              errorText={errFullname}
              onChange={_handleChangeFullname}
              disabled={!canEditFullname}
            />
            <Row>
              <Col md>
                <DateInput
                  placeholder={t("issue-date-placeholder")}
                  label={t("issue-date")}
                  required={true}
                  value={issueDate}
                  onChange={_handleChangeIssueDate}
                  errorText={errIssueDate}
                  maximumDate={_getTodayDay()}
                />
              </Col>
              <Col md>
                <Input
                  label={t("issue-place")}
                  placeholder={t("issue-place-placeholder")}
                  required={true}
                  maxLength={60}
                  value={issuePlace}
                  errorText={errIssuePlace}
                  onChange={_handleChangeIssuePlace}
                />
              </Col>
            </Row>

            <GenderSelector
              value={gender}
              onChange={_handleChangeGender}
              errorText={errGender}
            />

            <DateInput
              placeholder={t("dob-placeholder")}
              label={t("dob")}
              required={true}
              value={dob}
              onChange={_handleChangeDob}
              errorText={errDob}
              maximumDate={_getTodayDay()}
            />

            <DropdownInput
              label={t("nationality")}
              placeholder={t("nationality-placeholder")}
              values={nationList}
              value={nationality}
              onChange={_handleChangeNationality}
              required={true}
              errorText={errNationality}
            />
          </>
        ) : (
          <>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("identity-card-type")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {_getDisplayCardType(idCardType)}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("identity-card-no")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">{idCardNo}</div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("fullname")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">{fullname}</div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("issue-date")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {issueDate ? Utils.formatDate(issueDate) : ""}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("issue-place")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">{issuePlace || ""}</div>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("gender")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {t(GenderDisplayMap[gender || ""])}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("dob")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {dob ? Utils.formatDate(dob) : ""}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("nationality")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">{t("vn")}</div>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  };

  const _handleChangeDiscount = (e) => {
    setDiscount(e.target.value.toUpperCase());
    setIsValidDiscount(null);
    setErrDiscount("");
  };

  const _handleClearDiscount = () => {
    setDiscount("");
    setIsValidDiscount(null);
    setErrDiscount("");
    setAfterDiscount(undefined);
    setDiscountAmt(undefined);
    setBeforeDiscount(undefined);
  };

  const _checkDiscount = async (
    discountCode?: string | null
  ): Promise<boolean> => {
    console.log("_checkDiscount", discount);
    const discountCodeValue = discountCode || discount;
    if (!discountCodeValue || !discountCodeValue.trim()) {
      _handleClearDiscount();
      return false;
    }
    try {
      UiService.showLoading();
      const checkDiscountRes = await checkDiscount({
        channel: AuthInfoService.getChannel(),
        insurancePackageId: selectedPackage,
        discount: discountCodeValue,
        dob: Utils.formatDate(dob!),
        gender,
        insamountAm: insurancePackages?.find(
          (item) => item.id === selectedPackage
        )?.insamountAm,
        idCardNo: idCardNo,
      });
      setIsValidDiscount(true);
      setErrDiscount("");
      console.log("checkDiscountRes", checkDiscountRes);
      // setApplyingDiscount(
      //   discountData?.find((item) => item.discount === discountCodeValue)
      // );
      setApplyingDiscount(checkDiscountRes?.data);
      setBeforeDiscount(checkDiscountRes?.data?.beforeDiscount);
      setAfterDiscount(checkDiscountRes?.data?.afterDiscount);
      setDiscountAmt(checkDiscountRes?.data?.discountAmt);
      UiService.hideLoading();
      return true;
    } catch (err: any) {
      UiService.hideLoading();
      if (err?.response?.data?.code === 400) {
        setIsValidDiscount(false);
        setErrDiscount(
          err?.response?.data?.error_data?.messsage || t("invalid-discount")
        );
        setBeforeDiscount(undefined);
        setAfterDiscount(undefined);
        setDiscountAmt(undefined);
        setApplyingDiscount(undefined);
      }
      return false;
    }
  };

  const _handlePressChooseVoucher = async () => {
    console.log("_handlePressChooseVoucher");
    try {
      UiService.showLoading();
      const discountRes = await getDiscount(
        idCardNo,
        selectedPackage!,
        AuthInfoService.getChannel()
      );
      console.log("discountRes", discountRes);
      setDiscountData(discountRes?.data?.list);
      UiService.hideLoading();
      setShowDiscountPopup(true);
    } catch (err: any) {
      console.log("Load Discount err", err);
      UiService.hideLoading();
      UiService.openPopup({
        content: err?.response?.data?.error_message,
        showIcon: false,
        onlyOkButton: true,
        okTitle: t("close"),
        onOk: () => {
          UiService.closePopup();
        },
        onCancel: () => {
          UiService.closePopup();
        },
      });
    }
  };

  const _handleConfirmChooseVoucher = (discount?: string) => {
    console.log("_handleConfirmChooseVoucher", discount);
    setDiscount(discount);
    setIsValidDiscount(true);
    setErrDiscount("");
    setShowDiscountPopup(false);
    _checkDiscount(discount);
  };

  const _handleCancelChooseVoucher = () => {
    setShowDiscountPopup(false);
  };

  const _handleChangeSelectedPackage = useCallback(
    (value: string) => {
      setSelectedPackage(value);
      setDiscount("");
      setIsValidDiscount(null);
      setErrDiscount("");
      setApplyingDiscount(undefined);
      setBeforeDiscount(undefined);
      setAfterDiscount(undefined);
      setDiscountAmt(undefined);
      _selectPackage(value, idCardNo);
    },
    [idCardNo]
  );

  const _renderInsurancePackage = () => {
    if (!insurancePackages) return <div />;
    return (
      <Accordion defaultActiveKey="1" className="mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <div className="appH4 textBlack">
              {t("choose-insurance-package")}
              <span className="required">*</span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <InsurancePackageChooser
              selectedPackage={selectedPackage}
              onChangeSelectedPackage={_handleChangeSelectedPackage}
              data={insurancePackages}
            />
            <VoucherInput
              label={t("promotion-code")}
              placeholder={t("promotion-code-placeholder")}
              value={discount}
              onChange={_handleChangeDiscount}
              onCheckVoucherCode={_checkDiscount}
              errorText={errDiscount}
              isValidVouhcer={isValidDiscount}
              onClear={_handleClearDiscount}
              showChooseVoucher={true}
              onChooseVoucher={_handlePressChooseVoucher}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const _renderInfoForm = () => {
    return (
      <Accordion defaultActiveKey="1" className="mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <div className="appH4">
              {t("fill-info")} <span className="textSecondary">*</span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            {_renderCustomerInfoForm()}
            <Row>
              <Col>
                <Button
                  variant="primary"
                  onClick={_handleConfirmInfo}
                  className="fullWidth"
                >
                  {editingInfo ? t("confirm") : t("edit")}
                </Button>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const _renderForm = () => {
    return (
      <div className="mb-lg-0 mb-4">
        <Row className="mb-4">
          <Col sm md={8} className="d-flex flex-column justify-content-center">
            <div className="appH3 textBlack">{t("fill-personal-info")}</div>
          </Col>
          <Col sm md={4} className="d-flex flex-column justify-content-center">
            <div className="body16 textSecondary text-left text-sm-end">
              {t("required-info")}
            </div>
          </Col>
        </Row>

        {_renderInfoForm()}
        {_renderInsurancePackage()}
        {isLargeDevice && _renderButton()}
      </div>
    );
  };

  const _renderButton = () => {
    if (editingInfo) return <div />;
    return (
      <Row>
        <Col>
          <Button
            variant="primary"
            onClick={_handleContinue}
            className="fullWidth"
          >
            {t("continue")}
          </Button>
        </Col>
      </Row>
    );
  };

  const _renderInsuranceItem = () => {
    return (
      <div className="mb-3">
        <Row className="mb-4">
          <Col sm className="d-flex flex-column justify-content-center">
            <div className="appH3 textBlack">{t("choosed-product")}</div>
          </Col>
        </Row>
        <InsuranceItem
          data={insurancePackages?.find((item) => item.id === selectedPackage)}
          discount={applyingDiscount}
          beforeDiscount={beforeDiscount}
          discountAmt={discountAmt}
          afterDiscount={afterDiscount}
        />
      </div>
    );
  };
  return (
    <div className={styles.pageContainer}>
      <DiscountPopup
        visible={showDiscountPopup}
        onOk={_handleConfirmChooseVoucher}
        onCancel={_handleCancelChooseVoucher}
        data={discountData}
        selecetedDiscount={discount}
        onCancelDiscount={_handleClearDiscount}
      />
      <Row className={`justify-content-center ${styles.stepper}`}>
        <Col lg={8}>
          <div className="rowCenter">
            <HorizontalStepper data={BUY_INSURANCE_STEP_DATA} step={0} />
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={6}>{_renderForm()}</Col>
        <Col lg={3}>{_renderInsuranceItem()}</Col>
        {!isLargeDevice && <Col>{_renderButton()}</Col>}
      </Row>
    </div>
  );
};

export default CustomerInfo;
