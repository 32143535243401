import SellerInfo from "src/models/SellerInfo";

class SellerInfoService {
  static setInfo(
    accessToken: string,
    refreshToken: string,
    loginToken?: string,
    sellerInfo?: string
  ) {
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("refresh_token", refreshToken);
    if (loginToken) {
      localStorage.setItem("login_token", loginToken);
    }
    if (sellerInfo) {
      localStorage.setItem("seller_info", JSON.stringify(sellerInfo));
    }
  }

  static getAccessToken(): string | null {
    return localStorage.getItem("access_token");
  }

  static getRefreshToken(): string | null {
    return localStorage.getItem("refresh_token");
  }

  static setLoginToken(loginToken: string) {
    localStorage.setItem("login_token", loginToken);
  }

  static getLoginToken = (): string | null => {
    return localStorage.getItem("login_token");
  };

  static clearInfo() {
    localStorage.removeItem("seller_info");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  }

  static setSellerInfo(sellerInfo) {
    localStorage.setItem("seller_info", JSON.stringify(sellerInfo));
  }

  static getSellerInfo(): SellerInfo | null {
    const sellerInfoStr = localStorage.getItem("seller_info");
    if (!sellerInfoStr) return null;
    try {
      const sellerInfo: SellerInfo = JSON.parse(sellerInfoStr);
      return sellerInfo;
    } catch (err) {
      console.log("Parse UserInfo err", err);
      return null;
    }
  }
}

export default SellerInfoService;
