import { SupportItemData } from "src/models/SupportItemData";
import { SegmentControlItem } from "src/components/SegmentControl";
import documentProtectWhiteImg from "src/assets/images/document-protect-white.svg";
import documentProtectPrimaryImg from "src/assets/images/document-protect-primary.svg";
import documentSearchWhiteImg from "src/assets/images/document-search-white.svg";
import documentSearchPrimaryImg from "src/assets/images/document-search-primary.svg";
import documentTextWhiteImg from "src/assets/images/document-text-white.svg";
import documentTextPrimaryImg from "src/assets/images/document-text-primary.svg";

export const SUPPORT_CATEGORY_DATA: SegmentControlItem[] = [
  {
    id: "product",
    nameKey: "product",
    activeIcon: documentProtectWhiteImg,
    inactiveIcon: documentProtectPrimaryImg,
    link: "/support?tab=product",
  },
  {
    id: "benefit",
    nameKey: "about-benefit",
    activeIcon: documentSearchWhiteImg,
    inactiveIcon: documentSearchPrimaryImg,
    link: "/support?tab=benefit",
  },
  {
    id: "fee",
    nameKey: "about-fee",
    activeIcon: documentTextWhiteImg,
    inactiveIcon: documentTextPrimaryImg,
    link: "/support?tab=fee",
  },
];

export const SUPPORT_DATA: SupportItemData[] = [
  {
    id: "1",
    question:
      "Bên mua bảo hiểm (BMBH) của HĐ Sản phẩm bảo hiểm Bệnh ung thư cần thỏa mãn điều kiện gì?",
    answer:
      "Thưa anh/chị, BMBH của HĐ Sản phẩm bảo hiểm Bệnh ung thư cần thỏa mãn điều kiện là cá nhân từ đủ 18 tuổi trở lên đang cứ trú tại Việt Nam, có năng lực hành vi dân sự đầy đủ, hoặc tổ chức được thành lập và hoạt động hợp pháp tại Việt Nam; có Yêu cầu bảo hiểm theo Quy tắc, Điều khoản này và được MB Ageas Life chấp nhận. BMBH phải có quyền lợi có thể được bảo hiểm với Người được bảo hiểm.",
    category: "product",
  },
  {
    id: "2",
    question:
      "Người được bảo hiểm (NĐBH) của HĐ Sản phẩm bảo hiểm bệnh Ung thư cần thỏa mãn điều kiện gì?",
    answer: `Thưa anh/chị, NĐBH của HĐ sản phẩm bảo hiểm Bệnh ung thư cần thỏa mãn điều kiện là cá nhân từ đủ 30 ngày tuổi trở lên đến trước ngày sinh nhật thứ 66 vào Ngày hiệu lực Hợp đồng, có tính mạng và sức khỏe được bảo hiểm, hiện đang cư trú tại Việt Nam và được MB Ageas Life chấp nhận bảo hiểm theo Quy tắc, Điều khoản của sản phẩm bảo hiểm Bệnh ung thư ạ. Note: Hiện nay, với sản phẩm Ung dung sống khỏe MB Ageas Life chỉ áp dụng cho: NĐBH từ 18 đến 65 tuổi BMBH = NĐBH`,
    category: "product",
  },
  {
    id: "3",
    question: "Bệnh ung thư được hiểu là như thế nào vậy?",
    answer: `<p>Thưa anh/chị, theo Quy tắc, Điều khoản sản phẩm Bệnh ung thư bao gồm các trường hợp sau:<br/>(i)  <b>Ung thư</b> là khối u ác tính được chẩn đoán dựa trên bằng chứng mô học, đặc trưng bởi sự tăng trưởng không thể kiểm soát và lan rộng của các tế bào ác tính, xâm lấn và phá hủy mô lành. Ung thư phải được chẩn đoán mô học giai đoạn T1N0M0 hoặc nặng hơn theo phân loại TNM(*) hoặc theo phân loại quốc tế tương đương; hoặc<br/>(ii)  <b>Ung thư biểu mô tại chỗ</b> của một số vị trí cụ thể bao gồm: vú, tử cung, dương vật, bàng quang, vòm họng, đại trực tràng, và được chẩn đoán dựa trên bằng chứng mô học, là sự tăng trưởng không thể kiểm soát của các tế bào ung thư biểu mô ở tại chỗ. Việc chẩn đoán Ung thư hoặc chẩn đoán Ung thư biểu mô tại chỗ phải được Bác sỹ chuyên khoa ung bướu hoặc chuyên khoa giải phẫu bệnh học xác nhận dựa trên kết quả sinh thiết/giải phẫu bệnh được thực hiện qua quan sát vi thể mẫu mô đã được cố định, tại Bệnh viện/Cơ sở y tế đủ tiêu chuẩn. Quyền lợi bảo hiểm bệnh Ung thư sẽ không được chi trả cho một số trường hợp theo Quy tắc, Điều khoản SPBH Bệnh ung thư.<br/>(*) Phân loại TNM là hệ thống phân loại giai đoạn Ung thư của Tổ chức chống ung thư quốc tế (UICC).</p>`,
    category: "product",
  },
  {
    id: "4",
    question:
      "Khi tham gia HĐ Sản phẩm bảo hiểm bệnh Ung thư, KH được quyền lợi bảo hiểm như thế nào?",
    answer: `Thưa anh/chị, khi HĐBH đang có hiệu lực MB Ageas Life sẽ bảo vệ NĐBH các quyền lợi sau:<br/><b>Quyền lợi bảo hiệm Bệnh ung thư</b>: nếu NĐBH bị chẩn đoán mắc Bệnh ung thư và còn sống tại thời điểm chẩn đoán, MB Ageas Life sẽ chi trả 100% Số tiền bảo hiểm trừ đi Khoản nợ (nếu có). Quyền lợi này chỉ được trả 1 lần.<br/><b>QL bảo hiểm Sống khỏe</b>: nếu Quyền lợi bảo hiểm bệnh Ung thư được chấp nhận chi trả và NĐBH còn sống đến ngày liền sau 24 tháng kể từ Ngày chẩn đoán mắc Bệnh ung thư này thì MB Ageas Life sẽ chi trả thêm 25% Số tiền bảo hiểm trừ đi Khoản nợ (nếu có). Hợp đồng được duy trì miễn phí kể từ Ngày chẩn đoán mắc Bệnh ung thư này đến Ngày Hợp đồng chấm dứt hiệu lực.<br/><i>Quyền lợi này chỉ được trả 1 lần vào ngày liền sau 24 tháng kể từ Ngày chẩn đoán mặc Bệnh ung thư.<i>`,
    category: "product",
  },
  {
    id: "5",
    question: "HĐ có quyền lợi đáo hạn không?",
    answer: `Thưa anh/chị, sản phẩm bảo hiểm Bệnh ung thư là sản phẩm bảo hiểm thuần bảo vệ không tham gia chia lãi, không có giá trị hoàn lại và không có quyền lợi đáo hạn ạ.`,
    category: "product",
  },
  {
    id: "6",
    question: "Bệnh ung thư đặc biệt là như thế nào?",
    answer: `<p>Thưa anh/chị, Bệnh ung thư đặc biệt bao gồm một trong các tình trạng Bệnh ung thư sau được chẩn đoán mô học giai đoạn T1N1M0 hoặc T1N0M1 hoặc giai đoạn nặng hơn theo phân loại TNM(*) hoặc phân loại quốc tế tương đương:<br/>- Ung thư phổi nguyên phát; hoặc<br/>- Ung thư tụy nguyên phát; hoặc<br/>- Ung thư gan nguyên phát; hoặc<br/>- Ung thư thực quản nguyên phát; hoặc<br/>- Ung thư não nguyên phát; hoặc<br/>- Ung thư xương nguyên phát; hoặc<br/>- Ung thư hạch không Hodgkin.</p>`,
    category: "product",
  },
  {
    id: "7",
    question: "Thời hạn HĐ của sản phẩm bảo hiểm Bệnh ung thư là bao lâu?",
    answer: `Thưa anh/chị, thời hạn Hợp đồng bằng 5 năm tính từ Ngày hiệu lực Hợp đồng và được thể hiện trên Chứng nhận bảo hiểm và/hoặc (các) xác nhận thay đổi Hợp đồng (nếu có) ạ.`,
    category: "product",
  },
  {
    id: "8",
    question:
      "Tôi có thể tham gia HĐ sản phẩm bảo hiểm Bệnh ung thư qua các hình thức nào?",
    answer: `Thưa anh/chị, hiện nay MB Ageas Life đang triển khai sản phẩm bảo hiểm Bệnh ung thư qua 2 hình thức sau:<br/>1. Qua ứng dụng MBBANK của Ngân hàng TMCP Quân đội:<br/>Khi anh/chị đăng ký tham gia bảo hiểm qua ứng dụng MBBANK, HĐ Sản phẩm bảo hiểm Bệnh ung thư sẽ là phiên bản <b>"Ung dung sống khỏe"</b>, bao gồm QLBH Bệnh ung thư và QLBH Sống khỏe.<br/>2. Qua Chuyên viên tư vấn bảo hiểm (CVTVBH):<br/>Khi anh/chị đăng ký tham gia bảo hiểm qua CVTVBH, HĐ sản phẩm bảo hiểm Bệnh ung thư sẽ là phiên bản <b>"Ung dung sống trọn"</b>, bao gồm:<br/>- QLBH bệnh Ung thư.<br/>- Quyền lợi bảo hiểm nâng cao: anh/chị có thể cân nhắc lựa chọn thêm QLBH Bệnh ung thư đặc biệt và/hoặc QLBH Sống khỏe để gia tăng quyền lợi bảo vệ.`,
    category: "product",
  },
  {
    id: "9",
    question: "Tôi có thể lựa chọn Số tiền bảo hiểm (STBH) bao nhiêu?",
    answer: `Thưa anh/chị, KH có thể lựa chọn số tiền bảo hiểm (STBH) với các mệnh giá sau:<br/>&nbsp;&nbsp;&nbsp;&nbsp;100.000.000 đồng<br/>&nbsp;&nbsp;&nbsp;&nbsp;300.000.000 đồng<br/>&nbsp;&nbsp;&nbsp;&nbsp;500.000.000 đồng<br/>Ngoài ra, tổng STBH của các HĐ cho sản phẩm bảo hiểm Bệnh ung thư không quá 500.000.000 đồng/NĐBH với NĐBH từ 18 tuổi trở lên.`,
    category: "product",
  },
  {
    id: "10",
    question:
      "Khi HĐ hiện tại kết thúc thời hạn HĐ, trường hợp tôi đăng ký tham gia 1 HĐ mới của sản phẩm bảo hiểm Bệnh ung thư thì có ưu đãi gì cho KH không?",
    answer: `Thưa anh/chị, trong vòng 30 ngày kể từ ngày kết thúc Thời hạn HĐ (Nếu Hợp đồng hiện tại thỏa mãn điều kiện: Tất cả Phí bảo hiểm đến hạn đã được đóng đầy đủ, HĐ chưa từng chấm dứt hiệu lực và QLBH bệnh Ung thư chưa được chấp nhận chi trả), BMBH có quyền yêu cầu tham gia 01 HĐBH mới cho cùng NĐBH phù hợp với quy định của MB Ageas Life sẽ: không phải đánh giá lại rủi ro sức khỏe`,
    category: "product",
  },
  {
    id: "11",
    question: "Tôi sẽ nhận được bộ HĐBH qua hình thức nào?",
    answer: `Thưa anh/chị, sau khi HĐBH phát hành, MB Ageas Life sẽ thực hiện gửi bộ HĐBH điện tử bằng tin nhắn đến số điện thoại do BMBH cung cấp.`,
    category: "product",
  },
  {
    id: "12",
    question: "Quyền đảm bảo phát hành HĐ mới là gì?",
    answer: `Thưa anh/chị, nếu Hợp đồng đáp ứng các điều kiện sau:<br/>&nbsp;&nbsp;&nbsp;&nbsp;(i) Tất cả Phí bảo hiểm đến hạn đã được đóng đầy đủ; và<br/>&nbsp;&nbsp;&nbsp;&nbsp;(ii) Trong Thời hạn Hợp đồng, Hợp đồng chưa từng chấm dứt hiệu lực và Quyền lợi bảo hiểm Bệnh ung thư chưa được chấp nhận chi trả;<br/>thì trong vòng 30 ngày kể từ ngày kết thúc Thời hạn Hợp đồng, Bên mua bảo hiểm có quyền yêu cầu tham gia 01 Hợp đồng bảo hiểm mới cho cùng Người được bảo hiểm phù hợp với quy định của MB Ageas Life mà không phải đánh giá lại rủi ro sức khỏe. Hợp đồng mới sẽ tuân theo Quy tắc, điều khoản của:<br/>&nbsp;&nbsp;&nbsp;&nbsp;- Sản phẩm bảo hiểm bệnh ung thư này (nếu vẫn tiếp tục được bán); hoặc<br/>Sản phẩm bảo hiểm bệnh ung thư tương tự (nếu có) (trong trường hợp Sản phẩm bảo hiểm bệnh ung thư này đã dừng bán).`,
    category: "product",
  },
  {
    id: "13",
    question:
      "Tôi muốn mua Ung dung sống khỏe trên MB App nhưng hệ thống báo lỗi, tôi phải làm gì?",
    answer: `Thưa anh/chị, em xin lỗi anh/chị về sự bất tiện này. Trong trường hợp hệ thống báo lỗi, anh/chị vui lòng liên hệ đến Tổng đài chăm sóc Khách hàng của MB Ageas Life theo số điện thoại 024 2229 8888 các ngày làm việc trong tuần từ thứ Hai đến thứ Sáu, hoặc gửi e-mail đến địa chỉ: <a href="mailto:dvkh@mbageas.life">dvkh@mbageas.life</a> để được hỗ trợ ạ.`,
    category: "product",
  },
  {
    id: "14",
    question:
      "Tôi muốn tăng/giảm STBH cho phù hợp hơn với nhu cầu hiện nay thì phải làm gì?",
    answer: `Thưa anh/chị, HĐBH Bệnh ung thư không thể điều chỉnh Số tiền bảo hiểm trong Thời hạn Hợp đồng ạ.`,
    category: "product",
  },
  {
    id: "15",
    question:
      "Tôi muốn chỉ định Người thụ hưởng là bố/mẹ… tôi thì phải làm gì?",
    answer: `Thưa anh/chị, trong thời gian HĐBH có hiệu lực, Bên mua bảo hiểm (BMBH) có thể gửi yêu cầu thay đổi (những) Người thụ hưởng hoặc tỷ lệ thụ hưởng của mỗi Người thụ hưởng của HĐBH cho MB Ageas Life. Việc thay đổi chỉ có hiệu lực khi MB Ageas Life chấp nhận và MB Ageas Life không chịu trách nhiệm về tính hợp pháp cũng như tranh chấp (nếu có của BMBH hoặc những người liên quan đến việc chỉ định thay đổi Người thụ hưởng).`,
    category: "product",
  },
  {
    id: "16",
    question: "Tôi muốn hủy hợp đồng thì phải làm gì?",
    answer: `Thưa anh/chị, khi Hợp đồng đang có hiệu lực, Bên mua bảo hiểm có quyền yêu cầu chấm dứt Hợp đồng bằng văn bản. Ngày chấm dứt hiệu lực được thể hiện trên xác nhận của MB Ageas Life và Bên mua bảo hiểm không được hoàn lại Phí bảo hiểm định kỳ đến hạn đã đóng.`,
    category: "product",
  },
  {
    id: "17",
    question:
      "Các trường hợp Ung thư nào tôi sẽ không được chi trả Quyền lợi bảo hiểm?",
    answer: `Thưa anh/chị, Quyền lợi bảo hiểm bệnh Ung thư sẽ không được chi trả cho các trường hợp sau:<br/>&nbsp;&nbsp;&nbsp;&nbsp;a) Ung thư biểu mô da phi hắc tố, u lympho nguyên phát giới hạn ở da và u nguyên phát dạng u dưới da mà không có bằng chứng về di căn đến các hạch bạch huyết hoặc di căn xa hơn; và/hoặc<br/>&nbsp;&nbsp;&nbsp;&nbsp;b) U hắc tố ác tính chưa xâm lấn ra ngoài biểu bì; và/hoặc<br/>&nbsp;&nbsp;&nbsp;&nbsp;c) Ung thư tuyến giáp được chẩn đoán mô học giai đoạn T1N0M0 hoặc giai đoạn nhẹ hơn theo phân loại TNM; và/hoặc<br/>&nbsp;&nbsp;&nbsp;&nbsp;d) Ung thư/Khối u mô đệm dạ dày-ruột được chẩn đoán mô học giai đoạn I hoặc IA hoặc nhẹ hơn theo Phân loại AJCC (**); và/hoặc<br/>&nbsp;&nbsp;&nbsp;&nbsp;e) Bệnh ung thư trên người đang nhiễm HIV; và/hoặc<br/>&nbsp;&nbsp;&nbsp;&nbsp;f) Bệnh ung thư có dấu hiệu và triệu chứng (tự biểu hiện hoặc được chẩn đoán) xuất hiện trước hoặc trong vòng 90 ngày kể từ Ngày hiệu lực Hợp đồng (trừ trường hợp Hợp đồng được phát hành theo Điều 14); và/hoặc<br/>&nbsp;&nbsp;&nbsp;&nbsp;g) Bệnh ung thư liên quan đến dị tật bẩm sinh.<br/>(**) Phân loại <b>AJCC theo</b> ấn bản mới nhất của Sổ tay Phân loại Ung thư của Ủy ban liên minh Hoa Kỳ về Ung Thư (AJCC).`,
    category: "benefit",
  },
  {
    id: "18",
    question:
      "Ngay sau khi tham gia HĐ Sản phẩm bảo hiểm Bệnh ung thư, nếu tôi phát hiện bị mắc Bệnh ung thư, tôi có được chi trả luôn hay không?",
    answer: `Thưa anh/chị, MB Ageas Life sẽ không chi trả với Bệnh ung thư có dấu hiệu và triệu chứng xuất hiện trước hoặc trong vòng 90 ngày kể từ Ngày hiệu lực Hợp đồng (trừ trường hợp Hợp đồng được phát hành theo Quyền cam kết phát hành HĐ mới).`,
    category: "benefit",
  },
  {
    id: "19",
    question:
      "Khi xảy ra sự kiện bảo hiểm, ai sẽ người nhận Quyền lợi bảo hiểm?",
    answer: `Thưa anh/chị, khi xảy ra sự kiện bảo hiểm, MB Ageas Life sẽ trả Quyền lợi bảo hiểm theo thứ tự ưu tiên:<br/>&nbsp;&nbsp;&nbsp;&nbsp;(i) Bên mua bảo hiểm; hoặc<br/>Người được bảo hiểm (hoặc Người thừa kế hợp pháp của NĐBH đã tử vong).`,
    category: "benefit",
  },
  {
    id: "20",
    question:
      "Tôi tham gia Hợp đồng sản phẩm bảo hiểm Bệnh ung thư đã được 2 năm, chưa đóng phí bảo hiểm định kỳ đến hạn ở đầu năm thứ 3. Trong thời gian gia hạn đóng phí:<br/>- Tại ngày thứ 35, tôi có chẩn đoán mắc Bệnh ung thư<br/>- Tại ngày thứ 40, tôi đã nộp HSYCGQQLBH.<br/>Do khó khăn về tài chính nên tôi đã không đóng phí bảo hiểm đến hạn, dẫn tới HĐ bị mất hiệu lực. Trong trường hợp này, nếu HSYCGQQLBH của tôi đủ điều kiện được chi trả, thì MB Ageas Life có chi trả quyền lợi bảo hiểm cho tôi không?",
    answer: `Thưa anh/chị, đây là trường hợp KH không may có chẩn đoán mắc Bệnh ung thư trong thời gian gia hạn đóng phí trước khi Hợp đồng mất hiệu lực. Sau khi MB Ageas Life thực hiện đánh giá và thẩm định, nếu HSYCGQQLBH của KH được chấp thuận chi trả, MB Ageas Life sẽ chi trả Quyền lợi bảo hiểm trừ đi Khoản nợ (nếu có) - ở đây là khoản phí bảo hiểm định kỳ tại năm thứ 3 của HĐ mà KH chưa đóng phí. `,
    category: "benefit",
  },
  {
    id: "21",
    question:
      "Sau khi Hợp đồng Sản phẩm bảo hiểm bệnh ung thư hiện tại kết thúc thời hạn bảo hiểm (5 năm), tôi đã yêu cầu tham gia một Hợp đồng sản phẩm bảo hiểm Bệnh ung thư mới cho bản thân (theo Quyền cam kết phát hành Hợp đồng mới), và đã được MB Ageas Life chấp thuận. Giả sử trước khi Hợp đồng mới có hiệu lực, tôi có chẩn đoán mặc Bệnh ung thư, thì tôi có được MB Ageas Life chi trả Quyền lợi không?",
    answer: `Thưa anh/chị, trường hợp KH không may có chẩn đoán Bệnh ung thư trong khoảng thời gian sau khi Hợp đồng SPBH bệnh ung thư hiện tại đã kết thúc thời hạn hợp đồng và trước khi Hợp đồng SPBH bệnh ung thư mới (phát hành theo Quyền cam kêt phat hành hợp đồng mới) có hiệu lực thì MB Ageas Life sẽ không chi trả quyền lợi bảo hiểm.`,
    category: "benefit",
  },
  {
    id: "22",
    question:
      "Tôi quên không đóng phí và giờ đã qua thời gian gia hạn đóng phí. Tôi phải làm thủ tục gì để khôi phục hiệu lực HĐ?",
    answer: `Thưa anh/chị, khi Hợp đồng đã mất hiệu lực sẽ không thể khôi phục hiệu lực. Anh/chị vui lòng thu xếp nộp phí bảo hiểm định kỳ trong Thời gian gia hạn đóng phí (60 ngày kể từ ngày đến hạn đóng phí) để đảm bảo quyền lợi của Hợp đồng giúp em.`,
    category: "benefit",
  },
  {
    id: "23",
    question:
      "Tôi phải nộp hồ sơ gì để được giải quyết Quyền lợi bảo hiểm Sống khỏe?",
    answer: `Thưa anh/chị, để được hưởng quyền lợi bảo hiểm Sống khỏe, trước hết cần được chấp thuận giải quyết quyền lợi bảo hiểm ung thư.<br/>Sau thời gian 02 năm kể từ ngày được chẩn đoán ung thư, anh/chị có thể yêu cầu quyền lợi bảo hiểm Sống khỏe.<br/>Để yêu cầu giải quyết quyền lợi bảo hiểm Sống khỏe, anh/chị cần chuẩn bị các hồ sơ gồm:<br/>&nbsp;&nbsp;&nbsp;&nbsp;- Đơn yêu cầu giải quyết quyền lợi bảo hiểm theo mẫu Công ty<br/>&nbsp;&nbsp;&nbsp;&nbsp;- Giấy ủy quyền thu thập thông tin theo mẫu Công ty<br/>&nbsp;&nbsp;&nbsp;&nbsp;- <b>Đối với quyền lợi Sống khỏe:</b> hồ sơ tái khám sau 02 năm kể từ ngày được chẩn đoán bệnh lý ung thư.<br/>&nbsp;&nbsp;&nbsp;&nbsp;- Bản sao hợp pháp giấy tờ tùy thân của Người được bảo hiểm<br/>&nbsp;&nbsp;&nbsp;&nbsp;- Bản sao hợp pháp giấy tờ tùy thân của Người nhận quyền lợi bảo hiểm<br/>&nbsp;&nbsp;&nbsp;&nbsp;- Bằng chứng về quyền được nhận quyền lợi bảo hiểm <i>trong trường hợp người nhận quyền lợi bảo hiểm không phải người thụ hưởng (như Giấy ủy quyền)</i>`,
    category: "benefit",
  },
  {
    id: "24",
    question:
      "Nếu tôi bị Bệnh ung thư vào năm thứ 5 của HĐ thì tôi có được quyền lợi Sống khỏe không?",
    answer: `Thưa anh/chị, trường hợp NĐBH không may có chẩn đoán Bệnh ung thư tại 5 thứ năm của HĐ và được chấp thuận chi trả Quyền lợi bảo hiểm bệnh ung thư, nếu  NĐBH còn sống đến ngày liền sau 24 tháng kể từ Ngày chẩn đoán mắc Bệnh ung thư này thì MB Ageas Life sẽ chi trả Quyền lợi bảo hiểm Sống khỏe ạ.`,
    category: "benefit",
  },
  {
    id: "25",
    question:
      "Tôi có đã từng có Hợp đồng sản phẩm bảo hiểm Bệnh ung thư và đã được Giải quyết quyền lợi bảo hiểm, thì có được mua HĐ tiếp theo không?",
    answer: `Thưa anh/chị, đối với trường hợp Khách hàng đã từng được chấp thuận chi trả Quyền lợi bảo hiểm Bệnh ung thư rất tiếc sẽ không thể tham gia  1 HĐ sản phẩm BH Bệnh ung thư mới do quy định về thẩm định rủi ro của MB Ageas Life ạ.`,
    category: "benefit",
  },
  {
    id: "26",
    question: "KH có thể đóng phí bảo hiểm theo những định kỳ nộp phí nào?",
    answer: `Thưa anh/chị, hiện nay, MB Ageas Life chỉ áp dụng định kỳ đóng phí hàng Năm ạ.`,
    category: "fee",
  },
  {
    id: "27",
    question:
      "Phí bảo hiểm của HĐ sản phẩm bảo hiểm Bệnh ung thư là bao nhiêu?",
    answer: `Thưa anh/chị, Phí bảo hiểm sẽ phụ thuộc vào độ tuổi, giới tính, quyền lợi bảo hiểm và số tiền bảo hiểm KH lựa chọn. Anh/chị vui lòng chủ động truy cập ứng dụng MBBANK hoặc liên hệ CVTVBH để tìm hiểu cũng như được hỗ trợ ạ.`,
    category: "fee",
  },
  {
    id: "28",
    question:
      "HĐ sản phẩm bảo hiểm Bệnh ung thư có áp dụng thời gian gia hạn đóng phí không?",
    answer: `Thưa anh/chị, kể từ Ngày đến hạn đóng phí, KH sẽ có thời gian gia hạn đóng Phí bảo hiểm là 60 ngày. Nếu Phí bảo hiểm không được đóng trong thời gian gia hạn đóng phí, HĐ sẽ chấm dứt hiệu lực và BMBH sẽ không được hoàn lại Phí bảo hiểm đã đóng.<br/><b>HĐ đã mất hiệu lực sẽ không thể khôi phục hiệu lực.</b>`,
    category: "fee",
  },
  {
    id: "29",
    question:
      "Tôi muốn thay đổi định kỳ đóng phí từ năm thành tháng thì phải làm gì?",
    answer: `Thưa anh/chị, hiện nay, MB Ageas Life chỉ áp dụng định kỳ đóng phí hàng Năm đối với HĐ SPBH Bệnh ung thư ạ.`,
    category: "fee",
  },
  {
    id: "30",
    question:
      "Tôi vừa đóng phí bảo hiểm nhưng giờ tôi muốn hủy HĐ thì có được nhận lại phí bảo hiểm không?",
    answer: `Thưa anh/chị,<br/>&nbsp;&nbsp;&nbsp;&nbsp;1) Trong thời gian cân nhắc (21 ngày), với điều kiện chưa có yêu cầu GQQLBH, BMBH có quyền từ chối tiếp tục tham gia bảo hiểm bằng văn bản theo quy định của MB Ageas Life, và được hoàn trả Phí bảo hiểm đã đóng (không có lãi) trừ đi: (i) Phí bảo hiểm đã hoàn lại (nếu có); và (ii) Chi phí khám sức khỏe (nếu có).<br/>&nbsp;&nbsp;&nbsp;&nbsp;2) Khi Hợp đồng đang có hiệu lực (qua thời gian cân nhắc 21 ngày), Bên mua bảo hiểm có quyền yêu cầu chấm dứt Hợp đồng bằng văn bản. Ngày chấm dứt hiệu lực được thể hiện trên xác nhận của MB Ageas Life và Bên mua bảo hiểm không được hoàn lại Phí bảo hiểm định kỳ đến hạn đã đóng.`,
    category: "fee",
  },
];
