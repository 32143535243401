import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import styles from "./PopupConfirm.module.scss";
import { useMediaQuery } from "react-responsive";
import closeBlueImg from "../../assets/images/close-blue.svg";
import closeRedImg from "../../assets/images/close-red.svg";
import HighlightText from "../HighlightText";
import Utils from "src/utils/Utils";
export interface PopupConfirmProps {
  visible?: boolean;
  children?: React.ReactNode;
  title?: string;
  content?: string;
  onOk?: () => void;
  okTitle?: string;
  onCancel?: () => void;
  cancelTitle?: string;
  showIcon?: boolean;
  onlyCancelButton?: boolean;
  onlyOkButton?: boolean;
  showClose?: boolean;
  image?: React.ReactNode;
  titleClassName?: string;
  showContactNow?: boolean;
  hotline?: string;
  replaceText?: Array<string>;
  largePopup?: boolean;
  behind?: boolean;
}

const PopupConfirm = (props: PopupConfirmProps) => {
  const { t } = useTranslation();
  const {
    visible,
    children,
    onOk,
    onCancel,
    title,
    content,
    okTitle,
    cancelTitle,
    showIcon = true,
    onlyCancelButton,
    onlyOkButton,
    showClose,
    image,
    titleClassName,
    showContactNow = false,
    hotline = t("hotline"),
    largePopup = false,
    behind = false,
  } = props;

  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [visible]);

  const _renderFooter = () => {
    if (onlyOkButton) {
      return (
        <div className={styles.footer}>
          <Button variant="primary" onClick={onOk} className="flex1">
            {okTitle || t("confirm")}
          </Button>
        </div>
      );
    }
    if (onlyCancelButton) {
      return (
        <div className={styles.footer}>
          <Button variant="light" onClick={onCancel} className="flex1">
            {cancelTitle || t("go-back")}
          </Button>
        </div>
      );
    }
    return (
      <div className={styles.footer}>
        <div className="rowStart flex1 me-3">
          <Button variant="light" onClick={onCancel} className="flex1">
            {cancelTitle || t("go-back")}
          </Button>
        </div>

        {showContactNow ? (
          <div className="rowStart flex1">
            <a href={`tel:${hotline}`} className={styles.linkButton}>
              <Button variant="primary" onClick={onOk} className="flex1">
                {okTitle || t("contact-now")}
              </Button>
            </a>
          </div>
        ) : (
          <div className="rowStart flex1">
            <Button variant="primary" onClick={onOk} className="flex1">
              {okTitle || t("confirm")}
            </Button>
          </div>
        )}
      </div>
    );
  };

  if (!visible) return <div />;
  return (
    <Modal
      show={visible}
      centered
      dialogClassName={largePopup ? styles.dialogLarge : styles.dialog}
      className={behind ? styles.behind : ""}
    >
      <div className={styles.contentContainer}>
        {showIcon &&
          (image || (
            <img
              width={72}
              height={72}
              alt="Error"
              src={closeRedImg}
              className="mb32"
              onClick={onCancel}
            />
          ))}
        {showClose ? (
          <div className="rowStart fullWidth mb-4">
            <div className={titleClassName || "popupTitle2 textBlack flex1"}>
              {title || t("notification")}
            </div>
            <img
              src={closeBlueImg}
              width={isMobile ? 32 : 56}
              height={isMobile ? 32 : 56}
              alt="close"
              onClick={onCancel}
            />
          </div>
        ) : (
          <div
            className={
              titleClassName || "popupTitle textBlack text-center mb-4"
            }
          >
            {title || t("notification")}
          </div>
        )}

        {!!children ? (
          children
        ) : showContactNow ? (
          <HighlightText
            text={content ?? ""}
            replaceText={[Utils.formatPhoneNumber(hotline!) ?? ""]}
            className={"popupBody textBlack text-center"}
            highlightClassName={"w600"}
          />
        ) : (
          <div className="popupBody textBlack text-center">{content}</div>
        )}
      </div>
      {_renderFooter()}
    </Modal>
  );
};

export default PopupConfirm;
