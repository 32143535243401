import { ReactNode } from "react";
import Select from "react-select";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { FormatOptionLabelMeta } from "react-select/dist/declarations/src/Select";
import { useTranslation } from "react-i18next";

export interface Option {
  value: string;
  label: string;
}

export interface SearchableSelectProps<Option> {
  label?: string;
  required?: boolean;
  placeholder?: string;
  errorText?: string;
  options?: Option[];
  onChange?: (value: Option | null) => void;
  value?: Option | null;
  formatOptionLabel?: (
    data: Option,
    formatOptionLabelMeta: FormatOptionLabelMeta<Option>
  ) => ReactNode;
  filterOption?:
    | ((option: FilterOptionOption<Option>, inputValue: string) => boolean)
    | null;
}

const SearchableSelect = <Option extends object>({
  label,
  placeholder,
  errorText,
  required,
  options,
  onChange,
  value,
  formatOptionLabel,
  filterOption,
}: SearchableSelectProps<Option>) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="fullWidth form-label">
        <div className="rowStart fullWidth">
          <div className="flex1">
            {label}
            {!!required && <span className="required"> *</span>}
          </div>
        </div>
      </div>
      <div className="mb-4">
        <Select
          isSearchable
          isClearable
          options={options}
          placeholder={placeholder}
          className="select"
          classNamePrefix="mbal"
          blurInputOnSelect
          onChange={onChange}
          value={value}
          formatOptionLabel={formatOptionLabel}
          filterOption={filterOption}
          noOptionsMessage={({ inputValue }) => t("no-data")}
        />
        {!!errorText && <div className="invalidText">{errorText}</div>}
      </div>
    </>
  );
};

export default SearchableSelect;
