import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import styles from "./Input.module.scss";
interface InputProps {
  label?: string;
  required?: boolean;
  placeholder?: string;
  leftIcon?: string;
  errorText?: string;
  maxLength?: number;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onSubmit?: React.FormEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  showDefautErrorIcon?: boolean;
  disabled?: boolean;
  rightLabelComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
  marginBottom?: boolean;
  labelClassName?: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}

const Input = ({
  label,
  required,
  leftIcon,
  errorText,
  showDefautErrorIcon = true,
  rightLabelComponent,
  rightComponent,
  marginBottom = true,
  labelClassName,
  ...props
}: InputProps) => {
  return (
    <div className={styles.inputContainer}>
      {label && (
        <Form.Label className="fullWidth">
          <div className="rowStart fullWidth">
            <div
              className={labelClassName ? `flex1 ${labelClassName}` : "flex1"}
            >
              {label}
              {!!required && <span className="required"> *</span>}
            </div>
            {rightLabelComponent && rightLabelComponent}
          </div>
        </Form.Label>
      )}
      <InputGroup
        className={marginBottom ? "mb-4" : ""}
        hasValidation={!!errorText}
      >
        {leftIcon && (
          <InputGroup.Text className={!!errorText ? "error" : ""}>
            <img src={leftIcon} width={24} height={24} alt={leftIcon} />
          </InputGroup.Text>
        )}

        <FormControl
          {...props}
          style={leftIcon ? { borderLeftColor: "transparent" } : undefined}
          isInvalid={!!errorText}
          className={showDefautErrorIcon ? "" : "noErrorIcon"}
        />

        {!!errorText && (
          <Form.Control.Feedback type="invalid">
            {errorText}
          </Form.Control.Feedback>
        )}
      </InputGroup>
      {rightComponent && (
        <div className={styles.rightComponentContainer}>{rightComponent}</div>
      )}
    </div>
  );
};

export default Input;
