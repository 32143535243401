import { HorizontalStepperItem } from "./components/HorizontalStepper";
import documentAddImg from "./assets/images/document-add.svg";
import questionMarkCircleImg from "./assets/images/question-mark-circle.svg";
import informationCircleImg from "./assets/images/information-circle.svg";

export const BUY_INSURANCE_STEP_DATA: Array<HorizontalStepperItem> = [
  {
    name: "fill-personal-info",
    icon: documentAddImg,
  },
  {
    name: "health-question",
    icon: questionMarkCircleImg,
  },
  {
    name: "confirm-info",
    icon: informationCircleImg,
  },
];

export const OTP_LENGTH: number = 6;
export const OTP_TIME = 60; // seconds;
export const REPLACE_IDENTIFIER = "*****";

export const VN_COUNTRY_CODE: string = "VN";

export enum ProductCode {
  CANCER = "CANCER",
  SPA = "SPA",
}

export enum PromotionStatus {
  HAPPENING = "HAPPENING",
  FINISHED = "FINISHED",
}

export enum HomeNewsPromotionType {
  PROMOTION = "PROMOTION",
  NEWS = "EVENT_NEW",
}

export enum ProductType {
  PRODUCT_SUPPORT = "PRODUCT_SUPPORT",
  PRODUCT_MAIN = "PRODUCT_MAIN",
}
export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
}
export const GenderDisplayMap = {
  [Gender.MALE]: "male",
  [Gender.FEMALE]: "female",
};
export enum CardTypeOrigin {
  PASSPORT = "FS0002",
  CMND = "ZMBL02",
  CCCD = "ZMBL03",
  CMTQD = "ZMBL18",
}

export enum CardType {
  PASSPORT = "PASSPORT",
  CMND = "CMND",
  CCCD = "CCCD",
  CMTQD = "CMTQD",
}

export const CardTypeSortDisplayMap = {
  [CardType.PASSPORT]: "passport",
  [CardType.CMND]: "cmnd-sort",
  [CardType.CCCD]: "cccd-sort",
  [CardType.CMTQD]: "military-card-sort",
};

export const CardTypeOriginDisplayMap = {
  [CardTypeOrigin.PASSPORT]: "passport",
  [CardTypeOrigin.CMND]: "cmnd",
  [CardTypeOrigin.CCCD]: "cccd",
  [CardTypeOrigin.CMTQD]: "military-card",
};

export const CARD_TYPE_VALUES = [
  { name: "passport", value: CardType.PASSPORT },
  { name: "cmnd", value: CardType.CMND },
  { name: "cccd", value: CardType.CCCD },
  { name: "military-card", value: CardType.CMTQD },
];
export const PAGE_SIZE = 10;

export const FREE_CANCER_SUM_ASSURED = 100000000;
export const CANCER_URL =
  "https://www.mbageas.life/san-pham/bao-hiem-ung-dung-song-khoe";
export const MBAGEAS_LIFE_URL = "https://www.mbageas.life/";
export const INSURANCE_PAYMENT_GUIDE_URL =
  "https://cskh.mbageas.life/huong-dan-dong-phi";
export const SUPPORT_MAIL = "dvkh@mbageas.life";
export const MB_QR_URL = "https://mbbank.onelink.me/QPF5/c0299c2b";
export const MBAL_QR_URL =
  "https://mbal-cms.ntq.solutions/tra-cuu-va-dong-phi-bao-hiem";

export const MBAL_GA_TRACKING_URL =
  "https://ga-tracking-uat.mbageas.org/tracking";

// export const MBAL_GA_SALES_HUB = "UA-207447831-1";

export enum PAYMENT_CARD_TYPE {
  DOMESTIC = 1,
  CREDIT = 2,
}

export enum BUYING_STATUS_TYPE {
  Unsuccessful,
  Success,
}

export const BUYING_STATUS = [
  { id: [BUYING_STATUS_TYPE.Unsuccessful], name: "Unsuccessful" },
  { id: [BUYING_STATUS_TYPE.Success], name: "Success" },
];

export const BUYING_PROCESS_TRACKING = "Buying Process";
export const CHECKING_VOUCHER_CODE_FISRT = "Checking voucher code first";
export const STRING_EMPTY = "";

export enum BUYING_PROCESS_STEP {
  InputVoucherCode,
  InputCustomerInfo,
  AnswerHealthQuestion,
  InputContactInfo,
  ConfirmContactInfo,
  VerifyOTP,
  CreateContractSuccess,
}

export const BUYING_PROCESS = [
  {
    id: [BUYING_PROCESS_STEP.InputVoucherCode],
    name: "Step 1: Input Voucher Code",
  },
  {
    id: [BUYING_PROCESS_STEP.InputCustomerInfo],
    name: "Step 2: Input Customer Info",
  },
  {
    id: [BUYING_PROCESS_STEP.AnswerHealthQuestion],
    name: "Step 3: Answer Health Question",
  },
  {
    id: [BUYING_PROCESS_STEP.InputContactInfo],
    name: "Step 4: Input Contact Info",
  },
  {
    id: [BUYING_PROCESS_STEP.ConfirmContactInfo],
    name: "Step 5: Confirm Contact Info",
  },
  { id: [BUYING_PROCESS_STEP.VerifyOTP], name: "Step 6: Verify OTP" },
  {
    id: [BUYING_PROCESS_STEP.CreateContractSuccess],
    name: "Create Contract Success",
  },
];

export enum POLICY_STATUS {
  LAPSE = "LAPSE",
  DOMART = "DOMART",
  ACTIVE = "ACTIVE",
  REVERSED = "REVERSED",
}

export const POLICY_STATUS_VALUES = [
  { name: "policy-status-active", value: POLICY_STATUS.ACTIVE },
  { name: "policy-status-lapse", value: POLICY_STATUS.LAPSE },
  { name: "policy-status-domart", value: POLICY_STATUS.DOMART },
  { name: "policy-status-reversed", value: POLICY_STATUS.REVERSED },
];

export const EMPTY_ARRAY = [];
