import { useEffect } from "react";
import styles from "./Home.module.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HomeInsuranceItem from "../../components/HomeInsuranceItem";
import { useTranslation } from "react-i18next";
import cancerInsuranceImg from "../../assets/images/cancer_insurance.png";
import accidentInsuranceImg from "../../assets/images/cancer_360.png";
// import cancer360Img from "../../assets/images/cancer_360.png";
import { useHistory } from "react-router-dom";
// import { getHomeOnlineProduct } from "src/api";
import CancerFormService from "../BuyInsurance/CancerFormService";
// import homeBrandImg from "src/assets/images/home-brand.svg";
import Button from "react-bootstrap/Button";
import CustomerSupport from "src/components/CustomerSupport";
import homeBannerImg from "src/assets/images/home-banner.png";
import arrowRightImg from "src/assets/images/arrow-right.svg";
import { useMediaQuery } from "react-responsive";
import SellerInfoService from "src/services/SellerInfoService";
import { MBAGEAS_LIFE_URL } from "src/Constants";
import Utils from "src/utils/Utils";

const Home = () => {
  const { t } = useTranslation();
  const isUnderMd = useMediaQuery({
    query: "(max-width: 768px)",
  });

  useEffect(() => {
    CancerFormService.reset();
  }, []);

  const _renderBannerContent = () => {
    return (
      <div className={styles.bannerContent}>
        <div className="appH1 textBlack mb32">
          {t("home-banner-title1-part1")}
          <span className="textPrimary">{t("home-banner-title1-part2")}</span>
        </div>
        <div className="body24 textBlack opacity72 mb40">
          {t("home-banner-desc")}
        </div>
        <a href={MBAGEAS_LIFE_URL} className="linkNoStyle">
          <Button variant="primary" className={styles.discoverButton}>
            <span>{t("discover")}</span>
            <img
              src={arrowRightImg}
              alt="Arrow right"
              className={styles.arrowRight}
            />
          </Button>
        </a>
      </div>
    );
  };

  const _renderBannerImage = () => {
    return (
      <img
        src={homeBannerImg}
        alt="Home banner"
        className={styles.homeBanner}
      />
    );
  };

  const _renderHomeBanner = () => {
    return (
      <Container fluid>
        <Row className={styles.bannerRow}>
          <Col md className="py-4">
            {isUnderMd ? _renderBannerImage() : _renderBannerContent()}
          </Col>
          <Col md className="py-4">
            {isUnderMd ? _renderBannerContent() : _renderBannerImage()}
          </Col>
        </Row>
      </Container>
    );
  };

  const _renderProduct = () => {
    return (
      <Container fluid className={styles.productBlock}>
        <Row className="mb-4">
          <div className="rowCenter">
            <div className={`${styles.blockExplainLine} me-3`} />
            <div className={`textBlack2Opacity subTitle rowCenter`}>
              {t("menu-product")}
            </div>
            <div className={`${styles.blockExplainLine} ms-3`} />
          </div>
        </Row>
        <Row className="mb32">
          <div className={`textBlack2 appH2 rowCenter`}>{t("highlighted")}</div>
        </Row>
        <Row className="justify-content-center">
          <Col md={12} lg={6} xl={6} className="mb-lg-0 mb-4">
            <HomeInsuranceItem
              imgSrc={cancerInsuranceImg}
              name={t("cancer_insurance")}
              description={t("cancer_insurance_description")}
              showBuyButton={Utils.canBuyCaner()}
              detailLink="/product/cancer"
              buyLink={Utils.getBuyCancerUrl()}
            />
          </Col>

          <Col md={12} xl={6} lg={6}>
            <HomeInsuranceItem
              imgSrc={accidentInsuranceImg}
              name={t("cancer_360")}
              description={t("cancer_360_description")}
              showBuyButton={false}
              detailLink="/product/accident"
            />
          </Col>
        </Row>
      </Container>
    );
  };

  const _renderBrand = () => {
    return (
      <div className={styles.brandContainer}>
        <div className={styles.brandBelow} />
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={11}>
              <div className={styles.homeBranchImgContainer}>
                <div className={styles.homeBrandContent}>
                  <div className="rowStart mb32">
                    <div className={styles.lineWhiteLeft} />
                    <div className={"title18 textOnPrimary"}>
                      {t("about-mb-ageas-life")}
                    </div>
                  </div>
                  <div className="appH2 mb32 prewrap textOnPrimary">
                    {t("branch-sologan")}
                  </div>
                  <div className="rowStart">
                    <a href={MBAGEAS_LIFE_URL} className="linkNoStyle">
                      <Button
                        variant="primary"
                        className={styles.buttonMoreInfo}
                      >
                        {t("more-info")}
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  return (
    <div>
      {_renderHomeBanner()}
      <Container fluid>
        <Row className="justify-content-center pt-4">
          <Col lg={11}>{_renderProduct()}</Col>
        </Row>
      </Container>
      <div className={styles.bottomContainer}>
        {_renderBrand()}
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={11}>
              <div className={styles.customerSupportBlock}>
                <CustomerSupport />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Home;
