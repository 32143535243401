import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./Header.module.scss";
import logo from "../../assets/images/logo.svg";
import logoSmall from "../../assets/images/logo-small.svg";
import phoneImg from "src/assets/images/phone-lightblue.svg";
import mailImg from "src/assets/images/mail-lightblue.svg";
import { MBAGEAS_LIFE_URL, SUPPORT_MAIL } from "src/Constants";
import { useMediaQuery } from "react-responsive";
import SellerInfoService from "src/services/SellerInfoService";
import { useEffect, useRef, useState } from "react";
interface TabLinkItem {
  path: string;
  name: string;
  hasSubMenu?: boolean;
  subMenu?: TabLinkItem[];
}

const Header = () => {
  const isUnderLg = useMediaQuery({
    query: "(max-width: 992px)",
  });
  const merchantCode = SellerInfoService.getSellerInfo()?.merchant?.code;
  console.log("merchantCode", merchantCode);
  const divRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [key, setKey] = useState(1);
  const TAB_LINKS: TabLinkItem[] =
    merchantCode &&
    (merchantCode.toUpperCase() === "SMARTRM" ||
      merchantCode.toUpperCase() === "MCREDIT" ||
      merchantCode.toUpperCase() === "SMRTKHCN")
      ? [
          { path: "/", name: "menu-home" },
          {
            path: "/product/cancer",
            name: "menu-product",
            hasSubMenu: true,
            subMenu: [
              { path: "/product/cancer", name: "cancer_insurance" },
              { path: "/product/accident", name: "accident-insurance" },
            ],
          },
          {
            path: "/contract-management",
            name: "contract-management",
          },
          // { path: "/promotion", name: "menu-discount" },
          { path: "/support", name: "menu-support" },
        ]
      : [
          { path: "/", name: "menu-home" },
          {
            path: "/product/cancer",
            name: "menu-product",
            hasSubMenu: true,
            subMenu: [
              { path: "/product/cancer", name: "cancer_insurance" },
              { path: "/product/accident", name: "accident-insurance" },
            ],
          },
          // { path: "/promotion", name: "menu-discount" },
          { path: "/support", name: "menu-support" },
        ];
  const isUnderMd = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    setKey(key + 1);
  }, [location]);

  const _renderContact = () => {
    return (
      <Nav className={styles.headerTop}>
        <a href={MBAGEAS_LIFE_URL} className={styles.headerItemTop}>
          <div className="body14 textPrimary fontSourceSansPro">
            {t("go-to-mbageas-life")}
          </div>
        </a>
        <a href={`tel:${t("hotline2")}`} className={styles.headerItemTop}>
          <img src={phoneImg} alt="phone" className={styles.headerTopIcon} />
          <div className="body14 textPrimary fontSourceSansPro">
            {t("hotline2-display")}
          </div>
        </a>
        <a href={"mailto:dvkh@mbageas.life"} className={styles.headerItemTop}>
          <img src={mailImg} alt="mail" className={styles.headerTopIcon} />
          <div className="body14 textPrimary fontSourceSansPro">{SUPPORT_MAIL}</div>
        </a>
      </Nav>
    );
  };

  return (
    <Navbar
      expand="lg"
      className={styles.headerContainer}
      fixed={"top"}
      key={"navKey-" + key}
    >
      {/* <Container> */}
      <Navbar.Brand href="/">
        {isUnderLg ? (
          <img
            src={logoSmall}
            width="92"
            height="46"
            className="d-inline-block align-top"
            alt="SaleHub Logo"
          />
        ) : (
          <img
            src={logo}
            width="177"
            height="88"
            className="d-inline-block align-top"
            alt="SaleHub Logo"
          />
        )}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="header">
        <Nav
          className="me-auto"
          ref={divRef}
          onClick={() => {
            console.log("Div clicked");
          }}
        />
        <div>
          {!isUnderMd && _renderContact()}
          <Nav bsPrefix="mainNav" className={styles.mainHeaderMenu}>
            {TAB_LINKS.map((item) => {
              if (item.hasSubMenu) {
                return (
                  <NavDropdown
                    id="headerDropdown"
                    title={t(item.name)}
                    menuVariant="light"
                    active={
                      !!item?.subMenu &&
                      item?.subMenu?.findIndex(
                        (itr) => itr.path === location.pathname
                      ) > -1
                    }
                    key={item.path + "-parent"}
                  >
                    {item.subMenu?.map((subItem) => (
                      <NavDropdown.Item
                        to={subItem.path}
                        as={Link}
                        key={item.path}
                      >
                        {t(subItem.name)}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                );
              }
              return (
                <Nav.Link
                  key={item.path}
                  as={Link}
                  to={item.path}
                  active={location.pathname === item.path}
                  tabIndex={0}
                >
                  {t(item.name)}
                </Nav.Link>
              );
            })}
          </Nav>
          {isUnderMd && _renderContact()}
        </div>
      </Navbar.Collapse>
      {/* </Container> */}
    </Navbar>
  );
};
export default Header;
