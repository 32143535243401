import styles from "./HomeInsuranceItem.module.scss";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface HomeInsuranceItemProps {
  name: string;
  description: string;
  imgSrc: string;
  onViewDetail?: () => void;
  showBuyButton?: boolean;
  detailLink: string;
  buyLink?: string;
}
const HomeInsuranceItem = ({
  name,
  description,
  imgSrc,
  showBuyButton = true,
  detailLink,
  buyLink,
}: HomeInsuranceItemProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.homeInsuranceItem}>
      <img src={imgSrc} alt={name} className={styles.homeInsuranceItemImage} />

      <div className={styles.homeInsuranceItemContent}>
        <div className="bodySmall textGray mb-1">{name}</div>
        <div className="textBlack smallTitle mb-3">{description}</div>
        <Row>
          {showBuyButton && (
            <Col md={6} sm={12} className="mb-md-0 mb-2">
              <Link to={buyLink!} className="linkNoStyle">
                <Button variant="primary" className="fullWidth">
                  {t("buy-online")}
                </Button>
              </Link>
            </Col>
          )}
          <Col md={6} sm={12}>
            <Link to={detailLink} className="linkNoStyle">
              <Button variant="outline-primary" className="fullWidth">
                {t("view-detail")}
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HomeInsuranceItem;
