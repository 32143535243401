import React, { useRef } from "react";
import placeholderImg from "src/assets/images/image-placeholder.svg";
import uploadImg from "src/assets/images/upload.svg";
import styles from "./ImageInput.module.scss";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import Utils from "src/utils/Utils";

interface ImageInputProps {
  label: string;
  file?: File;
  onChange: (file: File) => void;
  errText?: string;
  imageContainerClassName?: string;
  imageClassName?: string;
  helperText?: string;
  helperIcon?: string;
}

const ImageInput = ({
  label,
  errText,
  file,
  onChange,
  imageContainerClassName,
  imageClassName,
  helperText,
  helperIcon,
}: ImageInputProps) => {
  const fileInput = useRef() as React.MutableRefObject<HTMLInputElement>;

  const { t } = useTranslation();

  const _handleChangeFile = (e) => {
    if (!Utils.isValidImageFileType(e.target.files[0])) return;
    onChange(e.target.files[0]);
  };

  const _handleUpload = () => {
    fileInput?.current?.click();
  };

  // helperTextComponent ? (
  //   helperTextComponent
  // ) : (
  //   <div className="helpText">{t("image-file-hint")}</div>
  // )}
  // </div>

  return (
    <div>
      <div className="body16 textBlack w600 mb-3">{label}</div>
      <div className="mb-3">
        <div
          className={`${
            imageContainerClassName || styles.cardImageContainer
          } ratio`}
        >
          {file && Utils.isValidImageFileType(file) ? (
            file.name.toLowerCase().endsWith(".pdf") ? (
              <div style={{ width: "100%", height: "100%" }}>
                <Viewer fileUrl={URL.createObjectURL(file)} />
              </div>
            ) : (
              <img
                src={URL.createObjectURL(file)}
                className={`ratio ${imageClassName || styles.cardImage}`}
                alt={"Upload preview"}
              />
            )
          ) : (
            <div className="rowCenter">
              <img
                src={placeholderImg}
                className={styles.placeholderImg}
                alt={"Placeholder"}
              />
            </div>
          )}
        </div>
        {errText ? (
          <div className="invalidText">{errText}</div>
        ) : (
          <div className="helpText rowStart">
            {helperIcon && (
              <img src={helperIcon} className={styles.helperIcon} alt="Info" />
            )}
            {helperText || t("upload-hint")}
          </div>
        )}
      </div>

      <Button variant="light" className="fullWidth" onClick={_handleUpload}>
        <img src={uploadImg} className={styles.uploadImg} alt={"Upload"} />
        {t("upload")}
      </Button>
      <input
        type="file"
        onChange={_handleChangeFile}
        style={{ display: "none" }}
        ref={fileInput}
        accept=".jpg,.jpeg,.png,.pdf"
      />
    </div>
  );
};

export default React.memo(ImageInput);
