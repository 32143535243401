import styles from "./FreeCancerInsuranceItem.module.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import fireImg from "../../assets/images/fire.svg";
import cancerInsurance2Img from "../../assets/images/cancer_insurance2.png";
import shieldImg from "../../assets/images/shield.svg";
import Utils from "src/utils/Utils";
import { CANCER_URL } from "src/Constants";

interface InsuranceItemProps {
  voucherCode?: string;
  discountPercentage?: number;
  feeTxt?: string;
  feeDiscountText?: string;
  feeAfterDiscountTxt?: string;
}

const InsuranceItem = ({
  voucherCode,
  discountPercentage,
  feeTxt,
  feeDiscountText,
  feeAfterDiscountTxt,
}: InsuranceItemProps) => {
  const { t } = useTranslation();

  const _renderBenefit = () => {
    return (
      <>
        <div className={styles.benefitRow}>
          <div className="me-2">
            <img width="20" height="20" src={shieldImg} alt="Shield" />
          </div>

          <div className="body14 w600 textBlack">
            {t("cancer-benefit")}:{" "}
            <span className="textSecondary">100 triệu đồng</span>
          </div>
        </div>
        <div className={styles.benefitRow2}>
          <div className="me-2">
            <img width="20" height="20" src={shieldImg} alt="Shield" />
          </div>
          <div className="body14 w600 textBlack">
            {t("live-benefit")}:{" "}
            <span className="textSecondary">25 triệu đồng</span>
          </div>
        </div>
        <div className={styles.benefitRow}>
          <div className="me-2" style={{ opacity: 0 }}>
            <img width="20" height="20" src={shieldImg} alt="Shield" />
          </div>
          <span className="caption textGray">
            ({t("live-benefit-description")})
          </span>
        </div>
        {/* <div className={styles.benefitRow}>
          <div className="me-2">
            <img width="20" height="20" src={shieldImg} alt="Shield" />
          </div>
          <div className="body14 w600 textBlack">
            {t("insurance-period")}:{" "}
            <span className="textSecondary">5 năm</span>
          </div>
        </div>
        <div className={styles.benefitRow}>
          <div className="me-2">
            <img width="20" height="20" src={shieldImg} alt="Shield" />
          </div>
          <div className="body14 w600 textBlack">
            {t("fee-term")}: <span className="textSecondary">Hàng năm</span>
          </div>
        </div> */}
        <div className={styles.benefitRow}>
          <div className="me-2">
            <img width="20" height="20" src={shieldImg} alt="Shield" />
          </div>
          <div className="body14 w600 textBlack">
            {t("new-contract-benefit")}
          </div>
        </div>
      </>
    );
  };

  const _renderPaymentInfo = () => {
    if (!feeTxt) return <div />;
    return (
      <>
        <div className="body16 w700 textBlack mb-3">{t("payment-info")}</div>
        <div className="rowStart mb-2">
          <div className="flex1 body14 textBack">{t("yearly-fee")}</div>
          <div className="body14 w600 textBack">{feeTxt}</div>
        </div>
        <div className="rowStart mb-2">
          <div className="flex1 body14 textBack">
            {t("discount-for-first-year")}
          </div>
          <div className="body14 w600 textBack">-{feeDiscountText}</div>
        </div>
        <div className="rowStart mb-2">
          <div className="flex1 body14 textBack w600">
            {t("amount-first-year")}
          </div>
          <div className="body14 textSecondary w700">{feeAfterDiscountTxt}</div>
        </div>
        <div className="line mb-3 mt-3" />
      </>
    );
  };

  const _renderImage = () => {
    return (
      <div className={styles.imageContainer}>
        <div className={styles.discountContainer}>
          <div className={styles.discountContainerIconContainer}>
            <img src={fireImg} alt="Cancer insurance" width="24" height="24" />
          </div>
          <div
            className={`body14 textSecondary w500 ${styles.discountContainerContent}`}
          >
            {t("free-discount")}
          </div>
        </div>
        <img
          src={cancerInsurance2Img}
          alt="Cancer insurance"
          width="1320"
          height="660"
          className="img-fluid"
        />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {_renderImage()}
      <div className={styles.content}>
        <div className="smallTitle14 textGray mb-1">Bảo hiểm ung thư</div>
        <div className="smallTitle textBlack mb-2">Ung dung sống khoẻ</div>
        <div className="smallTitle16 w500 textGray mb-3">
          Quan tâm mỗi ngày, ung dung từng giây
        </div>
        <div className="line mb-3" />
        <Row>
          <Col>
            <div className="body14 textGray mb-1">{t("insurance-package")}</div>
            <div className="body16 w600 textPrimary">100 triệu đồng</div>
          </Col>
          {/* <Col>
            <div className="body14 textGray mb-1 text-end">
              {t("first-year-fee")}
            </div>
            <div className="body16 w600 textSecondary text-end">0 VNĐ</div>
          </Col> */}
        </Row>
        {!!voucherCode && (
          <div className={styles.voucherBlock}>
            <div className="body14 textPrimary">
              {t("appling-voucher-code")}{" "}
              <span className="w700">{voucherCode}</span>
            </div>
            <div className="body14 textPrimary">
              {Utils.getReplaceText(
                t("discount-first-year"),
                discountPercentage?.toString() || ""
              )}
            </div>
          </div>
        )}

        <div className="line mb-3 mt-3" />
        {_renderPaymentInfo()}
        {_renderBenefit()}
        <div className="mb-3" />
        <a href={CANCER_URL} target="_blank" rel="noreferrer">
          <Button variant={"outline-light"} className="fullWidth">
            {t("more-info")}
          </Button>
        </a>
      </div>
    </div>
  );
};

export default InsuranceItem;
