import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

export interface DropdownItem {
  value: string;
  name: string;
}
interface DropdownInputProps {
  label?: string;
  required?: boolean;
  placeholder?: string;
  values?: Array<DropdownItem>;
  value?: string;
  onChange?: (value?: string) => void;
  errorText?: string;
  marginBottom?: boolean;
}

const DropdownInput = ({
  label,
  required,
  placeholder,
  values,
  value,
  onChange,
  errorText,
  marginBottom = true,
}: DropdownInputProps) => {
  const _renderOption = (item: DropdownItem, index: number) => {
    return (
      <option value={item.value} key={item.value}>
        {item.name}
      </option>
    );
  };

  const _handleChange = (e) => {
    const newValue = e.target.value;
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div>
      {!!label && (
        <Form.Label htmlFor="identity-card-type">
          {label}
          {required && <span className="required"> *</span>}
        </Form.Label>
      )}
      <InputGroup
        className={marginBottom ? "mb-4" : ""}
        hasValidation={!!errorText}
      >
        <Form.Select
          id="identity-card-type"
          value={value}
          onChange={_handleChange}
          isInvalid={!!errorText}
        >
          {!!placeholder && <option value={""}>{placeholder}</option>}
          {!!values && values.map(_renderOption)}
        </Form.Select>
        {!!errorText && (
          <Form.Control.Feedback type="invalid">
            {errorText}
          </Form.Control.Feedback>
        )}
      </InputGroup>
    </div>
  );
};

export default DropdownInput;
