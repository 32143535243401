class AuthInfoService {
  static setChannel(channel: string) {
    localStorage.setItem("channel", channel);
  }

  static getChannel = (): string => {
    return localStorage.getItem("channel") || "";
  };

  static clearInfo() {
    localStorage.removeItem("channel");
  }
}

export default AuthInfoService;
