import Input from "src/components/Input";
import styles from "./VoucherInput.module.scss";
import checkSuccessImg from "src/assets/images/check-success.svg";
import clearRedImg from "src/assets/images/clear-red.svg";
import { useTranslation } from "react-i18next";
import forwarCircleImg from "src/assets/images/forward-circle.svg";
interface VouhcerInputProps {
  isValidVouhcer?: boolean | null;
  label?: string;
  required?: boolean;
  placeholder?: string;
  leftIcon?: string;
  errorText?: string;
  maxLength?: number;
  value?: string;
  onCheckVoucherCode: () => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onClear: () => void;
  showChooseVoucher?: boolean;
  onChooseVoucher?: () => void;
}
const VoucherInput = ({
  isValidVouhcer,
  value,
  onCheckVoucherCode,
  onClear,
  showChooseVoucher,
  onChooseVoucher,
  ...passProps
}: VouhcerInputProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.voucherCodeContainer}>
      {isValidVouhcer === true && (
        <div className={styles.voucherCodeValid}>{value}</div>
      )}
      {isValidVouhcer === true && (
        <img
          src={checkSuccessImg}
          className={styles.rightImg}
          alt="Check success"
        />
      )}
      {isValidVouhcer === false && (
        <img
          src={clearRedImg}
          className={styles.rightImg}
          alt="Clear"
          onClick={onClear}
        />
      )}
      <Input
        value={value}
        {...passProps}
        onBlur={(e) => onCheckVoucherCode()}
        onSubmit={(e) => onCheckVoucherCode()}
        maxLength={20}
        showDefautErrorIcon={!(isValidVouhcer === false)}
        rightLabelComponent={
          showChooseVoucher ? (
            <div
              className={`rowStart ${styles.chooseVoucherContainer}`}
              onClick={onChooseVoucher}
            >
              <div className="body14 textSecondary">
                {t("choose-voucher-code")}
              </div>
              <img
                src={forwarCircleImg}
                className={styles.forwardCircle}
                alt="Forward"
              />
            </div>
          ) : null
        }
      />
    </div>
  );
};

export default VoucherInput;
