import styles from "./CustomerSupport.module.scss";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import aboutProductImg from "src/assets/images/about-product.svg";
import aboutBenefitImg from "src/assets/images/about-benefit.svg";
import aboutFeeImg from "src/assets/images/about-fee.svg";
import forwardImg from "src/assets/images/forward-circle.svg";
import { Link } from "react-router-dom";
const CustomerSupport = () => {
  const { t } = useTranslation();

  const _renderItem = (
    title: string,
    description: string,
    image: string,
    moreUrl: string
  ) => {
    return (
      <Link to={moreUrl} className="linkNoStyle">
        <div className={styles.item}>
          <div className="smallTitle mb-2">{title}</div>
          <div className="textGray mb-4 body18">{description}</div>
          <div className="rowStart pointer">
            <div className="body14 w700 textSecondary">{t("more-info")}</div>
            <img src={forwardImg} alt="Forward" className={styles.forwardImg} />
          </div>
          <img src={image} alt={title} className={styles.aboutImg} />
        </div>
      </Link>
    );
  };

  return (
    <div>
      <div className="rowStart mb-4">
        <div className={`${styles.blockExplainLine} me-3`} />
        <div className={`textBlack2Opacity subTitle`}>
          {t("support-customer")}
        </div>
      </div>
      <div className="appH2 textBlack mb32">{t("answer-question")}</div>
      <Row>
        <Col md className="mb-4">
          {_renderItem(
            t("product"),
            t("about-product-description"),
            aboutProductImg,
            "/support?tab=product"
          )}
        </Col>
        <Col md className="mb-4">
          {_renderItem(
            t("about-benefit"),
            t("about-benefit-description"),
            aboutBenefitImg,
            "/support?tab=benefit"
          )}
        </Col>
        <Col md className="mb-4">
          {_renderItem(
            t("about-fee"),
            t("about-fee-description"),
            aboutFeeImg,
            "/support?tab=fee"
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CustomerSupport;
