import Header from "../Header";
import Footer from "../Footer";
import styles from "../../styles/Layout.module.scss";

interface LayoutProps {
  children: React.ReactNode;
}
const Layout = ({ children }: LayoutProps) => {
  return (
    <div className={styles.container}>
      <Header />
      <main className={styles.main}>{children}</main>
      <Footer />
    </div>
  );
};
export default Layout;
