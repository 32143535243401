import { SupportItemData } from "src/models/SupportItemData";
import Accordion from "react-bootstrap/Accordion";
import styles from "./SupportItem.module.scss";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import UiService from "src/services/UiService";

interface SupportItemProps {
  data: SupportItemData;
}
const SupportItem = ({ data }: SupportItemProps) => {
  const { t } = useTranslation();
  const [isYes, setIsYes] = useState<boolean>();
  const _handleYes = () => {
    setIsYes(true);
    UiService.openPopup({
      showIcon: false,
      title: "",
      content: t("thank-for-your-response"),
      onlyOkButton: true,
      okTitle: t("close"),
      onOk: () => {
        UiService.closePopup();
      },
    });
  };

  const _handleNo = () => {
    setIsYes(false);
    UiService.openPopup({
      showIcon: false,
      title: "",
      content: t("thank-for-your-response"),
      onlyOkButton: true,
      okTitle: t("close"),
      onOk: () => {
        UiService.closePopup();
      },
    });
  };

  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="1">
        <Accordion.Header className={styles.accordionHeader}>
          <div className="smallTitle textBlack">
            <div dangerouslySetInnerHTML={{ __html: data.question || "" }} />
          </div>
        </Accordion.Header>
        <Accordion.Body className={styles.accordionBody}>
          <div className={`body18 textBlack ${styles.answerContainer}`}>
            <div dangerouslySetInnerHTML={{ __html: data.answer || "" }} />
          </div>
          <div className={styles.actionContainer}>
            <div className="smallTitle16 me-4">{t("this-info-useful")}</div>
            <Button
              variant={isYes === true ? "primary" : "light"}
              className={`${styles.button} me-4`}
              onClick={_handleYes}
            >
              {t("yes")}
            </Button>
            <Button
              variant={isYes === false ? "primary" : "light"}
              className={styles.button}
              onClick={_handleNo}
            >
              {t("no")}
            </Button>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
export default SupportItem;
