import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PopupConfirm from "src/components/PopupConfirm";
import couponImg from "src/assets/images/coupon.svg";
import voucherLeftImg from "src/assets/images/voucher-left.svg";
import voucherRightImg from "src/assets/images/voucher-right.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import radioCheckedImg from "src/assets/images/radio-checked.svg";
import radioDefaultImg from "src/assets/images/radio-default.svg";
import styles from "./DiscountPopup.module.scss";
import Discount from "src/models/Discount";
import UiService from "src/services/UiService";
import { useMediaQuery } from "react-responsive";

interface DiscountPopupProps {
  visible: boolean;
  selecetedDiscount?: string;
  data?: Discount[] | null;
  onOk?: (discount?: string) => void;
  onCancel?: () => void;
  onCancelDiscount?: () => void;
}

const DiscountPopup = (props: DiscountPopupProps) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const { visible, selecetedDiscount, onOk, onCancel, onCancelDiscount, data } =
    props;
  const [mainPopupBehind, setMainPopupBehind] = useState<boolean>(false);
  const [voucher, setVoucher] = useState<string | undefined>();
  const { t } = useTranslation();

  useEffect(() => {
    setVoucher(selecetedDiscount);
  }, [selecetedDiscount]);

  // const _getDiscountText = (discount: Discount) => {
  //   if (!discount) return "";
  //   if (discount.calType === "1") {
  //     if (!discount.discountValueMax) {
  //       return Utils.getReplaceText(
  //         t("discount-first-year-detail"),
  //         discount.discountValue
  //       );
  //     }
  //     return Utils.getReplaceTextMulti(
  //       t("discount-first-year-detail-with-limit"),
  //       [
  //         discount.discountValue?.toString(),
  //         discount.discountValueMax?.toString(),
  //       ]
  //     );
  //   } else if (discount.calType === "2") {
  //     return Utils.getReplaceText(
  //       t("discount-first-year-detail-vnd"),
  //       discount.discountValueMax
  //     );
  //   } else if (discount.calType === "3") {
  //     return Utils.getReplaceText(
  //       t("discount-first-year-detail-vnd"),
  //       discount.discountFixedValue
  //     );
  //   }
  //   return "";
  // };

  const _handleViewDetail = (item: Discount) => {
    console.log("_handleViewDetail", item);
    setMainPopupBehind(true);
    const isCurrentDiscount = item.discount === voucher;
    UiService.openPopup({
      title: t("voucher-detail"),
      showIcon: false,
      showClose: true,
      okTitle: isCurrentDiscount ? t("cancel-apply") : t("apply-this-voucher"),
      onlyOkButton: true,
      onOk: () => {
        setMainPopupBehind(false);
        UiService.closePopup();
        if (onOk) {
          if (isCurrentDiscount) {
            onCancelDiscount && onCancelDiscount();
          } else {
            onOk(item.discount);
          }
        }
      },
      onCancel: () => {
        setMainPopupBehind(false);
        UiService.closePopup();
      },
      children: (
        <div className="fullWidth">
          <div className="line mb-3" />
          <div className="appH4 textBlack mb-3">{item.discountName}</div>
          <div className="body14 textBlack mb-3">
            {t("apply-time")}: {item.startDate}-{item.endDate}
          </div>
          <div className="rowStart body14 textBlack italic mb-3">
            {t("promotion-code")}
            <div className={styles.voucherCodeContainer}>{item.discount}</div>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: item.descriptionDetail || "" }}
          />
          <div className="line mt-3" />
        </div>
      ),
    });
  };

  const _handleSelectDiscount = (item: Discount) => {
    setVoucher(item.discount);
  };

  const _renderVoucherItem = (item: Discount, index: number) => {
    const isSelected = voucher === item.discount;
    return (
      <Col
        md
        className={`rowStart mb-3 ${isMobile ? "gx-0" : "gx-3"}`}
        key={item.discount}
      >
        <Row className="flex1">
          <div
            className="rowStart flex1"
            onClick={() => _handleSelectDiscount(item)}
          >
            <div className={styles.voucherLeftRightContainer}>
              <img
                src={voucherLeftImg}
                className={styles.voucherLeft}
                alt="voucherleft"
              />
            </div>
            <div className={styles.couponImgContainer}>
              <img src={couponImg} className={styles.couponImg} alt="coupon" />
            </div>
            <div className={styles.voucherItemMainContent}>
              <img
                src={isSelected ? radioCheckedImg : radioDefaultImg}
                className={styles.radioImg}
                alt={"radio"}
              />
              <div>
                <div className="rowStart body14 textBlack italic mb-1">
                  {t("promotion-code")}
                  <div className={styles.voucherCodeContainer}>
                    {item.discount}
                  </div>
                </div>
                <div className="body16 textBlack italic w600 mb-1">
                  {item.discountName}
                </div>
                <div className="body14 textBlack mb-1">
                  {t("apply")} {item.startDate}-{item.endDate}
                </div>
                <div
                  className={`body14 textBlack w600 textPrimary ${styles.voucherDetailContainer}`}
                  onClick={() => _handleViewDetail(item)}
                >
                  {t("detail")}
                </div>
              </div>
            </div>
            <div className={styles.voucherLeftRightContainer}>
              <img
                src={voucherRightImg}
                className={styles.voucherRight}
                alt="voucherright"
              />
            </div>
          </div>
        </Row>
      </Col>
    );
  };

  return (
    <PopupConfirm
      visible={visible}
      onlyOkButton={true}
      title={t("choose-voucher-code")}
      showIcon={false}
      showClose={true}
      titleClassName={"popupTitle2 textBlack flex1 mb-4 rowStart fullWidth"}
      onOk={() => {
        if (onOk) {
          onOk(voucher);
        }
      }}
      onCancel={() => {
        if (onCancel) {
          onCancel();
        }
      }}
      okTitle={t("confirm")}
      largePopup={true}
      behind={mainPopupBehind}
    >
      <div className="fullWidth">
        <div className="line mb32" />
        <div className="popupBody mb-3">{t("choose-only-one-vouhcer")}</div>

        <Row>
          {data && data.map(_renderVoucherItem)}
          {data && data.length % 2 === 1 && (
            <Col md className={`mb-3 ${isMobile ? "gx-0" : "gx-3"}`} />
          )}
        </Row>
        <div className="line mt32" />
      </div>
    </PopupConfirm>
  );
};

export default DiscountPopup;
