import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import styles from "./PrivacyPolicy.module.scss";
const PrivacyPolicy = () => {
  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col lg={8}>
          <div className={styles.container}>
            <div className="rowCenter text-center appH3 textBlack mb40">
              CHÍNH SÁCH BẢO MẬT TRỰC TUYẾN
            </div>
            <p>
              MB Ageas Life luôn xem trọng sự riêng tư và bảo vệ dữ liệu cá nhân
              của Quý khách. Dữ liệu cá nhân được hiểu là các thông tin cá nhân
              của Quý khách như: tên, ngày sinh và thông tin liên lạc của Quý
              khách. Chúng tôi thu thập dữ liệu cá nhân từ Quý khách để chúng
              tôi có thể cung cấp cho Quý khách sản phẩm và/hoặc dịch vụ mà Quý
              khách có nhu cầu hoặc nhằm mục đích tuân thủ theo các yêu cầu luật
              định. Trong trường hợp Quý khách không thể cung cấp dữ liệu cá
              nhân cần thiết để chúng tôi có thể cung cấp sản phẩm và/hoặc dịch
              vụ của chúng tôi cho Quý khách, rất tiếc là chúng tôi sẽ không thể
              cung cấp cho Quý khách các sản phẩm và/hoặc dịch vụ đó.
            </p>
            <p>
              Chúng tôi có thể thay đổi Chính sách bảo mật trực tuyến này theo
              thời gian và phiên bản mới nhất sẽ được cập nhật trên Website của
              chúng tôi. Quý khách nên thường xuyên kiểm tra Chính sách bảo mật
              trực tuyến trên Website của chúng tôi vì phiên bản Chính sách bảo
              mật trực tuyến được hiển thị trên Website của chúng tôi luôn là
              phiên bản được cập nhật gần nhất.
            </p>
            <div className="smallTitle textBlack">
              I. Các loại dữ liệu cá nhân chúng tôi sẽ thu thập và sử dụng
            </div>
            <p>
              (i) Thông tin cá nhân và thông tin liên lạc, ví dụ như: họ tên,
              nghề nghiệp, thông tin liên lạc và lịch sử liên lạc với MB Ageas
              Life;
            </p>
            <p>(ii) Ngày sinh, giới tính và/hoặc tuổi của Quý khách;</p>
            <p>
              (iii) Quốc tịch, bản sao giấy tờ nhận dạng Quý khách (nếu có liên
              quan đến sản phẩm hoặc dịch vụ);
            </p>
            <ul>
              <li>
                Thông tin chi tiết về chủ hợp đồng bảo hiểm/Bên mua bảo hiểm,
                Người được bảo hiểm và người thụ hưởng của các sản phẩm và/hoặc
                dịch vụ của chúng tôi;
              </li>
              <li>
                Hồ sơ liên lạc của Quý khách với chúng tôi, ví dụ như lịch sử
                các cuộc gọi của Quý khách đến số điện thoại của trung tâm dịch
                vụ khách hàng của chúng tôi và, nếu Quý khách liên lạc với chúng
                tôi bằng các dịch vụ trực tuyến hoặc qua ứng dụng điện thoại
                thông minh của chúng tôi (App), các thông tin như dữ liệu vị trí
                điện thoại di động, địa chỉ IP và địa chỉ MAC;
              </li>
              <li>
                Sản phẩm và dịch vụ Quý khách đã mua từ chúng tôi, cũng như
                những sản phẩm Quý khách quan tâm và đã sở hữu và các phương
                thức thanh toán đã được Quý khách sử dụng;
              </li>
              <li>
                Việc sử dụng các sản phẩm và dịch vụ của chúng tôi, các yêu cầu
                bồi thường bảo hiểm và tình trạng thanh toán các yêu cầu bồi
                thường này (và các chi tiết khác liên quan đến vấn đề này);
              </li>
              <li>
                Phân tích dữ liệu tiếp thị sản phẩm hoặc dịch vụ được thực hiện
                riêng cho Quý khách, bao gồm lịch sử liên lạc và thông tin về
                việc Quý khách có mở các tài liệu này hay nhấp vào đường link
                liên kết;
              </li>
              <li>
                Tình trạng cư trú và/hoặc tình trạng công dân của Quý khách;
              </li>
              <li>
                Tình trạng hôn nhân, gia đình, lối sống hoặc hoàn cảnh xã hội
                của Quý khách (nếu có liên quan đến sản phẩm hoặc dịch vụ).
              </li>
              <li>
                Sự hiểu biết của chúng tôi về các khách hàng mà chúng tôi có
                được qua việc phân tích hoặc lập hồ sơ cá nhân cho các khách
                hàng;
              </li>
              <li>
                Giao dịch của bên thứ ba; chẳng hạn như khi một người không phải
                là chủ tài khoản sử dụng dịch vụ, thông tin về người đó và giao
                dịch;
              </li>
              <li>
                Thông tin thuế (nếu có liên quan đến sản phẩm hoặc dịch vụ).
              </li>
            </ul>
            <div className="smallTitle textBlack">
              II. Cách thức chúng tôi thu thập dữ liệu cá nhân của bạn
            </div>
            Chúng tôi sẽ thu thập dữ liệu cá nhân của Quý khách từ các nguồn
            chung sau:
            <ul>
              <li>
                Thông tin về Quý khách được tạo ra khi Quý khách sử dụng các sản
                phẩm và dịch vụ của chúng tôi;
              </li>
              <li>
                Cookie, dịch vụ định vị, địa chỉ IP khi Quý khách truy cập
                Website hoặc ứng dụng di động của chúng tôi hoặc khi Quý khách
                điền vào biểu mẫu Liên hệ với chúng tôi trên Website hoặc ứng
                dụng trực tuyến của chúng tôi;
              </li>
              <li>
                Các bên thứ ba như công ty bảo hiểm, đại lý, nhà cung cấp, tổ
                chức tài chính, nhân viên y tế, tòa án hoặc hồ sơ thông tin đã
                được công bố công khai;
              </li>
              <li>
                Bảng câu hỏi và thông tin liên lạc chi tiết khi Quý khách tham
                gia khảo sát, hội nghị nhà đầu tư, các buổi hội thảo hoặc khi
                Quý khách cập nhật thông tin liên lạc của Quý khách với chúng
                tôi trên Website của chúng tôi.
              </li>
            </ul>
            <p>
              Chính sách CookieWebsite của chúng tôi sử dụng cookie để phân biệt
              Quý khách với những người dùng khác. Điều này giúp chúng tôi cung
              cấp cho Quý khách trải nghiệm tốt khi Quý khách sử dụng Website và
              cũng cho phép chúng tôi cải thiện Website của mình. Cookie là một
              tệp nhỏ gồm các chữ cái và số mà chúng tôi lưu trữ trên trình
              duyệt hoặc ổ cứng máy tính của Quý khách. Cookie chứa thông tin
              được lưu trữ trên ổ cứng máy tính của Quý khách. Quý khách có khả
              năng chấp nhận hoặc từ chối cookie bằng cách sửa đổi cài đặt trong
              trình duyệt của Quý khách. Nếu Quý khách muốn làm điều này, xin
              vui lòng xem mục trợ giúp trong trình duyệt của Quý khách.
            </p>
            Chúng tôi sử dụng các loại cookie sau:
            <br />
            <br />
            <ul>
              <li>
                Cookie cần thiết cho Website: Đây là các cookie cần phải có để
                phục vụ hoạt động của Website của chúng tôi. Ví dụ, các cookie
                cho phép Quý khách đăng nhập vào Website của chúng tôi một cách
                an toàn;
              </li>
              <li>
                Cookie phân tích/quản lý hoạt động: Các cookie này cho phép
                chúng tôi nhận ra và đếm số lượng người dùng truy cập vào
                Website của chúng tôi và theo dõi cách người dùng truy cập di
                chuyển xung quanh Website của chúng tôi khi họ đang sử dụng nó.
                Điều này giúp chúng tôi cải thiện cách thức hoạt động của
                Website, ví dụ, cải tiến để giúp người dùng tìm kiếm những thứ
                họ cần một cách dễ dàng; và
              </li>
              <li>
                Cookie chức năng: Chúng được sử dụng để nhận ra Quý khách khi
                Quý khách quay lại Website của chúng tôi. Điều này cho phép
                chúng tôi cá nhân hóa nội dung của chúng tôi cho Quý khách, chào
                Quý khách bằng tên và ghi nhớ sở thích của Quý khách (ví dụ: lựa
                chọn ngôn ngữ hoặc khu vực của Quý khách).
              </li>
            </ul>
            <p>
              - Cookie nhắm mục tiêu. Các cookie này ghi lại số lần quý khách
              truy cập website, các trang đã truy cập và các đường dẫn quý khách
              đã tương tác. Chúng tôi sử dụng thông tin này để xây dựng website
              hoặc những nội dung hiển thị trên đó phù hợp với nhu cầu của quý
              khách hơn. Chúng tôi có thể chia sẻ thông tin này cho các đối tác
              thứ ba với cùng lí do như trên; và
            </p>
            <p>
              - Cookie quảng cáo. Những cookie này sẽ ghi nhớ sở thích của quý
              khách để thay đổi các quảng cáo sao cho phù hợp với quý khách nhất
              có thể, ví dụ như là lựa chọn các quảng cáo mà quý khách sẽ cảm
              thấy có nhiều hứng thú nhất, hoặc giới hạn tần suất xuất hiện của
              một loại quảng cáo để đo mức độ hiệu quả. Xin lưu ý là một số
              cookie trên website của chúng tôi được quản lý bởi các bên thứ ba,
              ví dụ như là các đơn vị quảng cáo, các tính năng như video, bản đồ
              và mạng xã hội; và nhà cung cấp các website ngoài luồng như là
              dịch vụ phân tích lượng truy cập trang web. Các cookie này thường
              thì sẽ là cookie phân tích/hoạt động và cookie nhắm mục tiêu. Quý
              khách nên xem qua chính sách cookie và bảo mật riêng của những bên
              thứ ba này để biết thêm về cách họ sẽ sử dụng thông tin của Quý
              khách.
            </p>
            <p>
              Nội dung hiển thị Pop up trong giai đọan 2 của website: Bằng cách
              tiếp tục sử dụng Website của chúng tôi, Quý khách chấp nhận chúng
              tôi sử dụng các loại cookie như đã nêu ở trên.
            </p>
            <div className="smallTitle textBlack">
              III. Cách thức và lý do chúng tôi sử dụng dữ liệu cá nhân của bạn
            </div>
            <p>
              MB Ageas Life và các đối tác kinh doanh sẽ sử dụng dữ liệu cá nhân
              Quý khách cung cấp cho chúng tôi, cùng với các thông tin khác, cho
              các mục đích sau: Mục đíchCơ sở pháp lýQuản lý các sản phẩm và
              dịch vụ của chúng tôi, bao gồm cho phép chúng tôi thực hiện nghĩa
              vụ của chúng tôi với Quý khách và cung cấp mọi dịch vụ liên quan
              như đã thảo luận với Quý khách trước khi Quý khách mua sản phẩm
              hoặc dịch vụCần thiết cho việc thực hiện hợp đồng của chúng tôi
              với Quý khách hoặc để thực hiện các bước trước khi ký hợp đồng với
              Quý khách. Cung cấp dịch vụ khách hàng – như trả lời thắc mắc của
              Quý khách hoặc thông báo cho Quý khách biết các thay đổi.Cần thiết
              cho việc thực hiện hợp đồng của chúng tôi với Quý khách. Sau khi
              hợp đồng chúng tôi ký với Quý khách hoàn tất, đây là lợi ích hợp
              pháp của chúng tôi trong việc duy trì và phát triển mối quan hệ
              của chúng tôi với Quý khách.Tự động ra quyết định hoặc tạo hồ sơ
              cá nhân của Quý khách Lưu giữ thông tin của Quý khách và thực hiện
              các công việc quản lý nội bộ khác.Tuân thủ với các yêu cầu pháp
              quy hoặc pháp lý khác.Tuân thủ với các nghĩa vụ pháp lý của chúng
              tôi.Thiết kế và cung cấp cho Quý khách các sản phẩm và dịch vụ bảo
              hiểm và tài chính liên quan.Lợi ích chính đáng của chúng tôi trong
              việc thiết kế và cải tiến sản phẩm, cung cấp dịch vụ giá trị gia
              tăng, phát triển kinh doanh và hiểu rõ hơn về cách sử dụng sản
              phẩm của chúng tôi. Tiến hành nghiên cứu và phân tích thống kê
              (bao gồm cả việc sử dụng các công nghệ mới).
            </p>
            <p>
              Ngoài ra, MB Ageas Life và các đối tác kinh doanh của chúng tôi,
              sẽ sử dụng dữ liệu cá nhân Quý khách cung cấp cho chúng tôi, cùng
              với các thông tin khác, để gửi cho Quý khách các ưu đãi tiếp thị
              trực tiếp bằng phương tiện điện tử và phi điện tử bao gồm qua
              đường bưu điện, cũng như gửi cho Quý khách thông tin giới thiệu
              các sản phẩm và dịch vụ từ các bên thứ ba đã được chọn lọc kỹ
              lưỡng. Cơ sở pháp lý chúng tôi trình bày bên trên được xây dựng
              dựa trên sự đồng thuận với Quý khách.
            </p>
            <div className="smallTitle textBlack">
              IV. Chúng tôi chia sẻ dữ liệu cá nhân của Quý khách với ai và tại
              sao
            </div>
            <p>
              Chúng tôi sẽ chia sẻ thông tin cá nhân và sức khỏe của Quý khách
              trong nội bộ MB Ageas Life và với các đối tác kinh doanh về tài
              chính/y tế, sức khỏe và các bên thứ ba cung cấp dịch vụ cho chúng
              tôi (bao gồm nhưng không giới hạn các công ty bảo hiểm, tái bảo
              hiểm, luật sư, ngân hàng, kế toán, tổ chức tài chính, bên ủy thác
              và các nhà cung cấp dịch vụ là bên thứ ba khác cung cấp dịch vụ
              quản lý, viễn thông, máy tính, thanh toán, in ấn, mua lại hoặc các
              dịch vụ khác để cho phép chúng tôi thực hiện hoạt động kinh
              doanh), các công ty thẩm định hóa đơn y tế, các công ty thẩm định
              yêu cầu bồi thường, hiệp hội ngành nghề, các bên đồng sở hữu hợp
              đồng bảo hiểm hoặc khoản đầu tư, cố vấn chuyên nghiệp, nhà nghiên
              cứu, tổ chức cung cấp thông tin tín dụng, tổ chức thu hồi nợ, tổ
              chức tài chính/y tế và các bên đối tác để thực hiện các mục đích
              được nêu trong Phần III. Nếu Quý khách có chung hợp đồng bảo hiểm
              với người khác, thì người đó cũng có thể nhận được dữ liệu cá nhân
              của Quý khách. Nếu được yêu cầu, chúng tôi cũng có thể chuyển dữ
              liệu cá nhân của Quý khách cho các cơ quan phòng chống rửa tiền và
              tội phạm tài chính, bất kỳ cơ quan lập pháp, tư pháp hoặc hành
              pháp nào khác theo quy định của Pháp luật Việt Nam.
            </p>
            <p>
              Chúng tôi có thể xử lý dữ liệu cá nhân của Quý khách ở một quốc
              gia khác ngoài quốc gia mà Quý khách cư trú. Trong phạm vi chúng
              tôi chuyển dữ liệu cá nhân của Quý khách, chúng tôi sẽ sử dụng các
              biện pháp bảo vệ phù hợp và tuân thủ luật pháp của quốc gia nơi dữ
              liệu cá nhân của Quý khách được chuyển đến.
            </p>
            <div className="smallTitle textBlack">
              V. Chúng tôi lưu trữ dữ liệu cá nhân của Quý khách trong một
              khoảng thời gian nhất định
            </div>
            <p>
              Dữ liệu cá nhân của Quý khách sẽ được lưu trữ khi Quý khách (hoặc
              đồng sở hữu hợp đồng bảo hiểm với Quý khách) là khách hàng của
              chúng tôi và trong khoảng thời gian sáu năm sau khi kết thúc mối
              quan hệ khách hàng hoặc lâu hơn nếu pháp luật yêu cầu. Có thể có
              những trường hợp cụ thể khi chúng tôi cần lưu giữ dữ liệu cá nhân
              của Quý khách lâu hơn (Ví dụ như khi xảy ratranh chấp).
            </p>
            <div className="smallTitle textBlack">VI. Kiểm tra tham khảo</div>
            <p>
              Đối với một số sản phẩm nhất định, chúng tôi có thể sử dụng các tổ
              chức cung cấp thông tin tín dụng, công ty hỗ trợ tìm kiếm thông
              tin, cơ quan phòng chống rửa tiền và tội phạm tài chính hoặc thông
              tin có sẵn công khai để giúp chúng tôi kiểm tra danh tính của Quý
              khách, cũng như để tránh gian lận và rửa tiền; điều này có thể bao
              gồm kiểm tra các địa chỉ hiện tại hoặc trước đây của Quý khách.
              Những kết quả này có thể được ghi lại để tham khảo trong tương
              lai. Những kết quả kiểm tra này cũng có thể được sử dụng cho một
              nhà đầu tư, chủ sở hữu hợp đồng chung hoặc người mà Quý khách đồng
              ý cung cấp dữ liệu cá nhân của mình. Nếu chúng tôi mất liên lạc
              với Quý khách, chúng tôi có thể sử dụng các tổ chức này để xác
              minh địa chỉ của Quý khách nhằm giúp chúng tôi liên lạc với Quý
              khách.
              <br />
              <br />
              Mọi việc chuyển dữ liệu cá nhân của Quý khách sẽ luôn được thực
              hiện an toàn.
            </p>
            <div className="smallTitle textBlack">
              VII. Chúng tôi có thể sử dụng dữ liệu cá nhân của bạn để đưa ra
              quyết định tự động hoặc tạo hồ sơ cá nhân của bạn
            </div>
            <p>
              MB Ageas Life, Đối tác kinh doanh và Đối tác tiếp thị của chúng
              tôi có thể sử dụng dữ liệu cá nhân của Quý khách để đưa ra quyết
              định tự động đối với Quý khách hoặc tạo hồ sơ cá nhân khác cho Quý
              khách (ví dụ: hồ sơ tiếp thị).
            </p>
            <div className="smallTitle textBlack">
              VIII. Quyền kiểm soát dữ liệu cá nhân của bạn
            </div>
            <p>
              Khi nói đến cách chúng tôi sử dụng dữ liệu cá nhân của Quý khách,
              Quý khách có quyền theo quy định của pháp luật Việt Nam. Theo đó,
              Quý khách có thể:
            </p>
            <ul>
              <li>
                Yêu cầu một bản sao dữ liệu cá nhân của Quý khách miễn phí
                (chúng tôi có thể tính phí yêu cầu của Quý khách nếu không có cơ
                sở rõ ràng hoặc bị lạm dụng quá mức);
              </li>
              <li>
                Trong một số trường hợp nhất định, chúng tôi sẽ chuyển dữ liệu
                cá nhân của Quý khách sang một tổ chức khác theo yêu cầu của Quý
                khách;
              </li>
              <li>
                Yêu cầu chúng tôi điều chỉnh bất cứ dữ liệu cá nhân nào bị sai
                hoặc không đầy đủ;
              </li>
              <li>
                Yêu cầu chúng tôi xóa dữ liệu cá nhân của Quý khách nếu không
                còn cần thiết cho các mục đích được nêu tại Chính sách này hoặc
                nếu không có cơ sở pháp lý nào khác để xử lý dữ liệu;
              </li>
              <li>
                Giới hạn cách chúng tôi sử dụng dữ liệu cá nhân của Quý khách
                hoặc rút lại sự đồng ý (bao gồm cả việc đồng ý cho phép đưa ra
                quyết định tự động dựa trên dữ liệu cá nhân) mà Quý khách đã
                chấp thuận cho phép chúng tôi xử lý dữ liệu cá nhân của Quý
                khách;
              </li>
              <li>
                Phản đối chúng tôi sử dụng dữ liệu cá nhân của Quý khách để tiếp
                thị trực tiếp (bao gồm cả việc tạo lập hồ sơ cá nhân của Quý
                khách) hoặc xử lý dữ liệu khác dựa trên lợi ích hợp pháp;
              </li>
              <li>
                Nếu Quý khách muốn thực hiện các quyền của mình hoặc muốn giải
                thích về các quyền này, Quý khách có thể liên lạc với chúng tôi
                trong phần Liên hệ.
              </li>
              <li>
                Chúng tôi có thể theo dõi hoặc ghi lại các cuộc gọi hoặc bất kỳ
                liên lạc nào khác mà chúng tôi có với Quý khách. Điều này có thể
                là để đào tạo, bảo mật hoặc để giúp chúng tôi nâng cao chất
                lượng dịch vụ
              </li>
            </ul>
            <div className="smallTitle textBlack">
              IX. Thay mặt người khác cung cấp dữ liệu cá nhân của họ
            </div>
            <p>
              Khi Quý khách cung cấp cho chúng tôi dữ liệu cá nhân về người khác
              (hoặc nhiều người khác), Quý khách cần được chỉ định và ủy quyền
              bởi người đó để hành động thay họ. Điều này bao gồm cung cấp sự
              đồng ý để:
            </p>
            - Chúng tôi xử lý dữ liệu cá nhân của họ
            <br />- Quý khách sẽ nhận được các thông báo bảo vệ thông tin thay
            mặt họ.
            <p>
              Nếu vì bất kỳ lý do nào Quý khách quan tâm đến việc liệu Quý khách
              có được phép cung cấp cho chúng tôi thông tin về người khác hay
              không, vui lòng liên hệ với chúng tôi theo địa chỉ thư điện tử ở
              phần XI trước khi gửi cho chúng tôi bất cứ thông tin gì.
            </p>
            <div className="smallTitle textBlack">X. Tiếp thị trực tiếp</div>
            <p>
              MB Ageas Life, đối tác kinh doanh của chúng tôi và đối tác tiếp
              thị có thể sẽ gửi cho Quý khách thông tin qua hình thức các bài
              đăng về các sản phẩm và dịch vụ của MB Ageas Life và các bên thứ
              ba được lựa chọn cẩn thận.
            </p>
            <p>
              Ngoài ra, theo thời gian, MB Ageas Life mong muốn gửi thông tin
              chi tiết về sản phẩm, dịch vụ và các ưu đãi đặc biệt cho Quý khách
              qua các phương tiện điện tử. Chúng tôi sẽ chỉ làm điều này nếu có
              sự đồng ý của Quý khách.
            </p>
            <p>
              Và Quý khách có quyền thay đổi ý định và/hoặc từ chối nhận tiếp
              thị trực tiếp bằng cách thông báo/liên lạc với chúng tôi.
            </p>
            <div className="smallTitle textBlack">
              XI. Liên hệ với chúng tôi
            </div>
            <p>
              Nếu Quý khách muốn thực hiện các quyền của mình hoặc nếu Quý khách
              yêu cầu bất kỳ thông tin nào theo thông báo này, Quý khách có thể
              liên hệ với chúng tôi theo nhiều cách khác nhau.
            </p>
            <p>- Gọi cho đường dây nóng của chúng tôi: 024 2229 8888</p>
            <p>
              - Gửi thư điện tử cho chúng tôi theo địa chỉ: dvkh@mbageas.life
            </p>
            <p>
              - Hoặc liên hệ trực tiếp với chúng tôi tại văn phòng:
              <ul>
                <li>
                  Trụ sở chính: Tầng 15, Tòa nhà 21 Cát Linh, phường Cát Linh,
                  quận Đống Đa, Hà Nội.
                  <br />
                  Điện thoại: 024 2229 6666
                </li>
                <li>
                  Hoặc các văn phòng chi nhánh của chúng tôi trên toàn quốc.
                </li>
              </ul>
            </p>
            <div className="w700 italic">Ghi chú:</div>
            <p>
              Các đối tác kinh doanh nghĩa là các đối tác cung cấp dịch vụ, kế
              toán, kiểm toán, các đối tác cung cấp dịch vụ nền tảng công nghệ
              thông tin, đối tác trung gian, các công ty tái báo hiểm, quản lý
              quỹ đầu tư, đại lý, ủy thác hưu trí (và các bên liên quan khác),
              cố vấn kế hoạch, tham vấn, nhà cung cấp sản phẩm bảo hiểm và tài
              chính được chọn, và cố vấn pháp lý của chúng tôi. Đối tác tiếp thị
              nghĩa là các nhà cung cấp dịch vụ, trung gian, ủy thác hưu trí (và
              các bên liên quan khác), cố vấn kế hoạch, tham vấn, nhà cung cấp
              sản phẩm bảo hiểm và tài chính được chọn.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
