import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Input from "src/components/Input";
import closeGrayImg from "src/assets/images/close-gray.svg";
import styles from "./ContractManagement.module.scss";
import { searchInquiryPolicy } from "src/api";
import UiService from "src/services/UiService";
import InquiryPolicy from "src/models/InquiryPolicy";
import Utils from "src/utils/Utils";
import { useHistory } from "react-router";
import DateInput from "src/components/DateInput";
import DropdownInput from "src/components/DropdownInput";
import { POLICY_STATUS_VALUES, POLICY_STATUS } from "src/Constants";
import { useMediaQuery } from "react-responsive";

const ContractManagement = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [keyword, setKeyword] = useState("");
  const [inquiryPolicies, setInquiryPolicies] = useState<InquiryPolicy[]>();
  const [fromDate, setFromDate] = useState<Date>();
  const [errFromDate, setErrFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<Date>();
  const [errToDate, setErrToDate] = useState<string>("");
  const [filterStatus, setFilterStatus] = useState<string>("");
  const [sortIssueDate, setSortIssueDate] = useState<"asc" | "desc" | "none">(
    "none"
  );

  const isUnderLg = useMediaQuery({
    query: "(max-width: 992px)",
  });

  useEffect(() => {
    _search();
    return () => {
      UiService.hideLoading();
    };
  }, []);

  const _handleChangeKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const _search = async () => {
    try {
      UiService.showLoading();
      const searchRes = await searchInquiryPolicy(
        keyword,
        fromDate ? Utils.formatDate(fromDate, t("date-format2")) : null,
        toDate ? Utils.formatDate(toDate, t("date-format2")) : null
      );
      console.log("searchRes", searchRes);
      setInquiryPolicies(searchRes?.data?.list);
      UiService.hideLoading();
    } catch (err: any) {
      UiService.hideLoading();
      console.log("searchInquiryPolicy err", err);
      if (err?.response?.data?.code === 400) {
        setInquiryPolicies([]);
        setFilterStatus("");
        if (err?.response?.data?.error_code !== "MSG16") {
          UiService.openPopup({
            content: err?.response?.data?.error_data?.messsage,
            onlyOkButton: true,
            onOk: () => {
              UiService.closePopup();
            },
          });
        }
      }
    }
  };

  const _handleSearch = async () => {
    console.log("Search");
    if (fromDate && toDate && fromDate.getTime() > toDate.getTime()) {
      setErrFromDate(t("from-date-must-not-larger-than-to-date"));
      return;
    }
    _search();
  };

  const _handleClearKeyword = () => {
    setKeyword("");
    setFilterStatus("");
  };

  const _handleChangeFromDate = (value) => {
    setFromDate(value);
    setErrFromDate("");
    setErrToDate("");
    if (value && toDate && value.getTime() > toDate.getTime()) {
      setErrFromDate(t("from-date-must-not-larger-than-to-date"));
      return;
    }
  };

  const _handleChangeToDate = (value) => {
    setToDate(value);
    setErrFromDate("");
    setErrToDate("");
    if (fromDate && value && fromDate.getTime() > value.getTime()) {
      setErrFromDate(t("from-date-must-not-larger-than-to-date"));
      return;
    }
  };

  const _handleChangeFilterStatus = (value) => {
    console.log("Change filter status", value);
    setFilterStatus(value);
  };

  const _handleKeyDownKeyword = (e) => {
    if (e.key === "Enter") {
      _handleSearch();
    }
  };

  // const _handleBlueKeyword = (e) => {
  //   _handleSearch();
  // };

  const _renderSearchBar = () => {
    return (
      <>
        <Row>
          <Col lg={3} sm={6} className="gx-2 mb-4">
            <DateInput
              placeholder={t("issue-date-placeholder")}
              label={t("from-date")}
              value={fromDate}
              onChange={_handleChangeFromDate}
              errorText={errFromDate}
              maximumDate={Utils.convertDateToDay(new Date())}
              marginBottom={false}
              showClear={true}
            />
          </Col>
          <Col lg={3} sm={6} className="gx-2 mb-4">
            <DateInput
              placeholder={t("issue-date-placeholder")}
              label={t("to-date")}
              value={toDate}
              onChange={_handleChangeToDate}
              errorText={errToDate}
              maximumDate={Utils.convertDateToDay(new Date())}
              marginBottom={false}
              showClear={true}
            />
          </Col>
          <Col lg={6} sm={12} className="gx-2 mb-4">
            <div className="rowAllStart">
              <div className="flex1 me-3">
                <div>
                  <Input
                    label={isUnderLg ? undefined : "---"}
                    labelClassName={"opacity0"}
                    placeholder={t("contract-keyword-placeholder")}
                    maxLength={500}
                    value={keyword}
                    onChange={_handleChangeKeyword}
                    marginBottom={false}
                    rightComponent={
                      !!keyword ? (
                        <img
                          src={closeGrayImg}
                          alt="Close gray"
                          className={styles.clearImg}
                          onClick={_handleClearKeyword}
                        />
                      ) : null
                    }
                    onKeyDown={_handleKeyDownKeyword}
                  />
                  <div className={"helpText italic"}>
                    {t("contract-keyword-hint")}
                  </div>
                </div>
              </div>
              <div className={isUnderLg ? "" : styles.searchButtonContainer}>
                <Button variant="primary" onClick={_handleSearch}>
                  {t("search")}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        {inquiryPolicies && inquiryPolicies.length > 0 && (
          <Row className="mb-4">
            <DropdownInput
              label={t("status")}
              placeholder={t("policy-status-placeholder")}
              values={POLICY_STATUS_VALUES.map((item) => ({
                ...item,
                name: t(item.name),
              }))}
              value={filterStatus}
              onChange={_handleChangeFilterStatus}
              marginBottom={false}
            />
          </Row>
        )}
      </>
    );
  };

  const _handleViewDetail = (item: InquiryPolicy) => {
    history.push("/contract-detail", item);
  };

  const _renderPolicyItem = (item: InquiryPolicy, index: number) => {
    return (
      <tr
        className="font14"
        key={item.policyNumber}
        onClick={() => _handleViewDetail(item)}
      >
        <td>{item.appNo}</td>
        <td>{item.policyNumber}</td>
        <td>{item.fullname}</td>
        <td>
          <div>{item.productName}</div>
          <div>{item.sumAssured}</div>
        </td>
        <td>
          <div
            className={
              item.policyStatus === POLICY_STATUS.ACTIVE
                ? "textSuccess"
                : "textGray"
            }
          >
            {t(Utils.getPolicyStatusText(item.policyStatus))}
          </div>
        </td>
        <td>{item.premium}</td>
        <td>{item.issueDate}</td>
      </tr>
    );
  };

  const _handleClickSort = () => {
    if (sortIssueDate === "none") {
      setSortIssueDate("asc");
    } else if (sortIssueDate === "asc") {
      setSortIssueDate("desc");
    } else {
      setSortIssueDate("asc");
    }
  };

  const _renderResultList = () => {
    if (inquiryPolicies?.length === 0) {
      return (
        <div className="body16 textGray text-center mt-3">{t("no-result")}</div>
      );
    }
    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>{t("inquiry-number")}</th>
            <th>{t("contract-number")}</th>
            <th>{t("bmbh-full")}</th>
            <th>{t("product")}</th>
            <th>{t("status")}</th>
            <th>{t("term-fee")}</th>
            <th>
              <div className="rowStart">
                {t("issued-date")}
                <div className={styles.sortContainer}>
                  <div
                    className={
                      sortIssueDate === "asc"
                        ? styles.sortingAsc
                        : sortIssueDate === "desc"
                        ? styles.sortingDesc
                        : styles.sorting
                    }
                    onClick={_handleClickSort}
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {inquiryPolicies
            ?.filter((item) => {
              if (!filterStatus) return true;
              return item.policyStatus === filterStatus;
            })
            ?.sort((a, b) => {
              if (sortIssueDate === "asc") {
                return (
                  Utils.covertToDate(a.issueDate).getTime() -
                  Utils.covertToDate(b.issueDate).getTime()
                );
              } else {
                return (
                  Utils.covertToDate(b.issueDate).getTime() -
                  Utils.covertToDate(a.issueDate).getTime()
                );
              }
            })
            .map(_renderPolicyItem)}
        </tbody>
      </Table>
    );
  };

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col lg={12} xl={9} className="pt-4 pb-4">
          {_renderSearchBar()}
          {_renderResultList()}
        </Col>
      </Row>
    </Container>
  );
};

export default ContractManagement;
