import { useCallback } from "react";
import InsurancePackage from "src/models/InsurancePackage";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./InsurancePackageChooser.module.scss";
import radioCheckedImg from "src/assets/images/radio-checked.svg";
import radioDefaultImg from "src/assets/images/radio-default.svg";
import { useTranslation } from "react-i18next";

interface InsurancePackageChooserProps {
  data: InsurancePackage[];
  selectedPackage?: string | null;
  onChangeSelectedPackage: (value: string) => void;
}
const InsurancePackageChooser = ({
  data,
  selectedPackage,
  onChangeSelectedPackage,
}: InsurancePackageChooserProps) => {
  const { t } = useTranslation();
  const _handleChoosePackage = useCallback(
    (item: InsurancePackage) => {
      onChangeSelectedPackage(item.id);
    },
    [onChangeSelectedPackage]
  );

  const _renderItem = useCallback(
    (item: InsurancePackage, index: number) => {
      const isSelected = selectedPackage === item.id;
      return (
        <Col key={item.id} sm className="gx-2">
          <div
            className={isSelected ? styles.itemActive : styles.itemInactive}
            onClick={() => _handleChoosePackage(item)}
          >
            <img
              src={isSelected ? radioCheckedImg : radioDefaultImg}
              alt={"Radio "}
              className={styles.radioImg}
            />
            <div>
              <div
                className={`body16 w600 mb-1 ${
                  isSelected ? "textPrimary" : "textBlack"
                }`}
              >
                {t("package")} {item.insamountTxt} {t("dong")}
              </div>
              <div
                className={`body14 mb-1 ${
                  isSelected ? "textPrimary" : "textBlack"
                }`}
              >
                {item.premiumAmTxt}/{t("yearlc")}
              </div>
              <div className={`body14 textGray`}>
                {item.paymentPeriodTxt || t("yearly-payment")}
              </div>
            </div>
          </div>
        </Col>
      );
    },
    [selectedPackage, t, _handleChoosePackage]
  );

  return <Row>{data.map(_renderItem)}</Row>;
};

export default InsurancePackageChooser;
