import { gaTrackEvent } from "src/api/ga-tracking";
import InsurancePackage from "src/models/InsurancePackage";
import McreditFormInfo from "./McreditFormInfo";

class McreditFormService {
  private static mcreditFormInfo?: McreditFormInfo;
  static frontIdCard?: File;
  static backIdCard?: File;
  static signature?: File;
  static insurancePackages?: InsurancePackage[];

  static saveMcreditFormInfo(data: any) {
    const savedFormInfo = this.getMcreditFormInfo();
    if (!this.mcreditFormInfo && savedFormInfo) {
      this.mcreditFormInfo = savedFormInfo;
    }
    this.mcreditFormInfo = {
      ...this.mcreditFormInfo,
      ...data,
    };
    sessionStorage.setItem(
      "mcredit_form_info",
      JSON.stringify(this.mcreditFormInfo)
    );
  }
  static getMcreditFormInfo = (): McreditFormInfo | null => {
    if (this.mcreditFormInfo) return this.mcreditFormInfo;
    const mcreditFormInfoStr = sessionStorage.getItem("mcredit_form_info");
    if (!mcreditFormInfoStr) return null;
    try {
      return JSON.parse(mcreditFormInfoStr) as McreditFormInfo;
    } catch (err) {
      gaTrackEvent(
        "TRACKING_OTP",
        "getMcreditFormInfo",
         mcreditFormInfoStr
      );
      console.log("Parse form info err", err);
      return null;
    }
  };

  static saveInsuracePackages = (packages: InsurancePackage[]) => {
    McreditFormService.insurancePackages = packages;
    sessionStorage.setItem(
      "mcredit_insurance_packages",
      JSON.stringify(packages)
    );
  };

  static getInsurancePackages = (): InsurancePackage[] | null => {
    if (McreditFormService.insurancePackages) {
      return McreditFormService.insurancePackages!;
    }
    const insurancePackagesStr = sessionStorage.getItem(
      "mcredit_insurance_packages"
    );
    if (!insurancePackagesStr) return null;
    try {
      McreditFormService.insurancePackages = JSON.parse(
        insurancePackagesStr
      ) as InsurancePackage[];
      return McreditFormService.insurancePackages;
    } catch (err) {
      gaTrackEvent(
        "TRACKING_OTP",
        "getInsurancePackages",
        JSON.stringify(err), 
      );
      console.log("Parse insuracePackage err", err);
      return null;
    }
  };

  static reset = () => {
    sessionStorage.removeItem("mcredit_form_info");
    sessionStorage.removeItem("mcredit_insurance_packages");
    this.mcreditFormInfo = undefined;
    this.frontIdCard = undefined;
    this.backIdCard = undefined;
    this.signature = undefined;
    this.insurancePackages = undefined;
  };
}

export default McreditFormService;
