import { useEffect, useRef, useState, Key } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import styles from "./OtpPopup.module.scss";
import { OTP_LENGTH, OTP_TIME } from "../../Constants";
import { useMediaQuery } from "react-responsive";
import closeBlueImg from "../../assets/images/close-blue.svg";
import refreshImg from "../../assets/images/refresh-blue.svg";
export interface OtpPopupProps {
  visible?: boolean;
  title?: string;
  onOk?: () => void;
  onCancel?: () => void;
  phone?: string;
  value?: string;
  onChange?: (value: string) => void;
  onResend?: () => void;
  errOtp?: string;
  actionText?: string;
}

const OtpPopup = ({
  visible,
  onOk,
  onCancel,
  title,
  phone,
  value,
  onChange,
  onResend,
  errOtp,
  actionText,
}: OtpPopupProps) => {
  const inputRef = useRef<any[]>([]);
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const { t } = useTranslation();
  const [remainTime, setRemainTime] = useState(OTP_TIME);

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [visible]);

  useEffect(() => {
    let intervalId = setInterval(() => {
      if (remainTime > 0) {
        setRemainTime(remainTime - 1);
      } else {
        clearInterval(intervalId);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [remainTime]);

  if (!visible) return <div />;

  const _handleFocus = (e, i: number) => {
    let currentValue = _getCurrentValue();
    if (i > currentValue.length) {
      inputRef.current[currentValue.length]?.focus();
    }
  };

  const _getCurrentValue = () => {
    return inputRef.current
      .map((item) => item.value)
      .filter((item) => !!item)
      .join("")
      .replace(/\D/g, "");
  };

  const _triggerOnChange = () => {
    const currentValue = _getCurrentValue();
    if (onChange && value !== currentValue) {
      onChange(currentValue);
    }
  };

  const _handleChangeOtpItem = (e, i: number) => {
    if (e.target.value.length >= 1 && i < OTP_LENGTH - 1) {
      inputRef.current[i + 1]?.focus();
    }
    _triggerOnChange();
  };

  const _handleKeyUp = (e, i: number) => {
    if (i > 0 && e.keyCode === 8) {
      inputRef.current[i - 1]?.focus();
    }
    _triggerOnChange();
  };

  const _handleKeyDown = (e) => {
    let key = e.keyCode || Number(e.key);
    if (!((key >= 48 && key <= 57) || (key >= 96 && key <= 105) || key === 8)) {
      e.preventDefault();
    }
  };

  const _renderOtpInput = () => {
    const listItem: any[] = [];
    for (let i = 0; i < OTP_LENGTH; i++) {
      listItem.push(
        <div
          key={i}
          className={
            (i < OTP_LENGTH - 1
              ? styles.otpInputContainer
              : styles.otpInputContainerLast) +
            (errOtp ? " " + styles.otpInputContainerErr : "")
          }
        >
          <input
            autoFocus={i === 0}
            className={errOtp ? styles.otpInputItemErr : styles.otpInputItem}
            maxLength={1}
            placeholder={"-"}
            type={"text"}
            inputMode={"numeric"}
            ref={(el) => (inputRef.current[i] = el)}
            onChange={(e) => _handleChangeOtpItem(e, i)}
            onKeyUp={(e) => _handleKeyUp(e, i)}
            onFocus={(e) => _handleFocus(e, i)}
            onKeyDown={_handleKeyDown}
          />
        </div>
      );
    }
    return <>{listItem}</>;
  };

  const _handleResend = () => {
    if (onResend && remainTime <= 0) {
      onResend();
    }
  };

  return (
    <Modal show={visible} centered>
      <div className={styles.contentContainer}>
        <div className="rowStart fullWidth mb-4">
          <div className="popupTitle2 textBlack flex1">{t("confirm-otp")}</div>
          <img
            src={closeBlueImg}
            width={isMobile ? 32 : 56}
            height={isMobile ? 32 : 56}
            alt="close"
            onClick={onCancel}
          />
        </div>

        <div className={styles.popupChilren}>
          <div className="popupBody textBlack text-center mb32">
            {t("input-otp-hint")}
            <div className="bold textPrimary">{phone}</div>
          </div>
          <div className="mb32">
            <div className="rowCenter">{_renderOtpInput()}</div>
            {!!errOtp && <div className="invalidText rowCenter">{errOtp}</div>}
          </div>
          <div className="rowCenter">
            <div
              className="rowStart"
              onClick={_handleResend}
              style={{
                opacity: remainTime <= 0 ? 1 : 0.5,
                cursor: remainTime <= 0 ? "pointer" : "auto",
              }}
            >
              <div className={styles.refrehsContainer}>
                <img src={refreshImg} width={24} height={24} alt="refresh" />
              </div>
              <div className="smallTitle14 textPrimary fontSourceSansPro">
                {t("resend-otp")}{" "}
                {remainTime > 0 ? (
                  <span>
                    (<span className={styles.remainTime}>{remainTime}</span>{" "}
                    {t("second")})
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <Button variant="primary" onClick={onOk} className="flex1">
          {actionText || t("finish")}
        </Button>
      </div>
    </Modal>
  );
};

export default OtpPopup;
