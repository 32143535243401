import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "src/styles/BuyInsurance.module.scss";
import { useTranslation } from "react-i18next";
import InsuranceItem from "src/components/InsuranceItem";
import { BUY_INSURANCE_STEP_DATA } from "src/Constants";
import HorizontalStepper from "src/components/HorizontalStepper";
import UiService from "src/services/UiService";
import { useHistory } from "react-router-dom";
import Item from "src/models/Item";
import McreditFormService from "../McreditFormService";
import { useMediaQuery } from "react-responsive";

const HealthQuestion = () => {
  const isLargeDevice = useMediaQuery({
    query: "(min-width: 992px)",
  });
  const history = useHistory();
  const { t } = useTranslation();
  const [yes, setYes] = useState<boolean>();
  const [questionData, setQuestionData] = useState<Item[]>();

  useEffect(() => {
    if (McreditFormService.getMcreditFormInfo()?.noHealthProblem === true) {
      setYes(false);
    }
    const cancerQuestionStr = localStorage.getItem("cancer_question");
    if (!cancerQuestionStr) return;
    try {
      setQuestionData(JSON.parse(cancerQuestionStr));
    } catch (err) {
      console.log("Parse cancer question err", err);
    }
  }, []);

  const _handleContinue = () => {
    if (typeof yes === "undefined") {
      UiService.openPopup({
        onlyOkButton: true,
        okTitle: t("agree"),
        content: t("please-answer-health-question"),
        onCancel: () => {
          UiService.closePopup();
        },
        onOk: () => {
          UiService.closePopup();
        },
      });
      return;
    }
    if (yes === true) {
      UiService.openPopup({
        showContactNow: true,
        content: t("sorry-reject-insurance-request"),
        onCancel: () => {
          UiService.closePopup();
        },
        onOk: () => {
          UiService.closePopup();
        },
      });
      return;
    }
    McreditFormService.saveMcreditFormInfo({ noHealthProblem: true });
    history.push("/merchant/sell-insurance/cancer/confirm-info");
  };

  const _handleGoBack = () => {
    history.goBack();
  };

  const _renderQuestionItem = (item: Item, index: number) => {
    return (
      <div key={item.name}>
        <div className="subTitle textBlack mb-2">
          {t("question")} {item.id}
        </div>
        <div className="body18 textBlack mb-2">{item.name}</div>
        <div className="line mt-3 mb-3" />
      </div>
    );
  };

  const _handleYes = () => {
    setYes(true);
  };

  const _handleNo = () => {
    setYes(false);
  };

  const _renderQuestion = () => {
    return (
      <div className="mb-lg-0 mb-4">
        <Row className="mb-4">
          <Col sm md={8} className="d-flex flex-column justify-content-center">
            <div className="appH3 textBlack">{t("health-question")}</div>
          </Col>
        </Row>

        <div className={styles.infoForm}>
          {!!questionData && questionData.map(_renderQuestionItem)}
          <div className="rowStart">
            <Button
              variant={yes === true ? "primary" : "light"}
              className="me-3 flex1"
              onClick={_handleYes}
            >
              {t("yes")}
            </Button>
            <Button
              variant={yes === false ? "primary" : "light"}
              className="flex1"
              onClick={_handleNo}
            >
              {t("no")}
            </Button>
          </div>
        </div>
        {isLargeDevice && _renderButton()}
      </div>
    );
  };

  const _renderButton = () => {
    return (
      <div className="rowStart">
        <Button variant="light" onClick={_handleGoBack} className="flex1 me-3">
          {t("go-back")}
        </Button>
        <Button variant="primary" onClick={_handleContinue} className="flex1">
          {t("continue")}
        </Button>
      </div>
    );
  };

  const _renderInsuranceItem = () => {
    return (
      <div className="mb-3">
        <Row className="mb-4">
          <Col sm className="d-flex flex-column justify-content-center">
            <div className="appH3 textBlack">{t("choosed-product")}</div>
          </Col>
        </Row>
        <InsuranceItem
          data={McreditFormService.getInsurancePackages()?.find(
            (item) =>
              item.id ===
              McreditFormService.getMcreditFormInfo()?.selectedPackage
          )}
          discount={McreditFormService.getMcreditFormInfo()?.applyingDiscount}
          beforeDiscount={
            McreditFormService.getMcreditFormInfo()?.beforeDiscount
          }
          discountAmt={McreditFormService.getMcreditFormInfo()?.discountAmt}
          afterDiscount={McreditFormService.getMcreditFormInfo()?.afterDiscount}
        />
      </div>
    );
  };

  return (
    <div className={styles.pageContainer}>
      <Row className={`justify-content-center ${styles.stepper}`}>
        <Col lg={8}>
          <div className="rowCenter">
            <HorizontalStepper data={BUY_INSURANCE_STEP_DATA} step={1} />
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={5}>{_renderQuestion()}</Col>
        <Col lg={3}>{_renderInsuranceItem()}</Col>
        {!isLargeDevice && <Col>{_renderButton()}</Col>}
      </Row>
    </div>
  );
};

export default HealthQuestion;
