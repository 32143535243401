import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className="container">
        {/* <Row>
          <Col>
            <span className={styles.footerCopyright}>
              © 2021<span className={styles.footerMBAL}>&nbsp;MBAL.&nbsp;</span>
              All rights reserved.
            </span>
          </Col>
        </Row> */}
        <div className="row">
          <div className={`col-sm ${styles.footerCopyright}`}>
            <span>
              © 2021<span className={styles.footerMBAL}> MBAL. </span>All rights
              reserved.
            </span>
          </div>

          <div className={`col-sm ${styles.faqContainer}`}>
            <Link to="/support" className={styles.footerLink}>
              <div>FAQs</div>
            </Link>

            <Link to="/term-of-use" className={styles.footerLink}>
              <div>Terms of use</div>
            </Link>
            <Link to="/privacy-policy" className={styles.footerLink}>
              <div>Privacy Policy</div>
            </Link>
          </div>
          <div className="col-sm"></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
