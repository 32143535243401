import styles from "./HorizontalStepper.module.scss";
import { useTranslation } from "react-i18next";
import checkCirclePinkImg from "../../assets/images/check-circle-pink.svg";
import lineImg from "../../assets/images/line.svg";
export interface HorizontalStepperItem {
  icon: string;
  name: string;
}

interface HorizontalStepperProps {
  data: Array<HorizontalStepperItem>;
  step: number;
}

const HorizontalStepper = ({ data, step }: HorizontalStepperProps) => {
  const { t } = useTranslation();
  const _renderItem = (item: HorizontalStepperItem, index: number) => {
    const showArrow = index < data.length - 1;
    let itemIconClassName = "";
    let textClassName = "textBlack";
    if (index === step) {
      itemIconClassName = "bgSecondary";
    } else if (index < step) {
      itemIconClassName = "bgPrimary";
    } else {
      itemIconClassName = "bgGray3";
      textClassName = "textGray";
    }

    return (
      <div className="rowStart" key={item.name}>
        <div className="position-relative">
          {index < step && (
            <div className={styles.checkedIconContainer}>
              <img
                width={20}
                height={20}
                src={checkCirclePinkImg}
                alt={"check"}
              />
            </div>
          )}
          <div className={`${styles.iconContainer} ${itemIconClassName}`}>
            <img width={24} height={24} src={item.icon} alt={item.name} />
          </div>
        </div>

        <div className={`body18 ${textClassName} ${styles.name}`}>
          {t(item.name)}
        </div>
        {showArrow && (
          <div className={styles.arrowContainer}>
            <img
              width={32}
              height={32}
              src={lineImg}
              alt={item.name}
              className={styles.arrow}
            />
          </div>
        )}
      </div>
    );
  };

  return <div className={styles.container}>{data.map(_renderItem)}</div>;
};

export default HorizontalStepper;
