import { get, post } from "./common";

export const getCategory = (productCode: string) => {
  return get({ url: "/api/frontend/category", params: { productCode } });
};

export const getHomeOnlineProduct = () => {
  return get({ url: "/api/frontend/home/get-product-onlines-on-home" });
};

export const getAllProduct = (productType: string) => {
  return get({
    url: "/api/frontend/home/get-all-products",
    params: productType ? { productType } : null,
  });
};

export const checkVoucher = (voucherCode: string) => {
  return get({ url: "/api/frontend/free-cancer/" + voucherCode });
};

export const calculatePremiumFreeCancer = (params: any) => {
  return post({ url: "/api/frontend/free-cancer/calculation-premium", params });
};

export const createOtp = (
  idCardNo: string,
  phone: string,
  type = "FREE_CANCER"
) => {
  return post({
    url: "/api/frontend/verification-request",
    params: {
      idCardNo,
      phone,
      type,
    },
  });
};

export const createOrderFreeCancer = (params: any) => {
  return post(
    { url: "/api/frontend/free-cancer/create-order", params },
    { timeout: 300000 }
  );
};

export const loginByToken = (loginToken: string) => {
  return post({
    url: "/authentication/login-by-token",
    params: { loginToken },
  });
};

export const getUserInfo = () => {
  return get(
    {
      url: "/authentication/userinfo",
      params: {},
    },
    { needAuthen: true }
  );
};

export const refreshToken = (refreshToken: string) => {
  return post({
    url: "/authentication/refresh-token",
    params: { refreshToken },
  });
};

export const getCustomerInfoMc = (idCardNo: string) => {
  return get(
    {
      url: "/api/frontend/mc-cancer/get-customer-info",
      params: { idCardNo },
    },
    {
      needAuthen: true,
    }
  );
};

export const getInsurancePackageMc = (
  dob: string,
  gender: string,
  idCardNo: string
) => {
  return post(
    {
      url: "/api/frontend/mc-cancer/get-insurance-packages",
      params: { dob, gender, idCardNo },
    },
    {
      needAuthen: true,
    }
  );
};

export const getDiscountMc = (idCardNo: string, insurancePackageId: string) => {
  return get(
    {
      url: "/api/frontend/mc-cancer/get-discounts",
      params: { idCardNo, insurancePackageId },
    },
    {
      needAuthen: true,
    }
  );
};

export const checkDiscountMc = (data: any) => {
  return post(
    { url: "/api/frontend/mc-cancer/check-discount", params: data },
    { needAuthen: true }
  );
};

export const checkBpTsaMc = (params: any) => {
  return post(
    {
      url: "/api/frontend/mc-cancer/check-bp-tsa",
      params,
    },
    {
      needAuthen: true,
    }
  );
};

export const createOrderMc = (params: any) => {
  return post(
    { url: "/api/frontend/mc-cancer/create-order", params },
    { needAuthen: true }
  );
};

export const trackingError = (params: any) => {
    return post(
        { url: "/api/frontend/mc-cancer/tracking-error", params },
        { needAuthen: true }
    );
};

export const getBanks = (idCardNo: string) => {
  return get(
    {
      url: "/api/frontend/payment/get-banks",
      params: { idCardNo },
    },
    { needAuthen: false }
  );
};

export const sendOrderPayment = (orderId: string, bpmId: number) => {
  return post(
    {
      url: "/api/frontend/payment/send-order-payment",
      queryParams: { orderId, bpmId },
    },
    { needAuthen: false }
  );
};

export const getBranch = () => {
  return get({ url: "/api/frontend/category/branch" }, { needAuthen: true });
};

export const getConsultant = () => {
  return get(
    { url: "/api/frontend/category/consultant" },
    { needAuthen: true }
  );
};

export const searchInquiryPolicy = (
  search: string,
  startDate?: string | null,
  endDate?: string | null
) => {
  return get(
    {
      url: "/api/frontend/mc-cancer/inquiry-policy",
      params: {
        search,
        startDate: startDate || null,
        endDate: endDate || null,
      },
    },
    { needAuthen: true }
  );
};

// Full buy insurance
export const getInsurancePackage = (
  dob: string,
  gender: string,
  idCardNo: string,
  channel: string
) => {
  return post(
    {
      url: "/api/frontend/buy-insurance-cancer/get-insurance-packages",
      params: { dob, gender, idCardNo, channel },
    },
    {
      needAuthen: false,
    }
  );
};

export const getDiscount = (
  idCardNo: string,
  insurancePackageId: string,
  channel: string
) => {
  return get(
    {
      url: "/api/frontend/buy-insurance-cancer/get-discounts",
      params: { idCardNo, insurancePackageId, channel },
    },
    {
      needAuthen: false,
    }
  );
};

export const checkDiscount = (data: any) => {
  return post(
    { url: "/api/frontend/buy-insurance-cancer/check-discount", params: data },
    { needAuthen: false }
  );
};

export const checkBpTsa = (params: any) => {
  return post(
    {
      url: "/api/frontend/buy-insurance-cancer/check-bp-tsa",
      params,
    },
    {
      needAuthen: false,
    }
  );
};

export const createOrder = (params: any) => {
  return post(
    { url: "/api/frontend/buy-insurance-cancer/create-order", params },
    { needAuthen: false }
  );
};
