import axios from "axios";
import {  MBAL_GA_TRACKING_URL } from "src/Constants";
import ReactGA from 'react-ga4';

export const gaTrackEvent = (pub: string, eventName: string, clientId: string, eventValue: any = 1): void => {
    console.log('gaTrackEvent inside method');

    const payload = {
        pub: pub,
        clientId: clientId,
        eventName: eventName,
        gaId: process.env.REACT_APP_G_A
    }

    ReactGA.event(eventName, payload);

    // const trackingUrl = process.env.REACT_APP_GA_TRACKING_URL || MBAL_GA_TRACKING_URL;
    // const payload = {
    //     pub: pub,
    //     clientId: clientId,
    //     eventName: eventName,
    //     gaId: process.env.REACT_APP_G_A
    // }
    // axios.post(trackingUrl, payload).then();
};
