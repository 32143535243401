import styles from "./SegmentControl.module.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export interface SegmentControlItem {
  id: string;
  name?: string;
  nameKey?: string;
  inactiveIcon: string;
  activeIcon: string;
  link: string;
}

interface SegmentControlProps {
  data?: SegmentControlItem[];
  value?: string;
  onChange: (value: string) => void;
}

const SegmentControl = ({ data, value, onChange }: SegmentControlProps) => {
  const { t } = useTranslation();
  const _handleChangeItem = (item: SegmentControlItem) => {
    if (item.id !== value) {
      onChange(item.id);
    }
  };

  const _renderItem = (item: SegmentControlItem, index: number) => {
    const isActive = item.id === value;
    return (
      <Link to={item.link} className="linkNoStyle">
        <div
          className={`${isActive ? styles.itemActive : styles.itemInactive}${
            index < data!.length - 1 ? " " + styles.itemMargin : ""
          }`}
          key={item.id}
          onClick={() => _handleChangeItem(item)}
        >
          <img
            src={isActive ? item.activeIcon : item.inactiveIcon}
            alt={item.name}
            className={styles.icon}
          />
          <div
            className={`smallTitle ${
              isActive ? "textOnPrimary" : "textPrimary"
            }`}
          >
            {item.name || (item.nameKey ? t(item.nameKey) : "")}
          </div>
        </div>
      </Link>
    );
  };

  return (
    <div className={styles.container}>{data && data.map(_renderItem)}</div>
  );
};

export default SegmentControl;
