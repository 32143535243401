import InsurancePackage from "src/models/InsurancePackage";
import FullCancerFormInfo from "./FullCancerFormInfo";

class FullCancerFormService {
  private static fullCancerFormInfo?: FullCancerFormInfo;
  static frontIdCard?: File;
  static backIdCard?: File;
  static signature?: File;
  static insurancePackages?: InsurancePackage[];

  static saveFullCanncerFormInfo(data: any) {
    const savedFormInfo = this.getFullCancerFormInfo();
    if (!this.fullCancerFormInfo && savedFormInfo) {
      this.fullCancerFormInfo = savedFormInfo;
    }
    this.fullCancerFormInfo = {
      ...this.fullCancerFormInfo,
      ...data,
    };
    sessionStorage.setItem(
      "full_cancer_form_info",
      JSON.stringify(this.fullCancerFormInfo)
    );
  }
  static getFullCancerFormInfo = (): FullCancerFormInfo | null => {
    if (this.fullCancerFormInfo) return this.fullCancerFormInfo;
    const mcreditFormInfoStr = sessionStorage.getItem("full_cancer_form_info");
    if (!mcreditFormInfoStr) return null;
    try {
      return JSON.parse(mcreditFormInfoStr) as FullCancerFormInfo;
    } catch (err) {
      console.log("Parse form info err", err);
      return null;
    }
  };

  static saveInsuracePackages = (packages: InsurancePackage[]) => {
    FullCancerFormService.insurancePackages = packages;
    sessionStorage.setItem(
      "full_cancer_insurance_packages",
      JSON.stringify(packages)
    );
  };

  static getInsurancePackages = (): InsurancePackage[] | null => {
    if (FullCancerFormService.insurancePackages) {
      return FullCancerFormService.insurancePackages!;
    }
    const insurancePackagesStr = sessionStorage.getItem(
      "full_cancer_insurance_packages"
    );
    if (!insurancePackagesStr) return null;
    try {
      FullCancerFormService.insurancePackages = JSON.parse(
        insurancePackagesStr
      ) as InsurancePackage[];
      return FullCancerFormService.insurancePackages;
    } catch (err) {
      console.log("Parse insuracePackage err", err);
      return null;
    }
  };

  static reset = () => {
    sessionStorage.removeItem("full_cancer_form_info");
    sessionStorage.removeItem("full_cancer_insurance_packages");
    this.fullCancerFormInfo = undefined;
    this.frontIdCard = undefined;
    this.backIdCard = undefined;
    this.signature = undefined;
    this.insurancePackages = undefined;
  };
}

export default FullCancerFormService;
