import { useEffect, useState, useCallback } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import ProductCancer from "./pages/Product";
import ProductAccident from "./pages/Product/product_accident";
import Promotion from "./pages/Promotion";
import Support from "./pages/Support";
// FreeCancer
import BuyInsurancePersonalInfo from "./pages/BuyInsurance/PersonalInfo";
import BuyInsuranceHealthQuestion from "./pages/BuyInsurance/HealthQuestion";
import BuyInsuranceConfirmInfo from "./pages/BuyInsurance/ConfirmInfo";
import TermAndCondition from "./pages/BuyInsurance/TermAndCondition";
// Mcredit
import McreditBuyInsuranceCustomerInfo from "./pages/Mcredit/BuyInsurance/CustomerInfo";
import McreditBuyInsuranceHealthQuestion from "./pages/Mcredit/BuyInsurance/HealthQuestion";
import McreditBuyInsuranceConfirmInfo from "./pages/Mcredit/BuyInsurance/ConfirmInfo";
import McreditBuyInsurancePaymentSuccess from "./pages/Mcredit/BuyInsurance/PaymentSuccess";
// FullCancer
import IndividualBuyInsuranceCustomerInfo from "./pages/Individual/BuyInsurance/CustomerInfo";
import IndividualBuyInsuranceHealthQuestion from "./pages/Individual/BuyInsurance/HealthQuestion";
import IndividualBuyInsuranceConfirmInfo from "./pages/Individual/BuyInsurance/ConfirmInfo";
import IndividualBuyInsurancePaymentSuccess from "./pages/Individual/BuyInsurance/PaymentSuccess";

import qs from "qs";
import { getUserInfo, loginByToken } from "src/api";
import SellerInfoService from "./services/SellerInfoService";
import McreditFormService from "./pages/Mcredit/BuyInsurance/McreditFormService";
import TermOfUse from "./pages/TermOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContractManagement from "./pages/ContractManagement";
import ContractDetail from "./pages/ContractManagement/ContractDetail";
import Layout from "./components/Layout";
import AuthInfoService from "./services/AuthInfoService";

const MerchantRoute = ({ children, ...rest }) => {
  const location = useLocation();
  const [logining, setLogining] = useState<boolean>(true);

  const _loginByToken = useCallback(async () => {
    let hashPart = location.hash;
    const fullQueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    const channel = fullQueryParams?.channel;
    if (!hashPart && !channel) {
      setLogining(false);
      return;
    }
    hashPart = hashPart.substring(1);
    const queryParams = qs.parse(hashPart, { ignoreQueryPrefix: true });
    const loginToken = queryParams["login_token"] as string;

    if (!loginToken && !channel) {
      setLogining(false);
      return;
    }
    if (loginToken) {
      console.log("loginToken", loginToken);
      if (
        !SellerInfoService.getAccessToken() ||
        loginToken !== SellerInfoService.getLoginToken()
      ) {
        try {
          setLogining(true);
          const loginRes = await loginByToken(loginToken);
          McreditFormService.reset();
          console.log("Login Res", loginRes);
          SellerInfoService.setInfo(
            loginRes?.data?.access_token,
            loginRes?.data?.refresh_token,
            loginToken
          );
          const sellerInfoRes = await getUserInfo();
          console.log("sellerInfoRes", sellerInfoRes);
          SellerInfoService.setSellerInfo(sellerInfoRes.data);
          AuthInfoService.clearInfo();
          setLogining(false);
          return;
        } catch (err) {
          console.log("Login err", err);
          setLogining(false);
          return;
        }
      } else {
        setLogining(false);
        return;
      }
    }
    if (channel) {
      AuthInfoService.setChannel(channel);
      setLogining(false);
    }
  }, [location]);

  useEffect(() => {
    _loginByToken();
  }, [location, _loginByToken]);
  if (logining) return <div />;
  return (
    <Layout>
      <Route {...rest} render={({ location }) => children} />
    </Layout>
  );
};

const AppRoutes = () => {
  return (
    <Switch>
      <MerchantRoute exact path="/">
        <Home />
      </MerchantRoute>
      <MerchantRoute path="/product/cancer">
        <ProductCancer />
      </MerchantRoute>
      <MerchantRoute path="/product/accident">
        <ProductAccident />
      </MerchantRoute>
      <MerchantRoute path="/support">
        <Support />
      </MerchantRoute>
      <MerchantRoute path="/promotion">
        <Promotion />
      </MerchantRoute>
      <MerchantRoute path="/support">
        <Support />
      </MerchantRoute>
      <Route path="/cancer/buy-insurance/personal-info">
        <BuyInsurancePersonalInfo />
      </Route>
      <Route path="/cancer/buy-insurance/health-question">
        <BuyInsuranceHealthQuestion />
      </Route>
      <Route path="/cancer/buy-insurance/confirm-info">
        <BuyInsuranceConfirmInfo />
      </Route>
      <Route path="/cancer/term_and_condition">
        <TermAndCondition />
      </Route>

      {/* Mcredit route */}
      <MerchantRoute path="/merchant/sell-insurance/cancer" exact={true}>
        <McreditBuyInsuranceCustomerInfo />
      </MerchantRoute>
      <MerchantRoute
        path="/merchant/sell-insurance/cancer/health-question"
        exact={true}
      >
        <McreditBuyInsuranceHealthQuestion />
      </MerchantRoute>
      <MerchantRoute
        path="/merchant/sell-insurance/cancer/confirm-info"
        exact={true}
      >
        <McreditBuyInsuranceConfirmInfo />
      </MerchantRoute>
      <MerchantRoute
        path="/merchant/sell-insurance/cancer/payment-success"
        exact={true}
      >
        <McreditBuyInsurancePaymentSuccess />
      </MerchantRoute>

      {/* Individual route */}
      <MerchantRoute path="/individual/buy-insurance/cancer" exact={true}>
        <IndividualBuyInsuranceCustomerInfo />
      </MerchantRoute>
      <MerchantRoute
        path="/individual/buy-insurance/cancer/health-question"
        exact={true}
      >
        <IndividualBuyInsuranceHealthQuestion />
      </MerchantRoute>
      <MerchantRoute
        path="/individual/buy-insurance/cancer/confirm-info"
        exact={true}
      >
        <IndividualBuyInsuranceConfirmInfo />
      </MerchantRoute>
      <MerchantRoute
        path="/individual/buy-insurance/cancer/payment-success"
        exact={true}
      >
        <IndividualBuyInsurancePaymentSuccess />
      </MerchantRoute>

      <MerchantRoute path="/contract-management" exact={true}>
        <ContractManagement />
      </MerchantRoute>
      <MerchantRoute path="/contract-detail" exact={true}>
        <ContractDetail />
      </MerchantRoute>
      <Route path="/term-of-use">
        <TermOfUse />
      </Route>
      <Route path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
    </Switch>
  );
};

export default AppRoutes;
