import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useLocation } from "react-router";
import InquiryPolicy from "src/models/InquiryPolicy";
import Utils from "src/utils/Utils";
import { CardTypeOriginDisplayMap } from "src/Constants";
import styles from "./ContractManagement.module.scss";

const ContractDetail = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const data = state as InquiryPolicy;
  console.log("Data", data);
  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col lg={8} className="pt-4 pb-4">
          <div className="appH3 textBlack mb-4">{t("contract-detail")}</div>
          <div className={styles.content}>
            <Row>
              <Col sm className="mb-4">
                <div className="textGray body14">{t("insurance-status")}</div>
                <div className="textBlack body14 w600">
                  {t(Utils.getPolicyStatusText(data.policyStatus))}
                </div>
              </Col>
              <Col sm className="mb-4">
                {/* <div className="textGray body14">{t("insurance-status")}</div>
              <div className="textBlack body14 w600">
                {t(Utils.getPolicyStatusText(data.policyStatus))}
              </div> */}
              </Col>
            </Row>
            <Row>
              <Col sm className="mb-4">
                <div className="textGray body14">{t("contract-number")}</div>
                <div className="textBlack body14 w600">{data.policyNumber}</div>
              </Col>
              <Col sm className="mb-4">
                <div className="textGray body14">{t("BMBH-fullname")}</div>
                <div className="textBlack body14 w600">{data.fullname}</div>
              </Col>
            </Row>
            <Row>
              <Col sm className="mb-4">
                <div className="textGray body14">{t("identity-card-no")}</div>
                <div className="textBlack body14 w600">{data.idCardNo}</div>
              </Col>
              <Col sm className="mb-4">
                <div className="textGray body14">{t("identity-card-type")}</div>
                <div className="textBlack body14 w600">
                  {t(CardTypeOriginDisplayMap[data.idCardType])}
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm className="mb-4">
                <div className="textGray body14">{t("inquiry-number")}</div>
                <div className="textBlack body14 w600">{data.appNo}</div>
              </Col>
              <Col sm className="mb-4">
                <div className="textGray body14">{t("promotion-group")}</div>
                <div className="textBlack body14 w600">
                  {data.promotionCode || t("not-apply")}
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm className="mb-4">
                <div className="textGray body14">{t("BMBH-phone")}</div>
                <div className="textBlack body14 w600">{data.phone || "-"}</div>
              </Col>
              <Col sm className="mb-4">
                <div className="textGray body14">{t("issued-date")}</div>
                <div className="textBlack body14 w600">{data.issueDate}</div>
              </Col>
            </Row>
            <Row>
              <Col sm className="mb-4">
                <div className="textGray body14">{t("ack-date")}</div>
                <div className="textBlack body14 w600">
                  {data.ackDate || "-"}
                </div>
              </Col>
              <Col sm className="mb-4">
                <div className="textGray body14">{t("product-name")}</div>
                <div className="textBlack body14 w600">{data.productName}</div>
              </Col>
            </Row>

            <Row>
              <Col sm className="mb-4">
                <div className="textGray body14">{t("expired-date")}</div>
                <div className="textBlack body14 w600">
                  {data.lapsedDate || "-"}
                </div>
              </Col>
              <Col sm className="mb-4">
                <div className="textGray body14">{t("insurance-period")}</div>
                <div className="textBlack body14 w600">
                  {data.contractDuration} {t("yearlc")}
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm className="mb-4">
                <div className="textGray body14">{t("fee-period2")}</div>
                <div className="textBlack body14 w600">
                  {data.paymentDuration || "-"} {t("yearlc")}
                </div>
              </Col>
              <Col sm className="mb-4">
                <div className="textGray body14">{t("term-fee")}</div>
                <div className="textBlack body14 w600">{data.premium}</div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ContractDetail;
