import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import styles from "./GenderSelector.module.scss";
import maleWhiteImg from "../../assets/images/male_white.svg";
import malePinkImg from "../../assets/images/male_pink.svg";
import femaleWhiteImg from "../../assets/images/female_white.svg";
import femalePinkImg from "../../assets/images/female_pink.svg";
import { Gender } from "src/Constants";
interface GenderItem {
  value: Gender;
  text: string;
  activeImg: string;
  inactiveImg: string;
}

const GenderData: Array<GenderItem> = [
  {
    value: Gender.MALE,
    text: "male",
    activeImg: maleWhiteImg,
    inactiveImg: malePinkImg,
  },
  {
    value: Gender.FEMALE,
    text: "female",
    activeImg: femaleWhiteImg,
    inactiveImg: femalePinkImg,
  },
];

interface GenderSelectorProps {
  value?: Gender | null;
  onChange: (gender?: Gender | null) => void;
  errorText?: string;
}
const GenderSelector = ({
  value,
  onChange,
  errorText,
}: GenderSelectorProps) => {
  const { t } = useTranslation();

  const _handleChooseGender = (gender: Gender) => {
    if (value !== gender) {
      onChange(gender);
    } else {
      onChange(null);
    }
  };

  const _renderItem = (item: GenderItem, index: number) => {
    const isActive = item.value === value;
    const itemClassName = `${
      !!errorText
        ? styles.errorItem
        : isActive
        ? styles.activeItem
        : styles.inactiveItem
    } ${index < GenderData.length - 1 ? styles.leftItem : ""} `;
    const textClassName = isActive
      ? "textOnPrimary body16"
      : "textBlack body16";
    const srcImg = isActive ? item.activeImg : item.inactiveImg;
    return (
      <div
        key={item.value}
        className={itemClassName}
        onClick={() => _handleChooseGender(item.value)}
      >
        <div className={textClassName}>{t(item.text)}</div>
        <img width="20" height="20" src={srcImg} alt={item.text} />
      </div>
    );
  };
  return (
    <div>
      <Form.Label htmlFor="identity-card-type">
        {t("gender")} <span className="required">*</span>
      </Form.Label>
      <InputGroup className="mb-4">
        <div className={styles.container}>{GenderData.map(_renderItem)}</div>
        {!!errorText && <div className="invalidText">{errorText}</div>}
      </InputGroup>
    </div>
  );
};

export default GenderSelector;
