import { Suspense, useRef } from "react";
import "./styles/globals.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/bootstrap.scss";
import "./styles/DatePicker.scss";
import GlobalPopupConfirm, {
  GlobalPopupConfirmRef,
} from "./components/GlobalPopupConfirm";
import UiService from "./services/UiService";
import Layout from "./components/Layout";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes";
import LoadingModal, { LoadingModalRef } from "src/components/LoadingModal";
import { Worker } from "@react-pdf-viewer/core";
import ScrollToTop from "./components/ScrollToTop";
import { QR_URL } from "src";

function App() {
  UiService.popupInstance = useRef<GlobalPopupConfirmRef>();
  UiService.loadingInstance = useRef<LoadingModalRef>();
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      <Router>
        <ScrollToTop />
        <Suspense fallback="Loading...">
          <GlobalPopupConfirm ref={UiService.popupInstance} />
          <LoadingModal ref={UiService.loadingInstance} />
          <AppRoutes />
        </Suspense>
      </Router>
    </Worker>
  );
}

export default App;
