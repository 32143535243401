import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import styles from "./TermOfUse.module.scss";
const TermOfUse = () => {
  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col lg={8}>
          <div className={styles.container}>
            <div className="rowCenter text-center appH3 textBlack mb40">
              ĐIỀU KHOẢN SỬ DỤNG WEBSITE
            </div>
            <div className="smallTitle textBlack">1. Điều khoản sử dụng</div>
            Chào mừng Quý khách đến với Trang thông tin điện tử (Website) chính
            thức của MB Ageas Life.
            <br />
            <br />
            <p>
              1.1 Bằng việc truy cập vào Website và các trang của Website này,
              Quý khách xác nhận đồng ý và chịu sự ràng buộc bởi các Điều Khoản
              Sử Dụng dưới đây. Vui lòng đọc kỹ các Điều Khoản Sử Dụng này trước
              khi sử dụng Website này.
            </p>
            <p>
              1.2 Chúng tôi có thể điều chỉnh Điều Khoản Sử Dụng này vào bất kỳ
              lúc nào mà không cần phải thông báo cho Quý khách hay cần có bất
              kỳ lý do nào. Điều Khoản Sử Dụng được cập nhật sẽ có hiệu lực áp
              dụng kể từ thời điểm được đăng tải lên Website này. Quý khách nên
              truy cập thường xuyên vào mục này để kiểm tra các nội dung cập
              nhật của Điều Khoản Sử Dụng.
            </p>
            <p>
              1.3 Nếu Quý khách không đồng ý với bất kỳ nội dung nào của Điều
              Khoản Sử Dụng này, Quý khách vui lòng không truy cập hay sử dụng
              Website này.
            </p>
            <div className="smallTitle textBlack">
              2. Bản quyền và Quyền Sở hữu trí tuệ
            </div>
            Website này thuộc sở hữu và được vận hành bởi MB Ageas Life. Tất cả
            các bản quyền và tài sản trí tuệ trên Website này thuộc về MB Ageas
            Life và người được chúng tôi cấp phép (nếu có). Ngoài những quy định
            khác được nêu rõ ở trong Điều Khoản Sử Dụng này và trừ khi được sự
            cho phép của pháp luật, Quý khách không được thực hiện (và không
            được cố gắng thực hiện) những việc nêu dưới đây:
            <br />
            <br />
            <p>
              (a) Sử dụng, sao chép, xuất bản lại, chỉnh sửa, lưu trong hệ thống
              phục hồi hoặc sử dụng dưới bất hình thức nào khác một phần hoặc
              toàn bộ Website này mục đích công cộng hoặc thương mại;
            </p>
            <p>
              (b) Phân phát, dịch thuật, sửa đổi hay làm xáo trộn bất kỳ phần
              nào của Website;
            </p>
            <p>
              (c) Tạo ra các tác phẩm phái sinh của hoặc từ bất kỳ phần nào của
              Website;
            </p>
            <p>
              (d) Bán, cho thuê, cấp phép, chuyển nhượng, hoán đổi hoặc chuyển
              quyền của Quý khách theo Điều Khoản Sử Dụng này;
            </p>
            <p>
              (e) Cho phép hay giúp người khác thực hiện các hành vi được quy
              định từ mục (a) đến (d)
            </p>
            <div className="smallTitle textBlack">3. Thương hiệu</div>
            <p>
              Tất cả các nhãn hiệu hàng hóa/dịch vụ, tên thương mại và logo hiển
              thị trên Website này đều được bảo hộ và người dùng không được phép
              sử dụng các nhãn hiệu hàng hóa/dịch vụ tên thương mại và logo này,
              ngoại trừ trường hợp được MB Ageas Life hoặc các chủ sở hữu của
              các nhãn hiệu hàng hóa/dịch vụ, tên thương mại và logo này cho
              phép bằng văn bản.
            </p>
            <div className="smallTitle textBlack">4. Hoạt động của Website</div>
            <p>
              4.1 Chúng tôi sẽ nỗ lực duy trì Website của MB Ageas Life trong
              tình trạng hoạt động ổn định. Tuy nhiên, một số chức năng của
              Website sẽ chỉ hoạt động chính thức trong giờ làm việc. Đồng thời,
              hoạt động của Website tùy thuộc vào một số nhà cung cấp khác vì
              vậy MB Ageas Life không cam kết hoặc đảm bảo: (a) Quý khách có thể
              truy cập Website bất kỳ thời điểm nào; hay (b) Quý khách có thể sử
              dụng Website liên tục, không gián đoạn, an toàn và không có lỗi.
            </p>
            <p>
              4.2 Quý khách xác nhận rằng Website có thể không phải lúc nào cũng
              sẵn sàng. Quý khách có thể bị ngừng kết nối với Website tại bất kỳ
              thời điểm nào vì bất kỳ lý do gì, bao gồm: (a) Lỗi do kết nối mạng
              xảy ra; (b) Hệ thống cung cấp dịch vụ không sẵn sàng do bất kỳ lý
              do gì (gồm việc bảo trì); (c) Quý khách vi phạm bất kỳ nội dung
              nào của Điều Khoản Sử Dụng; (d) MB Ageas Life quyết định chấm dứt,
              tạm ngừng hay ngăn chặn việc truy cập vào Website của Quý khách vì
              bất kỳ lý do gì.
            </p>
            <p>
              4.3 MB Ageas Life không đưa ra bất kỳ sự đảm bảo nào về tính tin
              cậy hay những vấn đề nào khác liên quan đến vận hành của Website.
              Hoạt động của Website phụ thuộc vào nhiều yếu tố, gồm chức năng,
              năng suất và cấu hình thiết bị của Quý khách, tốc độ đường truyền
              và số lượng người truy cập vào hệ thống hỗ trợ Website.
            </p>
            <p>
              4.4 Thông tin trên Website này có thể được chỉnh sửa bất kỳ lúc
              nào nhằm đảm bảo tính cập nhật, vì vậy có thể có sự gián đoạn, lỗi
              hoặc sai sót ảnh hưởng đến việc lưu hành và độ chính xác. Theo đó,
              MB Ageas Life không thể và không cam đoan hay đảm bảo các thông
              tin trên Website này được hoặc sẽ được cập nhật, hoàn chỉnh và
              chính xác tại mọi thời điểm. Quý khách xác nhận và đồng ý Quý
              khách sẽ tự xác định các thông tin trên Website này là thông tin
              hiện hành, hoàn chỉnh và chính xác trước khi sử dụng. MB Ageas
              Life không chịu trách nhiệm cho bất kỳ tổn thất nào mà Quý khách
              gánh chịu do không tuân thủ quy định này
            </p>
            <div className="smallTitle textBlack">5. Miễn trừ trách nhiệm</div>
            <p>
              MB Ageas Life sẽ không chịu trách nhiệm bồi thường đối với bất kỳ
              chi phí, tổn thất hoặc thiệt hại nào cho dù trực tiếp hay gián
              tiếp, có liên quan tới hoặc là hậu quả của việc sử dụng hoặc không
              thể sử dụng được Website này bởi bất cứ bên nào, hoặc liên quan
              đến việc không hoạt động, sai sót, thiếu sót, gián đoạn, các nhược
              điểm, hay sự chậm trễ trong hoạt động hay truyền tải do ”virus”
              máy tính hoặc do đường truyền hoặc do gián đoạn hệ thống, ngay cả
              khi MB Ageas Life đã được thông báo trước về khả năng xảy ra các
              thiệt hại, mất mát này. Người truy cập phải tự chịu các rủi ro
              (nếu có) khi sử dụng các đường dẫn đến các nguồn tài nguyên
              internet khác; nội dung, sự chính xác, quan điểm trình bày, cũng
              như các đường dẫn khác được cung cấp bởi các nguồn này không được
              điều tra, xác minh, giám sát hoặc xác nhận bởi MB Ageas Life.
            </p>
            <div className="smallTitle textBlack">
              6. Quyền sở hữu đối với thông tin:
            </div>
            <p>
              Mọi thông tin, ngoại trừ thông tin cá nhân, mà Quý khách gửi đến
              MB Ageas Life qua Website này, bao gồm nhưng không giới hạn ở các
              dữ liệu, câu hỏi, góp ý, yêu cầu hoặc đề nghị, sẽ không được coi
              là các thông tin mật và sẽ trở thành tài sản của MB Ageas Life.
              Khi đó, MB Ageas Life có thể sử dụng các thông tin này cho bất kỳ
              mục đích gì mà không cần bất kỳ sự đồng ý nào. Bất kỳ thông tin cá
              nhân nào được gửi cho chúng tôi bằng việc sử dụng Website sẽ là
              đối tượng được áp dụng Chính sách bảo mật trực tuyến của MB Ageas
              Life tại đây.
            </p>
            <div className="smallTitle textBlack">7. Truyền thông internet</div>
            <p>
              Các tin nhắn được gửi qua mạng Internet không thể được đảm bảo an
              toàn tuyệt đối. Vì vậy, Quý Khách cần tự trang bị cho mình kiến
              thức về an toàn và bảo mật cần thiết khi sử dụng mạng Internet.
              Tham khảo hướng dẫn cập nhật “Các vấn đề bảo mật quan trọng mới
              nhất” tại đây. MB Ageas Life sẽ không thể và không chịu trách
              nhiệm cho bất kỳ thiệt hại nào phát sinh cho người dùng nếu người
              dùng gửi tin nhắn cho MB Ageas Life, hoặc nếu MB Ageas Life gửi
              tin nhắn cho người dùng theo yêu cầu qua Internet. MB Ageas Life
              không chịu trách nhiệm dưới bất kỳ hình thức nào đối với các thiệt
              hại trực tiếp, gián tiếp, đặc biệt là hậu quả phát sinh từ việc sử
              dụng Website này.
            </p>
            <div className="smallTitle textBlack">
              8. Luật điều chỉnh và thẩm quyền xét xử
            </div>
            <p>
              Điều Khoản Sử Dụng này sẽ được điều chỉnh bởi pháp luật Việt Nam
              và các bên đồng ý tuân theo thẩm quyền duy nhất của các tòa án
              Việt Nam.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default TermOfUse;
