import { useState, useEffect, useCallback, useMemo } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GenderSelector from "src/components/GenderSelector";
import styles from "src/styles/BuyInsurance.module.scss";
import DropdownInput, { DropdownItem } from "src/components/DropdownInput";
import { useTranslation } from "react-i18next";
import Input from "src/components/Input";
import DateInput from "src/components/DateInput";
import InsuranceItem from "src/components/InsuranceItem";
import HorizontalStepper from "src/components/HorizontalStepper";
import { useHistory } from "react-router-dom";
import UiService from "src/services/UiService";
import {
  CARD_TYPE_VALUES,
  CardType,
  Gender,
  VN_COUNTRY_CODE,
  ProductCode,
  BUY_INSURANCE_STEP_DATA,
  GenderDisplayMap,
  EMPTY_ARRAY,
} from "src/Constants";
import {
  getCategory,
  getUserInfo,
  getCustomerInfoMc,
  getInsurancePackageMc,
  checkBpTsaMc,
  getDiscountMc,
  checkDiscountMc,
  getBranch,
  getConsultant,
} from "src/api";
import Utils from "src/utils/Utils";
import McreditFormInfo from "src/pages/Mcredit/BuyInsurance/McreditFormInfo";
import McreditFormService from "../McreditFormService";
import { useMediaQuery } from "react-responsive";
import Accordion from "react-bootstrap/Accordion";
import InsurancePackage from "src/models/InsurancePackage";
import InsurancePackageChooser from "src/components/InsurancePackageChooser";
import SellerInfoService from "src/services/SellerInfoService";
import VoucherInput from "src/components/VoucherInput";
import DiscountPopup from "src/components/DiscountPopup";
import Discount from "src/models/Discount";
import Branch from "src/models/Branch";
import Consultant from "src/models/Consultant";
import SearchableSelect from "src/components/SearchableSelect";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";

const CustomerInfo = () => {
  const isLargeDevice = useMediaQuery({
    query: "(min-width: 992px)",
  });
  const [saleChannel, setSaleChannel] = useState<string>("");
  const [errSaleChannel, setErrSaleChannel] = useState<string>("");
  const [crossSellingCode, setCrossSellingCode] = useState<string>("");
  const [errCrossSellingCode, setErrCrossSellingCode] = useState<string>("");
  const [referralCode, setReferralCode] = useState<string>("");
  const [errReferralCode, setErrReferralCode] = useState<string>("");
  const [employeeName, setEmployeeName] = useState<string>("");
  const [errEmployeeName, setErrEmployeeName] = useState<string>("");

  const [dob, setDob] = useState<Date>();
  const [errDob, setErrDob] = useState<string>();
  const [gender, setGender] = useState<Gender | null>(
    McreditFormService.getMcreditFormInfo()?.gender || null
  );
  const [errGender, setErrGender] = useState<string>("");
  const [idCardType, setIdCardType] = useState<CardType>();
  const [issueDate, setIssueDate] = useState<Date>();
  const [errIssueDate, setErrIssueDate] = useState<string>();
  const [issuePlace, setIssuePlace] = useState<string>("");
  const [errIssuePlace, setErrIssuePlace] = useState<string>("");
  const [errIdCardType, setErrIdCardType] = useState<string>("");
  const [idCardNo, setIdCardNo] = useState<string>("");
  const [errIdCardNo, setErrIdCardNo] = useState<string>("");
  const [fullname, setFullname] = useState<string>("");
  const [canEditFullname, setCanEditFullname] = useState<boolean>(true);
  const [errFullname, setErrFullname] = useState<string>("");
  const [nationality, setNationality] = useState<string>(VN_COUNTRY_CODE);
  const [errNationality, setErrNationality] = useState<string>("");
  const [nationList, setNationList] = useState<DropdownItem[]>();
  const [selectedPackage, setSelectedPackage] = useState<string | null>();
  const [insurancePackages, setInsurancePackages] = useState<
    InsurancePackage[] | null
  >();
  const [editingInfo, setEditingInfo] = useState<boolean>(true);
  const [discount, setDiscount] = useState<string>();
  const [isValidDiscount, setIsValidDiscount] = useState<boolean | null>(null);
  const [errDiscount, setErrDiscount] = useState<string>("");
  const [showDiscountPopup, setShowDiscountPopup] = useState<boolean>(false);
  const [discountData, setDiscountData] = useState<Discount[] | null>();
  const [discountAmt, setDiscountAmt] = useState<number | null>();
  const [beforeDiscount, setBeforeDiscount] = useState<number | null>();
  const [afterDiscount, setAfterDiscount] = useState<number | null>();
  const [applyingDiscount, setApplyingDiscount] = useState<Discount | null>();
  const [branches, setBranches] = useState<Branch[]>();
  const [branch, setBranch] = useState<Branch | null>();
  const [errBranch, setErrBranch] = useState<string>();
  const [consultants, setConsultants] = useState<Consultant[]>();
  const [consultant, setConsultant] = useState<Consultant | null>();
  const [errConsultant, setErrConsultant] = useState<string>();

  const { t } = useTranslation();
  const history = useHistory();

  const _loadCategory = async () => {
    try {
      const categoryRes = await getCategory(ProductCode.CANCER);
      console.log("categoryRes", categoryRes);
      localStorage.setItem(
        "cancer_commitments",
        JSON.stringify(categoryRes?.data?.commitments)
      );
      localStorage.setItem(
        "nationList",
        JSON.stringify(categoryRes?.data?.nationalitys)
      );
      localStorage.setItem(
        "cancer_question",
        JSON.stringify(categoryRes?.data?.questions)
      );
      setNationList(
        categoryRes?.data?.nationalitys.map((item) => ({
          name: item.name,
          value: item.id,
        }))
      );
    } catch (err) {
      console.log("_loadCategory err", err);
    }
  };

  const _loadBranch = async () => {
    try {
      const branchRes = await getBranch();
      console.log("branchRes", branchRes);
      setBranches(branchRes?.data?.dtos);
    } catch (err) {
      console.log("_loadBranch err", err);
    }
  };

  const _loadConsultant = async () => {
    try {
      const consultantRes = await getConsultant();
      console.log("consultantRes", consultantRes);
      setConsultants(consultantRes?.data?.dtos);
    } catch (err) {
      console.log("_loadConsultant err", err);
    }
  };

  const _fillData = () => {
    const savedInsuraceaPackages = McreditFormService.getInsurancePackages();
    if (savedInsuraceaPackages) {
      setEditingInfo(false);
      setInsurancePackages(savedInsuraceaPackages);
      if (McreditFormService.getMcreditFormInfo()?.selectedPackage) {
        _selectPackage(
          McreditFormService.getMcreditFormInfo()?.selectedPackage,
          McreditFormService.getMcreditFormInfo()?.idCardNo
        );
      } else {
        _selectPackage(
          savedInsuraceaPackages.find((item) => item.defaultSelect === true)
            ?.id || "1",
          McreditFormService.getMcreditFormInfo()?.idCardNo
        );
      }
      if (McreditFormService.getMcreditFormInfo()?.discount) {
        setDiscount(McreditFormService.getMcreditFormInfo()?.discount);
        setIsValidDiscount(true);
      }
      if (McreditFormService.getMcreditFormInfo()?.applyingDiscount) {
        setApplyingDiscount(
          McreditFormService.getMcreditFormInfo()?.applyingDiscount
        );
      }
    }
    if (McreditFormService.getMcreditFormInfo()?.fullname) {
      setFullname(McreditFormService.getMcreditFormInfo()?.fullname!);
    }
    if (McreditFormService.getMcreditFormInfo()?.idCardNo) {
      setIdCardNo(McreditFormService.getMcreditFormInfo()?.idCardNo!);
    }
    if (McreditFormService.getMcreditFormInfo()?.idCardType) {
      setIdCardType(McreditFormService.getMcreditFormInfo()?.idCardType);
    }
    if (McreditFormService.getMcreditFormInfo()?.issuedDate) {
      setIssueDate(
        Utils.covertToDate(McreditFormService.getMcreditFormInfo()!.issuedDate!)
      );
    }
    if (McreditFormService.getMcreditFormInfo()?.dob) {
      setDob(Utils.covertToDate(McreditFormService.getMcreditFormInfo()!.dob!));
    }
    if (McreditFormService.getMcreditFormInfo()?.issuePlace) {
      setIssuePlace(McreditFormService.getMcreditFormInfo()?.issuePlace!);
    }
    if (McreditFormService.getMcreditFormInfo()?.gender) {
      setGender(McreditFormService.getMcreditFormInfo()!.gender!);
    }

    if (McreditFormService.getMcreditFormInfo()?.branch) {
      setBranch(McreditFormService.getMcreditFormInfo()?.branch);
    }

    if (McreditFormService.getMcreditFormInfo()?.consultant) {
      setConsultant(McreditFormService.getMcreditFormInfo()?.consultant);
    }

    if (
      typeof McreditFormService.getMcreditFormInfo()?.beforeDiscount !=
      "undefined"
    ) {
      setBeforeDiscount(
        McreditFormService.getMcreditFormInfo()?.beforeDiscount
      );
    }
    if (
      typeof McreditFormService.getMcreditFormInfo()?.afterDiscount !=
      "undefined"
    ) {
      setAfterDiscount(McreditFormService.getMcreditFormInfo()?.afterDiscount);
    }
    if (
      typeof McreditFormService.getMcreditFormInfo()?.discountAmt != "undefined"
    ) {
      setDiscountAmt(McreditFormService.getMcreditFormInfo()?.discountAmt);
    }
  };

  const _loadUserInfo = async () => {
    try {
      UiService.showLoading();
      const sellerInfoRes = await getUserInfo();
      SellerInfoService.setSellerInfo(sellerInfoRes.data);
      console.log("sellerInfoRes", sellerInfoRes);
      setEmployeeName(sellerInfoRes?.data?.sellerName);
      setReferralCode(sellerInfoRes?.data?.sellerCode);
      setSaleChannel(sellerInfoRes?.data?.merchant?.name);
      UiService.hideLoading();
    } catch (err) {
      console.log("Load user Info err", err);
      UiService.hideLoading();
    }
  };

  useEffect(() => {
    _loadCategory();
    _fillData();
    _loadUserInfo();
    _loadBranch();
    _loadConsultant();
  }, []);

  const _validate = (): boolean => {
    let isValid: boolean = true;
    if (!saleChannel || !saleChannel.trim()) {
      setErrSaleChannel(t("sale-channel-required"));
      isValid = false;
    }
    if (!referralCode || !referralCode.trim()) {
      setErrReferralCode(t("referral-code-required"));
      isValid = false;
    }
    if (!employeeName || !employeeName.trim()) {
      setErrEmployeeName(t("employee-name-required"));
      isValid = false;
    }
    if (!idCardType) {
      setErrIdCardType(t("id-card-type-required"));
      isValid = false;
    }
    if (
      crossSellingCode &&
      crossSellingCode.trim() &&
      !Utils.isNotContainSpecialCharacter(crossSellingCode)
    ) {
      setErrCrossSellingCode(t("invalid-cross-selling-code"));
      isValid = false;
    }
    if (!idCardNo || idCardNo.trim() === "") {
      setErrIdCardNo(t("id-card-no-required"));
      isValid = false;
    } else if (idCardType === CardType.CMND && !Utils.isValidCMND(idCardNo)) {
      setErrIdCardNo(t("invalid-cmnd"));
      isValid = false;
    } else if (idCardType === CardType.CCCD && !Utils.isValidCCCD(idCardNo)) {
      setErrIdCardNo(t("invalid-cccd"));
      isValid = false;
    } else if (
      idCardType === CardType.PASSPORT &&
      !Utils.isValidPassport(idCardNo)
    ) {
      setErrIdCardNo(t("invalid-passport"));
      isValid = false;
    } else if (idCardType === CardType.CMTQD && !Utils.isValidCMTQD(idCardNo)) {
      setErrIdCardNo(t("invalid-cmntqd"));
      isValid = false;
    }
    if (!issueDate) {
      setErrIssueDate(t("issue-date-required"));
      isValid = false;
    }
    if (!issuePlace || !issuePlace.trim()) {
      setErrIssuePlace(t("issue-place-required"));
      isValid = false;
    }

    if (!fullname || fullname.trim() === "") {
      setErrFullname(t("name-required"));
      isValid = false;
    }
    if (!gender) {
      setErrGender(t("gender-required"));
      isValid = false;
    }
    if (!dob) {
      setErrDob(t("dob-required"));
      isValid = false;
    }

    if (!nationality) {
      setErrNationality(t("nationality-required"));
      isValid = false;
    }

    if (Utils.isSmartRMChannel() && !branch) {
      setErrBranch(t("branch-code-required"));
      isValid = false;
    }
    if (Utils.isSmartRMChannel() && !consultant) {
      setErrConsultant(t("consultant-code-required"));
      isValid = false;
    }
    if (!isValid) return false;
    if (nationality !== VN_COUNTRY_CODE) {
      UiService.openPopup({
        showContactNow: true,
        content: t("product-not-support-foreign-people"),
        onOk: () => {
          UiService.closePopup();
        },
        onCancel: () => {
          UiService.closePopup();
        },
      });
      isValid = false;
    }
    return isValid;
  };

  const _handleContinue = async () => {
    if (discount && discount.trim()) {
      const isValidDiscount = await _checkDiscount();
      if (!isValidDiscount) return;
    }
    try {
      UiService.showLoading();
      const checkBpTsaRes = await checkBpTsaMc({
        dob: Utils.formatDate(dob!),
        gender,
        fullname,
        sumAssured: insurancePackages?.find(
          (item) => item.id === selectedPackage
        )?.insamountAm,
        idCardNo: idCardNo,
      });
      McreditFormService.saveMcreditFormInfo({
        selectedPackage,
        discount,
        discountData,
        discountAmt,
        beforeDiscount,
        afterDiscount,
        applyingDiscount,
        branch,
        consultant,
      });
      UiService.hideLoading();
      console.log("checkBpTsaRes", checkBpTsaRes);
      history.push("/merchant/sell-insurance/cancer/health-question");
    } catch (err: any) {
      console.log("calculatePremiumFreeCancer err", err);
      UiService.hideLoading();
      if (err?.response?.data?.code === 400) {
        UiService.openPopup({
          content: err?.response?.data?.error_message,
          showContactNow: true,
          onOk: () => {
            UiService.closePopup();
          },
          onCancel: () => {
            UiService.closePopup();
          },
        });
      }
    }
  };

  const _handleChangeIdCardNo = (e) => {
    setIdCardNo(e.target.value.replace(/[^0-9a-zA-Z]/gi, ""));
    setErrIdCardNo("");
  };

  const _handleChangeIssueDate = (value) => {
    setIssueDate(value);
    setErrIssueDate("");
  };

  const _handleChangeIssuePlace = (e) => {
    setIssuePlace(e.target.value);
    setErrIssuePlace("");
  };

  const _handleChangeFullname = (e) => {
    setFullname(e.target.value);
    setErrFullname("");
  };

  const _handleChangeDob = (value) => {
    console.log("On Change Dob", value);
    setDob(value);
    setErrDob("");
  };

  const _handleChangeIdCardType = (value) => {
    setIdCardType(value);
    setErrIdCardType("");
  };

  const _handleChangeNationality = (value) => {
    setNationality(value);
    setErrNationality("");
  };

  const _handleChangeGender = (gender) => {
    setGender(gender);
    setErrGender("");
  };

  const _getTodayDay = () => {
    const todayDate = new Date();
    return {
      day: todayDate.getDate(),
      month: todayDate.getMonth() + 1,
      year: todayDate.getFullYear(),
    };
  };

  const _handleChangeSaleChannel = (e) => {
    setSaleChannel(e.target.value);
    setErrSaleChannel("");
  };

  const _handleChangeCrossSellingCode = (e) => {
    setCrossSellingCode(e.target.value);
    setErrCrossSellingCode("");
  };

  const _handleChangeReferralCode = (e) => {
    setReferralCode(e.target.value);
    setErrReferralCode("");
  };

  const _handleChangeEmployeeName = (e) => {
    setEmployeeName(e.target.value);
    setErrEmployeeName("");
  };

  const _queryCustomerInfo = async () => {
    if (
      !idCardNo ||
      !idCardNo.trim() ||
      SellerInfoService.getSellerInfo()?.merchant?.customerInfoSupported ===
        false
    )
      return;
    try {
      UiService.showLoading();
      const customerInfoRes = await getCustomerInfoMc(idCardNo);
      console.log("customerInfoRes", customerInfoRes);
      McreditFormService.saveMcreditFormInfo({ hasCustomerInfo: true });
      // if (customerInfoRes?.data?.idCardNo) {
      //   setIdCardNo(customerInfoRes?.data?.idCardNo);
      //   setErrIdCardNo("");
      // }
      if (customerInfoRes?.data?.customerName) {
        setFullname(customerInfoRes?.data?.customerName);
        setCanEditFullname(false);
        setErrFullname("");
      }
      if (
        customerInfoRes?.data?.dob &&
        Utils.covertToDateForInput(customerInfoRes?.data?.dob)
      ) {
        setDob(Utils.covertToDateForInput(customerInfoRes?.data?.dob)!);
        setErrDob("");
      }
      if (
        customerInfoRes?.data?.issuedDate &&
        Utils.covertToDateForInput(customerInfoRes?.data?.issuedDate)
      ) {
        setIssueDate(
          Utils.covertToDateForInput(customerInfoRes?.data?.issuedDate)!
        );
        setErrIssueDate("");
      }
      if (customerInfoRes?.data?.issuedPlace) {
        setIssuePlace(customerInfoRes?.data?.issuedPlace);
        setErrIssuePlace("");
      }
      if (customerInfoRes?.data?.gender) {
        setGender(customerInfoRes?.data?.gender);
        setErrGender("");
      }

      if (customerInfoRes?.data?.idCardType) {
        setIdCardType(CardType[customerInfoRes?.data?.idCardType]);
        setErrIdCardType("");
      }

      UiService.hideLoading();
    } catch (err: any) {
      if (err?.response?.data?.code === 400) {
        McreditFormService.saveMcreditFormInfo({ hasCustomerInfo: false });
      }
      console.log("Err _queryCustomerInfo: ", err);
      UiService.hideLoading();
    }
  };

  const _selectPackage = async (
    packageId?: string | null,
    idCardNo?: string
  ) => {
    if (!packageId || !idCardNo) return;
    setSelectedPackage(packageId);
  };

  const _handleConfirmInfo = async () => {
    if (!editingInfo) {
      setEditingInfo(true);
      setInsurancePackages(null);
      setSelectedPackage(null);
      setDiscount("");
      setIsValidDiscount(null);
      setDiscountData(null);
      setErrDiscount("");
      setApplyingDiscount(undefined);
      setBeforeDiscount(undefined);
      setAfterDiscount(undefined);
      setDiscountAmt(undefined);
      return;
    }
    const isValid = _validate();
    if (!isValid) return;

    const mcreditFormInfo: McreditFormInfo = {
      saleChannel,
      crossSellingCode,
      employeeName,
      referralCode,
      dob: Utils.formatDate(dob!),
      gender: gender!,
      idCardNo: idCardNo!,
      idCardType: idCardType!,
      fullname,
      issuePlace: issuePlace,
      issuedDate: Utils.formatDate(issueDate!),
    };
    McreditFormService.saveMcreditFormInfo(mcreditFormInfo);
    try {
      UiService.showLoading();
      const insuracePackagesRes = await getInsurancePackageMc(
        Utils.formatDate(dob!),
        gender!,
        idCardNo
      );
      setEditingInfo(false);
      McreditFormService.saveInsuracePackages(insuracePackagesRes?.data?.list);
      setInsurancePackages(insuracePackagesRes?.data?.list);
      const selectedPackageId =
        insuracePackagesRes?.data?.list?.find(
          (item) => item.defaultSelected === true
        )?.id || "1";
      // setSelectedPackage(selectedPackageId);
      _selectPackage(selectedPackageId, idCardNo);
      console.log("insuracePackagesRes", insuracePackagesRes);
      UiService.hideLoading();
    } catch (err: any) {
      UiService.hideLoading();
      console.log("getInsurancePackageMc err", err);
      if (err?.response?.data?.code === 400) {
        UiService.openPopup({
          content: err?.response?.data?.error_message,
          showContactNow: true,
          onOk: () => {
            UiService.closePopup();
          },
          onCancel: () => {
            UiService.closePopup();
          },
        });
      }
    }
  };

  const branchForSelect = useMemo(() => {
    if (!branches) return EMPTY_ARRAY;
    return branches.map((item) => ({
      ...item,
      value: item.ccId,
      label: item.branchId,
    }));
  }, [branches]);

  const _handleChangeBranchCode = (branch: Branch | null) => {
    console.log("Handle Change branhc", branch);
    setBranch(branch);
    setErrBranch("");
  };

  const consultantForSelect = useMemo(() => {
    if (!consultants) return EMPTY_ARRAY;
    return consultants.map((item) => ({
      ...item,
      value: item.consultantId,
      label: item.consultantId,
    }));
  }, [consultants]);

  const _handleChangeConsultant = (consultant: Consultant | null) => {
    console.log("Handle Change consultant", consultant);
    setConsultant(consultant);
    setErrConsultant("");
  };
  const _renderSellerInfoForm = () => {
    return (
      <div className="mb-3">
        <div className="body16 w600 textBlack">{t("seller-info")}</div>
        <div className="line mb-3 mt-3" />
        {editingInfo ? (
          <>
            <Row>
              <Col md>
                <Input
                  label={t("sale-channel")}
                  placeholder={t("sale-channel-placeholder")}
                  required={true}
                  maxLength={60}
                  value={saleChannel}
                  onChange={_handleChangeSaleChannel}
                  errorText={errSaleChannel}
                  disabled={true}
                />
              </Col>
              <Col md>
                <Input
                  label={t("cross-selling-code")}
                  placeholder={t("cross-selling-code-placeholder")}
                  maxLength={10}
                  value={crossSellingCode}
                  onChange={_handleChangeCrossSellingCode}
                  errorText={errCrossSellingCode}
                />
              </Col>
            </Row>
            <Row>
              <Col md>
                <Input
                  label={t("referral-code")}
                  placeholder={t("referral-code-placeholder")}
                  required={true}
                  maxLength={60}
                  value={referralCode}
                  onChange={_handleChangeReferralCode}
                  errorText={errReferralCode}
                  disabled={true}
                />
              </Col>
              <Col md>
                <Input
                  label={t("employee-name")}
                  placeholder={t("employee-name-placeholder")}
                  maxLength={60}
                  value={employeeName}
                  onChange={_handleChangeEmployeeName}
                  errorText={errEmployeeName}
                  required={true}
                  disabled={true}
                />
              </Col>
            </Row>
            {Utils.isSmartRMChannel() && (
              <>
                <Row>
                  <Col md>
                    <SearchableSelect<Branch>
                      label={t("branch-code")}
                      options={branchForSelect}
                      required
                      placeholder={t("choose-branch-code")}
                      errorText={errBranch}
                      onChange={_handleChangeBranchCode}
                      value={branch}
                      formatOptionLabel={(data: Branch, { context }) => {
                        if (context === "value") {
                          return (
                            <div className="rowStart">
                              <div className="flex1">{data.branchId}</div>
                            </div>
                          );
                        }
                        return (
                          <div>
                            <div className="rowStart mb-1">
                              <div className="textGray flex1">
                                {data.branchName}
                              </div>
                            </div>
                            <div className="rowStart">
                              <div className="flex1">{data.branchId}</div>
                              <div>{data.ccId}</div>
                            </div>
                          </div>
                        );
                      }}
                      filterOption={(
                        candidate: FilterOptionOption<Branch>,
                        input
                      ) => {
                        const normalizedInput = Utils.removeAccent(
                          input.trim()
                        ).toLowerCase();
                        return (
                          Utils.removeAccent(
                            candidate.data.branchId.toLowerCase()
                          ).includes(normalizedInput) ||
                          Utils.removeAccent(
                            candidate.data.ccId.toLowerCase()
                          ).includes(normalizedInput) ||
                          Utils.removeAccent(
                            candidate.data.branchName.toLowerCase()
                          ).includes(normalizedInput)
                        );
                      }}
                    />
                  </Col>
                  <Col md>
                    <Input
                      label={t("branch-name")}
                      maxLength={60}
                      value={branch?.branchName || ""}
                      required={true}
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md>
                    <SearchableSelect<Consultant>
                      label={t("consultant-code")}
                      options={consultantForSelect}
                      required
                      placeholder={t("choose-consultant-code")}
                      errorText={errConsultant}
                      onChange={_handleChangeConsultant}
                      value={consultant}
                      formatOptionLabel={(data: Consultant, { context }) => {
                        if (context === "value") {
                          return (
                            <div className="rowStart">
                              <div className="flex1">{data.consultantId}</div>
                            </div>
                          );
                        }
                        return (
                          <div className="rowStart">
                            <div className="flex1">{data.consultantName}</div>
                            <div>{data.consultantId}</div>
                          </div>
                        );
                      }}
                      filterOption={(
                        candidate: FilterOptionOption<Consultant>,
                        input
                      ) => {
                        const normalizedInput = Utils.removeAccent(
                          input.trim()
                        ).toLowerCase();
                        return (
                          Utils.removeAccent(
                            candidate.data.consultantName.toLowerCase()
                          ).includes(normalizedInput) ||
                          Utils.removeAccent(
                            candidate.data.consultantId.toLowerCase()
                          ).includes(normalizedInput)
                        );
                      }}
                    />
                  </Col>
                  <Col md>
                    <Input
                      label={t("consultant-name")}
                      maxLength={60}
                      value={consultant?.consultantName || ""}
                      required={true}
                      disabled={true}
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        ) : (
          <>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("sale-channel")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">{saleChannel}</div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("cross-selling-code")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {crossSellingCode || "-"}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("referral-code")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">{referralCode}</div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("employee-name")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">{employeeName}</div>
              </Col>
            </Row>
            {Utils.isSmartRMChannel() && (
              <>
                <Row className="mb-3">
                  <Col>
                    <div className="textGray body14">{t("branch-code")}</div>
                  </Col>
                  <Col>
                    <div className="textBlack body14 w600">
                      {branch?.branchId}
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <div className="textGray body14">{t("branch-name")}</div>
                  </Col>
                  <Col>
                    <div className="textBlack body14 w600">
                      {branch?.branchName}
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <div className="textGray body14">
                      {t("consultant-code")}
                    </div>
                  </Col>
                  <Col>
                    <div className="textBlack body14 w600">
                      {consultant?.consultantId}
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <div className="textGray body14">
                      {t("consultant-name")}
                    </div>
                  </Col>
                  <Col>
                    <div className="textBlack body14 w600">
                      {consultant?.consultantName}
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const _getDisplayCardType = (cardType?: CardType): string => {
    if (!cardType) return "";
    return t(
      CARD_TYPE_VALUES.find((item) => item.value === cardType)?.name || ""
    );
  };

  const _renderCustomerInfoForm = () => {
    return (
      <>
        <div className="body16 w600 textBlack">{t("customer-info")}</div>
        <div className="line mb-3 mt-3" />
        {editingInfo ? (
          <>
            <Row>
              <Col md>
                <DropdownInput
                  label={t("identity-card-type")}
                  placeholder={t("identity-card-type-placeholder")}
                  values={CARD_TYPE_VALUES.map((item) => ({
                    ...item,
                    name: t(item.name),
                  }))}
                  value={idCardType}
                  onChange={_handleChangeIdCardType}
                  required={true}
                  errorText={errIdCardType}
                />
              </Col>
              <Col md>
                <Input
                  label={t("identity-card-no")}
                  placeholder={t("identity-card-no-placeholder")}
                  required={true}
                  maxLength={20}
                  value={idCardNo}
                  onChange={_handleChangeIdCardNo}
                  errorText={errIdCardNo}
                  onBlur={_queryCustomerInfo}
                />
              </Col>
            </Row>
            <Input
              label={t("fullname")}
              placeholder={t("fullname-placeholder")}
              required={true}
              maxLength={60}
              value={fullname}
              errorText={errFullname}
              onChange={_handleChangeFullname}
              disabled={!canEditFullname}
            />
            <Row>
              <Col md>
                <DateInput
                  placeholder={t("issue-date-placeholder")}
                  label={t("issue-date")}
                  required={true}
                  value={issueDate}
                  onChange={_handleChangeIssueDate}
                  errorText={errIssueDate}
                  maximumDate={_getTodayDay()}
                />
              </Col>
              <Col md>
                <Input
                  label={t("issue-place")}
                  placeholder={t("issue-place-placeholder")}
                  required={true}
                  maxLength={60}
                  value={issuePlace}
                  errorText={errIssuePlace}
                  onChange={_handleChangeIssuePlace}
                />
              </Col>
            </Row>

            <GenderSelector
              value={gender}
              onChange={_handleChangeGender}
              errorText={errGender}
            />

            <DateInput
              placeholder={t("dob-placeholder")}
              label={t("dob")}
              required={true}
              value={dob}
              onChange={_handleChangeDob}
              errorText={errDob}
              maximumDate={_getTodayDay()}
            />

            <DropdownInput
              label={t("nationality")}
              placeholder={t("nationality-placeholder")}
              values={nationList}
              value={nationality}
              onChange={_handleChangeNationality}
              required={true}
              errorText={errNationality}
            />
          </>
        ) : (
          <>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("identity-card-type")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {_getDisplayCardType(idCardType)}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("identity-card-no")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">{idCardNo}</div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("fullname")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">{fullname}</div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("issue-date")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {issueDate ? Utils.formatDate(issueDate) : ""}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("issue-place")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">{issuePlace || ""}</div>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("gender")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {t(GenderDisplayMap[gender || ""])}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("dob")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">
                  {dob ? Utils.formatDate(dob) : ""}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="textGray body14">{t("nationality")}</div>
              </Col>
              <Col>
                <div className="textBlack body14 w600">Việt Nam</div>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  };

  const _handleChangeDiscount = (e) => {
    setDiscount(e.target.value.toUpperCase());
    setIsValidDiscount(null);
    setErrDiscount("");
  };

  const _handleClearDiscount = () => {
    setDiscount("");
    setIsValidDiscount(null);
    setErrDiscount("");
    setAfterDiscount(undefined);
    setDiscountAmt(undefined);
    setBeforeDiscount(undefined);
  };

  const _checkDiscount = async (
    discountCode?: string | null
  ): Promise<boolean> => {
    console.log("_checkDiscount", discount);
    const discountCodeValue = discountCode || discount;
    if (!discountCodeValue || !discountCodeValue.trim()) {
      _handleClearDiscount();
      return false;
    }
    try {
      UiService.showLoading();
      const checkDiscountRes = await checkDiscountMc({
        insurancePackageId: selectedPackage,
        discount: discountCodeValue,
        dob: Utils.formatDate(dob!),
        gender,
        insamountAm: insurancePackages?.find(
          (item) => item.id === selectedPackage
        )?.insamountAm,
        idCardNo: idCardNo,
      });
      setIsValidDiscount(true);
      setErrDiscount("");
      console.log("checkDiscountRes", checkDiscountRes);
      // setApplyingDiscount(
      //   discountData?.find((item) => item.discount === discountCodeValue)
      // );
      setApplyingDiscount(checkDiscountRes?.data);
      setBeforeDiscount(checkDiscountRes?.data?.beforeDiscount);
      setAfterDiscount(checkDiscountRes?.data?.afterDiscount);
      setDiscountAmt(checkDiscountRes?.data?.discountAmt);
      UiService.hideLoading();
      return true;
    } catch (err: any) {
      UiService.hideLoading();
      if (err?.response?.data?.code === 400) {
        setIsValidDiscount(false);
        setErrDiscount(
          err?.response?.data?.error_data?.messsage || t("invalid-discount")
        );
        setBeforeDiscount(undefined);
        setAfterDiscount(undefined);
        setDiscountAmt(undefined);
        setApplyingDiscount(undefined);
      }
      return false;
    }
  };

  const _handlePressChooseVoucher = async () => {
    console.log("_handlePressChooseVoucher");
    try {
      UiService.showLoading();
      const discountRes = await getDiscountMc(idCardNo, selectedPackage!);
      console.log("discountRes", discountRes);
      setDiscountData(discountRes?.data?.list);
      UiService.hideLoading();
      setShowDiscountPopup(true);
    } catch (err: any) {
      console.log("Load Discount err", err);
      UiService.hideLoading();
      UiService.openPopup({
        content: err?.response?.data?.error_message,
        showIcon: false,
        onlyOkButton: true,
        okTitle: t("close"),
        onOk: () => {
          UiService.closePopup();
        },
        onCancel: () => {
          UiService.closePopup();
        },
      });
    }
  };

  const _handleConfirmChooseVoucher = (discount?: string) => {
    console.log("_handleConfirmChooseVoucher", discount);
    setDiscount(discount);
    setIsValidDiscount(true);
    setErrDiscount("");
    setShowDiscountPopup(false);
    _checkDiscount(discount);
  };

  const _handleCancelChooseVoucher = () => {
    setShowDiscountPopup(false);
  };

  const _handleChangeSelectedPackage = useCallback(
    (value: string) => {
      setSelectedPackage(value);
      setDiscount("");
      setIsValidDiscount(null);
      setErrDiscount("");
      setApplyingDiscount(undefined);
      setBeforeDiscount(undefined);
      setAfterDiscount(undefined);
      setDiscountAmt(undefined);
      _selectPackage(value, idCardNo);
    },
    [idCardNo]
  );

  const _renderInsurancePackage = () => {
    if (!insurancePackages) return <div />;
    return (
      <Accordion defaultActiveKey="1" className="mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <div className="appH4 textBlack">
              {t("choose-insurance-package")}
              <span className="required">*</span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <InsurancePackageChooser
              selectedPackage={selectedPackage}
              onChangeSelectedPackage={_handleChangeSelectedPackage}
              data={insurancePackages}
            />
            <VoucherInput
              label={t("promotion-code")}
              placeholder={t("promotion-code-placeholder")}
              value={discount}
              onChange={_handleChangeDiscount}
              onCheckVoucherCode={_checkDiscount}
              errorText={errDiscount}
              isValidVouhcer={isValidDiscount}
              onClear={_handleClearDiscount}
              showChooseVoucher={true}
              onChooseVoucher={_handlePressChooseVoucher}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const _renderInfoForm = () => {
    return (
      <Accordion defaultActiveKey="1" className="mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <div className="appH4">
              {t("fill-info")} <span className="textSecondary">*</span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            {_renderSellerInfoForm()}
            {_renderCustomerInfoForm()}
            <Row>
              <Col>
                <Button
                  variant="primary"
                  onClick={_handleConfirmInfo}
                  className="fullWidth"
                >
                  {editingInfo ? t("confirm") : t("edit")}
                </Button>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const _renderForm = () => {
    return (
      <div className="mb-lg-0 mb-4">
        <Row className="mb-4">
          <Col sm md={8} className="d-flex flex-column justify-content-center">
            <div className="appH3 textBlack">{t("fill-personal-info")}</div>
          </Col>
          <Col sm md={4} className="d-flex flex-column justify-content-center">
            <div className="body16 textSecondary text-left text-sm-end">
              {t("required-info")}
            </div>
          </Col>
        </Row>

        {_renderInfoForm()}
        {_renderInsurancePackage()}
        {isLargeDevice && _renderButton()}
      </div>
    );
  };

  const _renderButton = () => {
    if (editingInfo) return <div />;
    return (
      <Row>
        <Col>
          <Button
            variant="primary"
            onClick={_handleContinue}
            className="fullWidth"
          >
            {t("continue")}
          </Button>
        </Col>
      </Row>
    );
  };

  const _renderInsuranceItem = () => {
    return (
      <div className="mb-3">
        <Row className="mb-4">
          <Col sm className="d-flex flex-column justify-content-center">
            <div className="appH3 textBlack">{t("choosed-product")}</div>
          </Col>
        </Row>
        <InsuranceItem
          data={insurancePackages?.find((item) => item.id === selectedPackage)}
          discount={applyingDiscount}
          beforeDiscount={beforeDiscount}
          discountAmt={discountAmt}
          afterDiscount={afterDiscount}
        />
      </div>
    );
  };
  return (
    <div className={styles.pageContainer}>
      <DiscountPopup
        visible={showDiscountPopup}
        onOk={_handleConfirmChooseVoucher}
        onCancel={_handleCancelChooseVoucher}
        data={discountData}
        selecetedDiscount={discount}
        onCancelDiscount={_handleClearDiscount}
      />
      <Row className={`justify-content-center ${styles.stepper}`}>
        <Col lg={8}>
          <div className="rowCenter">
            <HorizontalStepper data={BUY_INSURANCE_STEP_DATA} step={0} />
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={6}>{_renderForm()}</Col>
        <Col lg={3}>{_renderInsuranceItem()}</Col>
        {!isLargeDevice && <Col>{_renderButton()}</Col>}
      </Row>
    </div>
  );
};

export default CustomerInfo;
