import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const TermAndCondition = () => {
  return (
    <Viewer
      defaultScale={SpecialZoomLevel.PageWidth}
      fileUrl={process.env.PUBLIC_URL + "/BaohiembenhungthuTnC.pdf"}
    />
  );
};

export default TermAndCondition;
