import cancerImg1 from "src/assets/images/cancer-page-1.png";
import cancerImg2 from "src/assets/images/cancer-page-2.png";
import styles from "./Product.module.scss";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useMediaQuery } from "react-responsive";
import arrowRightImg from "src/assets/images/arrow-right.svg";
import SellerInfoService from "src/services/SellerInfoService";
import { Link } from "react-router-dom";
import Utils from "src/utils/Utils";

const ProductCancer = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });

  const _renderProductSortInfo = () => {
    if (isMobile) {
      return (
        <Row>
          <Col className="gx-0">
            <div>
              <img
                src={cancerImg1}
                className={styles.cancerImg1}
                alt="Caner Insurance"
              />
              <div className={styles.productSortInfoContainer}>
                <div className="rowStart mb-2">
                  <div className="lineLeft" />
                  <div className="title18 textBlack2Opacity">
                    {t("cancer_insurance").toUpperCase()}
                  </div>
                </div>
                <div className="appH1 textBlack mb-2 prewrap">
                  {t("cancer_insurance_description")}
                </div>
                <div className="body24 textBlack mb-2">
                  {t("cancer-insurance-sologan")}
                </div>
                {Utils.canBuyCaner() && (
                  <Link to={Utils.getBuyCancerUrl()} className="linkNoStyle">
                    <Button variant={"primary"} className="fullWidth">
                      <span>{t("buy-online")}</span>
                      <img
                        src={arrowRightImg}
                        alt="Arrow right"
                        className={styles.arrowRight}
                      />
                    </Button>
                  </Link>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    return (
      <Row className={styles.producerSortInfoRow}>
        <div className={styles.productSortInfoOuter}>
          <div className={styles.productSortInfoContainer}>
            <div className="rowStart mb32">
              <div className="lineLeft" />
              <div className="title18 textBlack2Opacity">
                {t("cancer_insurance").toUpperCase()}
              </div>
            </div>
            <div className="appH1 textBlack mb-4 prewrap">
              {t("cancer_insurance_description2")}
            </div>
            <div className="body24 textBlack mb32">
              {t("cancer-insurance-sologan")}
            </div>
            {Utils.canBuyCaner() && (
              <Link to={Utils.getBuyCancerUrl()} className="linkNoStyle">
                <Button variant={"primary"} className={styles.buyButtonLg}>
                  <span>{t("buy-online")}</span>
                  <img
                    src={arrowRightImg}
                    alt="Arrow right"
                    className={styles.arrowRight}
                  />
                </Button>
              </Link>
            )}
          </div>
        </div>
        <Col lg={4}></Col>
        <Col lg={8}>
          <img
            src={cancerImg1}
            className={styles.cancerImg1}
            alt="Caner Insurance"
          />
        </Col>
      </Row>
    );
  };

  const _renderDescription = () => {
    return (
      <Row className={styles.descriptionRow}>
        <Col lg={4} md className="gx-0">
          <img src={cancerImg2} className={styles.canderImg2} alt="Cancer" />
        </Col>
        <Col lg={8} md className="gx-0">
          <div className={styles.descriptionContainer}>
            <div className="appH2 mb32">{t("feature")}</div>
            <Row>
              <Col sm>
                <div className="smallTitle mb32">{t("insurance-benefit")}</div>
                <div className={`${styles.description} mb-4`}>
                  Tổng quyền lợi bảo hiểm lên đến{" "}
                  <span className="textPrimary w700">625 triệu đồng</span> với
                  mức phí chỉ từ khoảng{" "}
                  <span className="textPrimary w700">500 đồng/ngày</span>
                </div>
                <div className={`${styles.description} mb-4`}>
                  Nhận ngay <span className="textPrimary w700">100%</span> STBH
                  khi có chẩn đoán bệnh ung thư ở bất kỳ giai đoạn nào (*)
                </div>
                <div
                  className={`${styles.description} ${isMobile ? "mb-4" : ""}`}
                >
                  Nhận thêm <span className="textPrimary w700">25%</span> STBH
                  khi bạn sống khỏe sau 2 năm kể từ ngày chẩn đoán mắc bệnh ung
                  thư <span className="textPrimary w700">LẦN ĐẦU TIÊN</span> tại
                  MB Ageas
                </div>
              </Col>
              <Col sm>
                <div className="smallTitle mb32">{t("commitment")}</div>
                <div className={`${styles.description} mb40`}>
                  Quyền cam kết phát hành hợp đồng mới không cần thẩm định lại
                  sức khỏe
                </div>
                <div className="smallTitle mb32">{t("join")}</div>
                <div className={styles.description}>
                  Tham gia nhanh chóng chỉ với 4 bước trên ứng dụng MB App để có
                  ngay hợp đồng bảo hiểm
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  };

  const _renderAboutProduct = () => {
    return (
      <div className={styles.aboutProductRow}>
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={9}>
              <div className="rowStart mb-4">
                <div className={"lineLeft"} />
                <div className={`textBlack2Opacity subTitle`}>
                  {t("customer-service")}
                </div>
              </div>
              <div className="appH2 textBlack mb40">
                {t("info-abount-product")}
              </div>
              <Row>
                <Col sm>
                  <Row>
                    <Col sm>
                      <div className={styles.aboutProductItem}>
                        <div className="body18 textBlack mb-2">
                          {t("age-join-insurance")}
                        </div>
                        <div className="smallTitle textPrimary">18-65 tuổi</div>
                      </div>
                    </Col>
                    <Col sm>
                      <div className={styles.aboutProductItem}>
                        <div className="body18 textBlack mb-2">
                          {t("fee-term")}
                        </div>
                        <div className="smallTitle textPrimary">Hàng năm</div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm>
                      <div className={styles.aboutProductItem}>
                        <div className="body18 textBlack mb-2">
                          {t("contract-period")}
                        </div>
                        <div className="smallTitle textPrimary">5 năm</div>
                      </div>
                    </Col>
                    <Col sm>
                      <div className={styles.aboutProductItem}>
                        <div className="body18 textBlack mb-2">
                          {t("fee-period2")}
                        </div>
                        <div className="smallTitle textPrimary">5 năm</div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col sm>
                  <div className={styles.aboutProductInfoBlock}>
                    <div className="appH3 mb-4">
                      {t("buy-insurance")}{" "}
                      <span className="textSecondary">
                        {t("cancer_insurance_description")}
                      </span>
                    </div>
                    <div className={styles.aboutProductBuyContainer}>
                      <div className={`flex1 ${isMobile ? "mb-4" : "me-4"}`}>
                        <div className="body18 textBlack w600">
                          {t("about-cancer-product-3-packages")}
                        </div>
                        <div className="body18 textBlack">
                          {t("about-cancer-product-multi-price")}
                        </div>
                      </div>
                      {Utils.canBuyCaner() && (
                        <Link
                          to={Utils.getBuyCancerUrl()}
                          className={`linkNoStyle ${
                            isMobile ? " fullWidth" : ""
                          }`}
                        >
                          <Button
                            variant="primary"
                            className={isMobile ? "fullWidth" : ""}
                          >
                            {t("buy-online")}
                          </Button>
                        </Link>
                      )}
                    </div>
                    <div className="bodt8 textGray italic">
                      * {t("about-cancer-product-note")}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Container fluid>
        <Row className="justify-content-center">
          <Col xl={9} lg={11}>
            {_renderProductSortInfo()}
            {_renderDescription()}
          </Col>
        </Row>
      </Container>
      {_renderAboutProduct()}
    </div>
  );
};

export default ProductCancer;
